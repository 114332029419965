import { combineReducers } from 'redux'

import articles from './articles'
import config from './config'
import favorite from './favorite'
import playlists from './playlists'
import project from './project'
import user from './user'
import player from './player'
import queue from './queue'
import territories from './territories'
import toptracks from './toptracks'
import explorer from './explorer'
import onboarding from './onboarding'
import rgpd from './rgpd'
import tutorial from './tutorial'
import voucherPopIn from './voucherPopIn'

const reducer = combineReducers({
  articles,
  config,
  favorite,
  playlists,
  project,
  user,
  player,
  queue,
  territories,
  toptracks,
  explorer,
  onboarding,
  rgpd,
  tutorial,
  voucherPopIn,
})

export default reducer
