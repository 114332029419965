import React, { useState } from 'react'
import './styles/index.scss'
import Carousel from 'react-multi-carousel'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { stringToHexColor, gaSender } from '../../lib'
import { fetchGenres, updateActiveFilter } from '../../actions'
import 'react-multi-carousel/lib/styles.css'
import { ButtonGroup } from '..'

const Genres = () => {
  const { t } = useTranslation('genres')
  const dispatch = useDispatch()
  const genres = useSelector((state) => state.explorer.genres)
  const [activeFiltersState, setActiveFiltersState] = useState([])
  React.useEffect(() => {
    dispatch(fetchGenres())
  }, [dispatch])
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 6,
      pprojectialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 4,
      pprojectialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 5,
      pprojectialVisibilityGutter: 30,
    },
    tabletXL: {
      breakpoint: {
        max: 1440,
        min: 1024,
      },
      items: 6,
      partialVisibilityGutter: 30,
    },
    desktopSM: {
      breakpoint: {
        max: 1680,
        min: 1440,
      },
      items: 7,
      partialVisibilityGutter: 30,
    },
  }

  return (
    <div className="container__mood slider_bloc container__genre">
      {genres && (
        <div className="slider-content">
          <div className="moods-titles-extra slider-title">
            <h2 className="title title-extra-moods">
              <Link to="/explore" className="link__title">
                <span>{t('title')}</span>
                <img src="/assets/picto_fleche_go.svg" alt={t('more')} />
              </Link>
            </h2>
            <Link
              className="button__seemore"
              to={{ pathname: '/explore', state: { moodCat: true } }}
              onClick={() => {
                gaSender('action_genre_see_all')
              }}
            >
              <text>{t('more')}</text>
            </Link>
          </div>
          <Carousel
            arrows={false}
            keyBoardControl
            partialVisible
            additionalTransfrom={0}
            responsive={responsive}
            slidesToSlide={1}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {genres.map((filter) => (
              <Link
                className="genre-filter-home"
                to="/explore"
                key={`currentcat${filter.id}`}
                style={{ backgroundColor: stringToHexColor(filter) }}
                onClick={() => {
                  const index = activeFiltersState.indexOf(filter.id)
                  const filters = [
                    ...activeFiltersState,
                  ]
                  if (index !== -1) {
                    filters.splice(index, 1)
                  } else {
                    filters.push(filter.id)
                  }
                  setActiveFiltersState(filters)
                  dispatch(updateActiveFilter(filters))
                }}
              >
                {filter.name}
              </Link>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}

export default Genres
