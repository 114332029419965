import React, { useState } from 'react'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import ModalQRCode from '../ModalQRCode'
import { getUrlDeeplink } from '../../lib/helpers'

const MainMenu = () => {
  const { t } = useTranslation('mainmenu')
  const [openQRCode, setOpenQRCode] = useState(false)

  const itemActive = window.location.pathname

  return (
    <nav className="navbar-container">
      <div className="navbar-nav">
        <Link
          className="navbar-logo"
          value="home"
          to="/"
          aria-label={t('backlink')}
        >
          <img src="/iconDigster192.png" alt="logo" className="logo" />
        </Link>
        <Link
          className="nav-link"
          value="home"
          to="/"
        >
          <img
            src={itemActive === '/' ? '/assets/picto_home_action.svg' : '/assets/picto_home.svg'}
            alt="picto home"
            width={20}
            className="icon"
          />
          <span className={itemActive === '/' ? ('active-link') : undefined}>{t('home')}</span>
        </Link>
        <Link
          value="search"
          className="nav-link"
          to="/explore"
          alt="link explore page"
        >
          <img
            src={itemActive === '/explore' ? '/assets/picto_search_action.svg' : '/assets/picto_search.svg'}
            alt="picto search"
            width={20}
            className="icon"
          />
          <span className={itemActive === '/explore' ? ('active-link') : undefined}>{t('explore')}</span>
        </Link>
        <Link
          value="mymusic"
          className="nav-link"
          to="/mymusic"
          alt="link my music page"
        >
          <img
            src={itemActive === '/mymusic' ? '/assets/picto_heart_actif.svg' : '/assets/picto_heart_inactif.svg'}
            alt="picto my music"
            width={20}
            className="icon"
          />
          <span className={itemActive === '/mymusic' ? ('active-link') : undefined}>{t('mymusic')}</span>
        </Link>
        <Link
          value="settings"
          className="nav-link"
          to="/settings"
          alt="link settings page"
        >
          <img
            src={itemActive === '/settings' ? '/assets/picto_account_actif.svg' : '/assets/picto_account.svg'}
            alt="picto account"
            width={20}
            className="icon"
          />
          <span className={itemActive === '/settings' ? ('active-link') : undefined}>{t('settings')}</span>
        </Link>
      </div>
      <button
        className="nav-qrbtn"
        type="button"
        onClick={(e) => {
          e.stopPropagation()
          setOpenQRCode(true)
        }}
      >
        <p>{t('discoverDigster')}</p>
        <div className="nav-qrcode">
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={getUrlDeeplink()}
            viewBox="0 0 256 256"
          />
        </div>

      </button>
      <ModalQRCode isShowing={openQRCode} hide={() => setOpenQRCode(false)} />
    </nav>
  )
}

export default MainMenu
