import React from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import './styles/index.scss'

const Spinner = ({ message }) => (
  <div className="containerSpinner">
    <img
      width={130}
      src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`}
      alt="logo"
    />
    <p className="containerSpinner__message">{message}</p>
  </div>
)

Spinner.propTypes = {
  message: PropTypes.string.isRequired,
}

export default Spinner
