import api from '../api'
import { getLanguage } from './config'
import {
  ONLY_FOR_YOU,
  PROCESSED_CURRENT_PLAYLIST,
  PROCESSED_PLAYLIST_TRACKS,
  PROCESSING_CURRENT_PLAYLIST,
  PROCESSING_PLAYLIST_TRACKS,
  SIMILAR_PLAYLISTS,
  STORE_CURRENT_PLAYLIST,
  STORE_PLAYLISTS,
  STORE_PLAYLIST_TRACKS,
} from './constants'

import { addTracksToQueue } from './queue'

const processingCurrentPlaylist = () => ({
  type: PROCESSING_CURRENT_PLAYLIST,
})

const processedCurrentPlaylist = () => ({
  type: PROCESSED_CURRENT_PLAYLIST,
})

const processingPlaylistTracks = () => ({
  type: PROCESSING_PLAYLIST_TRACKS,
})

const processedPlaylistTracks = () => ({
  type: PROCESSED_PLAYLIST_TRACKS,
})

const storePlaylists = (payload) => ({
  type: STORE_PLAYLISTS,
  payload,
})

const storePlaylist = (payload) => ({
  type: STORE_CURRENT_PLAYLIST,
  payload,
})

const onlyForYou = (payload) => ({
  type: ONLY_FOR_YOU,
  payload,
})

const similarPlaylists = (payload) => ({
  type: SIMILAR_PLAYLISTS,
  payload,
})

const storePlaylistTracks = (payload) => async (dispatch) => {
  const { tracks } = payload

  dispatch({
    type: STORE_PLAYLIST_TRACKS,
    payload: {
      ...payload,
      tracks,
    },
  })
}
export const fetchPlaylists = (containerId) => async (dispatch) => {
  const { items } = await api.fetchPlaylists(containerId)
  const allPlaylists = items.filter((playlist) => playlist.nb_items > 0)
  const order = allPlaylists.map((playlist) => playlist.id)
  const playlists = {}

  allPlaylists.forEach((playlist) => {
    playlists[playlist.id] = {
      ...playlist,
    }
  })

  dispatch(storePlaylists({
    playlists,
    order,
  }))
}

export const fetchPlaylistForOnlyForYou = () => async (dispatch) => {
  const { items } = await api.fetchPlaylistForOnlyForYou()
  if (items) {
    const allPlaylists = items.filter((playlist) => playlist.nb_items > 0)
    dispatch(getLanguage())

    dispatch(onlyForYou({
      allPlaylists,
    }))
  }
}

export const fetchSimilarPlaylists = (id) => async (dispatch) => {
  const similar = await api.fetchSimilarPlaylists(id)
  const allPlaylists = []
  similar.items.forEach(async (list) => {
    allPlaylists.push(await api.fetchPlaylist(list))

    if (allPlaylists.length === similar.items.length) {
      dispatch(similarPlaylists({
        allPlaylists,
      }))
    }
  })
}
export const fetchPlaylist = (playlistId) => async (dispatch) => {
  dispatch(processingCurrentPlaylist())
  dispatch(getLanguage())
  const currentPlaylist = await api.fetchPlaylist(playlistId)

  dispatch(storePlaylist({
    currentPlaylist,
    playlistId,
  }))
  dispatch(processedCurrentPlaylist())
}

export const fetchPlaylistTracks = (playlistId) => async (dispatch) => {
  dispatch(processingPlaylistTracks())
  const { items: tracks } = await api.fetchPlaylistTracks(playlistId)

  await dispatch(storePlaylistTracks({
    playlistId,
    tracks,
  }))
  dispatch(processedPlaylistTracks())
}

export const launchPlaylist = (playlistId, trackIndex) => async (dispatch, getState) => {
  const { playlists: { tracks: { [playlistId]: tracks } } } = getState()
  await dispatch(addTracksToQueue(playlistId, tracks.length, trackIndex))
}
