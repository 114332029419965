export const trackScreenView = (pageName, data = null) => {
  console.log('trackScreenView', pageName, data)
  return { type: 'trackScreenView' }
}

export const trackEvent = (eventAction, data = {}) => {
  console.log('trackEvent', eventAction, (typeof data === 'string') ? { data } : data)
  return { type: 'trackEvent' }
}

export const playlistEvent = (eventAction, data = {}) => {
  console.log('playlistEvent', eventAction, (typeof data === 'string') ? { data } : data)
  return { type: 'playlistEvent' }
}

export const trackException = (error) => {
  console.log('trackException', (typeof error === 'string') ? { error } : error)
  return { type: 'trackException' }
}
