import { useEffect, useState } from 'react'

export const useItemsToDispayHelper = (slopeFactor, margin) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const sideMenu = 256 // largeur du sideMenu
  const integerToDisplay = Math.floor((windowDimensions.width - sideMenu - margin) / slopeFactor)
  const remainderToDisplay = Math.round(
    (((windowDimensions.width - sideMenu - (integerToDisplay * margin)) % slopeFactor)
            / (slopeFactor * (integerToDisplay))) * 100,
  )
  return { integerToDisplay, remainderToDisplay }
}
