import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFavorites,
  fetchPlaylist,
  fetchPlaylistTracks,
  fetchSimilarPlaylists,
} from '../../actions'
import {
  MainMenu,
  PlaylistHeader,
  SimilarPlaylists,
  TracksList,
} from '../../components'
import config from '../../config'
import { gaSender, history } from '../../lib'
import './styles/index.scss'

const Playlist = ({ match }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('playlist')
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { id: playlistId, project_id: projectId } = match.params
  const tracks = useSelector((state) => state.playlists.tracks)
  const allPlaylist = useSelector((state) => state.playlists.playlists)
  const favoplaylists = useSelector((state) => state.favorite.fplaylists)
  const similarPlaylists = useSelector(
    (state) => state.playlists.similarPlaylists,
  )
  const queue = useSelector((state) => state.queue.playlistId)
  const user = useSelector((state) => state.user)
  const [playlist, setIsPlaylist] = useState(null)

  const playlistImageSource = () => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const checkPlaylistAvailability = useCallback(() => {
    if (playlistId in allPlaylist) {
      setIsPlaylist(allPlaylist[playlistId])
      gaSender(
        'page_playlist_screen',
        allPlaylist[playlistId].id,
        allPlaylist[playlistId].metadata.title,
      )
    }
  }, [allPlaylist, playlistId])

  const handleRedirection = useCallback(() => {
    if (projectId) {
      history.push(`/registration-portal/${projectId}`)
    } else {
      history.push('/selectcountry')
    }
  }, [projectId])

  useEffect(() => {
    if (!user.loggedIn) {
      handleRedirection()
    } else {
      checkPlaylistAvailability()
    }
  }, [user, handleRedirection, checkPlaylistAvailability])

  useEffect(() => {
    if (playlist) {
      dispatch(fetchPlaylist(playlistId))
      dispatch(fetchPlaylistTracks(playlistId))
      dispatch(fetchSimilarPlaylists(playlistId))
      dispatch(fetchFavorites())
    }
  }, [dispatch, playlistId, playlist])

  if (user.loggedIn && playlist) {
    return (
      <div
        className={`container-page playlist ${
          queue !== null ? 'container-page-with-player' : ''
        }`}
      >
        {/* main content */}
        <article className="main_content">
          <div className="bgMobile">
            <img src={playlistImageSource()} alt="bgMobile" />
          </div>
          <div className="main-playlist">
            <div className="content_playlist">
              {playlist && (
              <PlaylistHeader
                playlist={playlist}
                playlistImageSource={playlistImageSource}
                tracks={tracks}
                favorplaylists={favoplaylists}
              />
              )}
              <div className="body-list-group">
                {tracks[playlistId] && (
                <TracksList
                  tracks={tracks[playlistId]}
                  user={user}
                  lang={lang}
                  playlist={playlist}
                  similarPlaylists={similarPlaylists}
                />
                )}
              </div>
              {similarPlaylists.length > 0 && (
              <SimilarPlaylists playlists={similarPlaylists} />
              )}
            </div>
          </div>
        </article>
        {/* menu */}
        <aside className="main_menu">
          <MainMenu />
        </aside>
      </div>
    )
  }
  return (
    <div className="container-playlistUnvailable">
      <p className="playlist_unvailable">{t('playlistUnvailalble')}</p>
    </div>
  )
}

Playlist.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape({
    project_id: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
}

export default Playlist
