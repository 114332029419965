import React from 'react'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config'

const ForgotLogin = () => {
  const { t } = useTranslation('forgotLogin')

  return (
    <div className="container_changeLanguage_page main_page">
      <main className="container__forgotLogin">
        <div className="container_changeLanguage">
          <Link className="link_change_language" to="/changeLanguage">{t('changeLanguage')}</Link>
        </div>
        <div className="main_content">
          <img className="imagelogo country" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
          <img className="imagelogomobile" width={70} src={`${config.REACT_APP_PREFIX}./iconDigster192.png`} alt="logo" />
          <div className="text_content">
            <p>{t('forgotLoginHomeMessage')}</p>
            <a href="mailto:support-cm@mesabonnements-cm.com">
              <text>support-cm@mesabonnements-cm.com</text>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}

export default ForgotLogin
