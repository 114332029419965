import { REHYDRATE } from 'redux-persist'
import {
  UPDATE_ONBOARDING_VALUE,
  ON_BOARDING_SKIP,
  ON_BOARDING_RELAUNCH,
  ON_BOARDING_GENDER,
  ON_BOARDING_YEAR,
  ON_BOARDING_GENRE,
  ON_BOARDING_MOOD,
  STORE_ONBOARDING_GENRES,
  STORE_ONBOARDING_MOODS,
  HAS_ONBOARDED,
  HIDE_ONBOARDING_VALUE,
} from '../actions'

const initialState = {
  start: null,
  hasOnboarded: false,
  favoriteGenre: [],
  relaunch: false,
  gender: null,
  yearUser: '',
  favoriteMood: [],
  genres: [],
  moods: [],
  hideMyMusicProfil: false,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case REHYDRATE: {
      const incoming = (payload && payload.onboarding) || {}
      return {
        ...state,
        ...incoming,
        rehydrated: true,
      }
    }
    case UPDATE_ONBOARDING_VALUE: {
      const { start } = payload
      return {
        ...state,
        start,
      }
    }
    case HAS_ONBOARDED: {
      const { hasOnboarded } = payload
      return {
        ...state,
        hasOnboarded,
      }
    }
    case ON_BOARDING_RELAUNCH:
      return {
        ...state,
        start: true,
        relaunch: true,
      }
    case ON_BOARDING_SKIP:
      return {
        ...state,
        start: false,
      }
    case ON_BOARDING_GENDER: {
      return {
        ...state,
        gender: payload.gender,
      }
    }
    case ON_BOARDING_YEAR: {
      return {
        ...state,
        yearUser: payload.birthdate.slice(0, 4),
      }
    }
    case ON_BOARDING_GENRE: {
      return {
        ...state,
        favoriteGenre: payload.genres,
      }
    }
    case ON_BOARDING_MOOD: {
      return {
        ...state,
        favoriteMood: payload.mood,
      }
    }
    case STORE_ONBOARDING_GENRES:
      return {
        ...state,
        genres: payload.genres,
      }
    case STORE_ONBOARDING_MOODS:
      return {
        ...state,
        moods: payload.moods,
      }
    case HIDE_ONBOARDING_VALUE: {
      const { hideMyMusicProfil } = payload
      return {
        ...state,
        hideMyMusicProfil,
      }
    }
    default:
      return state
  }
}
