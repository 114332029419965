import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import ReactCodeInput from 'react-verification-code-input'
import { useTranslation } from 'react-i18next'
import { history } from '../../lib'
import config from '../../config'
import { checkUserFreeTrial } from '../../actions'

const FreeTrialPass = () => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [isRequestSent, setIsRequestSent] = useState(false)
  const { t } = useTranslation('freetrialpass')
  const { t: tError } = useTranslation('error')
  const dispatch = useDispatch()
  const registration = useSelector((state) => state.user.registration)

  const onClickCallToAction = useCallback(async () => {
    try {
      if (registration && registration.phoneNumber) {
        await dispatch(checkUserFreeTrial(registration.phoneNumber, password))
      } else {
        history.push('/phonenumber')
      }
    } catch (e) {
      setError(e.message)
    }
  }, [dispatch, password, registration])

  React.useEffect(() => {
    if (password.length === 4 && !isRequestSent) {
      setIsRequestSent(true)
      onClickCallToAction()
    } else if (password.length === 1) {
      setIsRequestSent(false)
    }
  }, [dispatch, registration, password, onClickCallToAction, isRequestSent, setIsRequestSent])

  return (
    <div className="freetrial__page main_page">
      <div className="container__free_trial container_page">
        <Link
          className="link_return"
          to="/phonenumber"
          onClick={() => {
            history.push('/phonenumber}')
          }}
        >
          <img src="/assets/fleche_retour.png" alt="return" />
        </Link>
        <img className="imagelogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
        <h1 className="titre_page">{t('titlefreetrial')}</h1>
        <p className="chapo_text">{t('freetrial')}</p>
        <div className="container__code">
          <div className="container__input">
            <span>FREE - </span>
            <ReactCodeInput
              fields={4}
              onChange={setPassword}
            />
          </div>
        </div>
        {error && (<p className="alert alert-danger">{tError(error)}</p>)}
        <div className="container__button">
          <button className="button__freetrial button__validation" type="button" onClick={() => history.push('/phonenumber')}>{t('return')}</button>
        </div>
      </div>
    </div>
  )
}

export default FreeTrialPass
