import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { mustDisplayPopInSubscription } from '../../lib'
import { updateVoucherPopIn } from '../../actions'

const VoucherPopIn = () => {
  const dispatch = useDispatch()
  const links = useSelector((reducerState) => reducerState.project.links)
  const voucherDataAtCOnnection = useSelector((state) => state.voucherPopIn.voucherDataAtCOnnection)
  const voucherDataPopIn = useSelector((state) => state.voucherPopIn.voucherDataPopIn)
  const voucherTimePopIn = useSelector((state) => state.voucherPopIn.voucherTimePopIn)
  const hasStartTutorial = useSelector((state) => state.tutorial.hasStartTutorial)
  const { t } = useTranslation('voucherPopIn')
  const [trad, setTrad] = useState(t('title'))
  const [stateDisplayPopIn, setStateDisplayPopIn] = useState(false)
  const [stateVoucherTimePopIn, setStateVoucherTimePopIn] = useState(voucherTimePopIn)
  const interval = React.useRef(null)

  useEffect(() => {
    const subscription = JSON.parse(localStorage.getItem('user'))?.subscription
    if (!subscription) {
      return false
    }
    const today = Date.parse(new Date())
    const exp = Date.parse(subscription.end_date)

    let day = 'J'
    if (exp - 86400000 * 2 - today > -86400000) {
      day = '2'
    } else if (exp - today > 0) {
      day = '1'
    }

    setTrad(trad.replace('-2', `-${day}`))
  }, [trad])

  const onClickContinueButton = () => {
    dispatch(updateVoucherPopIn(voucherDataAtCOnnection, Date.parse(new Date())))
    setStateVoucherTimePopIn(Date.parse(new Date()))
    setStateDisplayPopIn(false)
  }

  const onClickClosebutton = () => {
    setStateVoucherTimePopIn(Date.parse(new Date()))
    setStateDisplayPopIn(false)
    dispatch(updateVoucherPopIn(voucherDataAtCOnnection, Date.parse(new Date())))
  }

  useEffect(() => {
    if ((voucherDataPopIn && voucherDataPopIn.voucherPopInData !== '' && voucherDataAtCOnnection &&
      voucherDataAtCOnnection !== voucherDataPopIn) || voucherDataPopIn === null) {
      dispatch(updateVoucherPopIn('', 0))
    }
  }, [dispatch, voucherDataPopIn, voucherDataAtCOnnection])

  useEffect(() => {
    if (interval?.current) {
      clearInterval(interval.current)
    }
    interval.current = setInterval(() => {
      if (hasStartTutorial && mustDisplayPopInSubscription(stateVoucherTimePopIn) && (voucherDataAtCOnnection !== undefined && voucherDataAtCOnnection !== null)) {
        setStateDisplayPopIn(true)
      }
    }, 60000)
  }, [stateVoucherTimePopIn, voucherDataAtCOnnection, hasStartTutorial])

  const displayPopIn = () => (
    <div className="container__voucher-pop-in">
      <div className="container__pop-in">
        <button
          className="close-popin"
          type="button"
          onClick={() => onClickClosebutton()}
        >
          <span>x</span>
        </button>
        <h1 className="title">{trad}</h1>
        <p className="text">{t('text')}</p>
        {links?.abonnements && (
          <div className="container__link">
            <button
              className="continue-popin"
              type="button"
              onClick={() => onClickContinueButton()}
            >
              <a
                href={`${links.abonnements.cinetpay}`}
                alt={t('altContinueButton')}
                className="link__informations"
              >
                {t('ContinueButton')}
              </a>
            </button>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div>
      {stateDisplayPopIn && (
        displayPopIn()
      )}
    </div>
  )
}

VoucherPopIn.propTypes = {
  voucherDataAtCOnnection: PropTypes.shape({}),
  voucherDataPopIn: PropTypes.shape({}),
}

VoucherPopIn.defaultProps = {
  voucherDataAtCOnnection: null,
  voucherDataPopIn: null,
}

export default VoucherPopIn
