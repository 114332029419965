import { REHYDRATE } from 'redux-persist'
import {
  VOUCHER_DATA_POPIN_ATCONECTION,
  VOUCHER_DATA_POPIN,
} from '../actions'

const initialState = {
  voucherDataAtCOnnection: null,
  voucherDataPopIn: '',
  voucherTimePopIn: 0,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case REHYDRATE: {
      const incoming = (payload && payload.voucherPopIn) || {}
      return {
        ...state,
        ...incoming,
        rehydrated: true,
      }
    }
    case VOUCHER_DATA_POPIN_ATCONECTION:
      return {
        ...state,
        voucherDataAtCOnnection: payload,
      }
    case VOUCHER_DATA_POPIN:
      return {
        ...state,
        voucherDataPopIn: payload.voucherPopInData,
        voucherTimePopIn: payload.voucherPopInTime,
      }
    default:
      return state
  }
}
