import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import Track from '../Track'
import TerritoryTrack from '../TerritoryTrack'

const TracksList = (props) => {
  const {
    tracks,
    lang,
    user,
    playlist,
    territoryTracks,
    isTopTracks,
    territory,
    isMyFavorites,
    similarPlaylists,
  } = props
  if (isTopTracks) {
    return (
      <div className="list-group">
        {territoryTracks && territoryTracks.map((track, index) => (
          <TerritoryTrack
            territory={territory}
            track={track}
            lang={lang}
            user={user}
            key={`track-${track.id}`}
            index={index}
          />
        ))}
      </div>
    )
  }
  return (
    <div className="list-group" style={similarPlaylists.length > 0 ? { paddingBottom: '25px' } : {}}>
      {tracks && tracks.map((track, index) => (
        <Track
          isMyFavorites={isMyFavorites}
          lang={lang}
          user={user}
          key={`track-${track.id}`}
          track={track}
          playlist={playlist}
          index={index}
        />
      ))}
    </div>
  )
}

TracksList.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({})),
  similarPlaylists: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  lang: PropTypes.string.isRequired,
  playlist: PropTypes.objectOf(PropTypes.shape({})),
  territoryTracks: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  isTopTracks: PropTypes.bool,
  territory: PropTypes.number,
  isMyFavorites: PropTypes.bool,
}
TracksList.defaultProps = {
  playlist: {},
  territoryTracks: [],
  similarPlaylists: [],
  tracks: [],
  isTopTracks: false,
  isMyFavorites: false,
  territory: 0,
}

export default TracksList
