import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { history, stringToHexColor, gaSender } from '../../lib'
import { updateFavoriteMood, fetchOnboardingMoods, getLanguage } from '../../actions'
import { OnBoardingSkipButton } from '../../components'

const OnBoardingMoods = () => {
  const { t } = useTranslation('onboarding')
  const dispatch = useDispatch()
  const moods = useSelector((state) => state.onboarding.moods)
  const favoriteMood = useSelector((state) => state.onboarding.favoriteMood)
  const [activeFiltersState, setActiveFiltersState] = useState(
    favoriteMood.length === 3 ? favoriteMood : [],
  )

  const toggleFilter = (filter) => {
    const index = activeFiltersState.indexOf(filter.id)
    const filters = [
      ...activeFiltersState,
    ]
    if (index !== -1) {
      filters.splice(index, 1)
    } else {
      filters.push(filter.id)
    }
    setActiveFiltersState(filters)
    if (filters.length > 3) {
      filters.splice(index, 1)
    }
  }
  useEffect(() => {
    gaSender('page_onboarding_mood')
    dispatch(getLanguage())
    dispatch(fetchOnboardingMoods())
  }, [dispatch])

  return (
    <div className="container_onboarding_moods">
      <div className="bg-onboarding-moods">
        <OnBoardingSkipButton />
        <div className="onboarding-moods-content">
          <div>
            <h1>{t('titlemoods')}</h1>
            <p>{t('choice')}</p>
          </div>
          <div className="active-filters">
            <div className="filter-choice">
              {activeFiltersState.map((filter) => {
                let filterFound = {}
                moods.forEach((genre) => {
                  if (genre.id === filter) {
                    filterFound = genre
                  }
                })

                return (
                  <div key={`activefilters${filter}`}>
                    <button
                      type="button"
                      style={{ backgroundColor: stringToHexColor(filterFound) }}
                      onClick={() => {
                        toggleFilter(filterFound)
                      }}
                    >
                      <p>{filterFound.name}</p>
                      <p>x</p>
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mood">
            {moods.map((filter) => (
              <button
                type="button"
                style={{
                  opacity: activeFiltersState.indexOf(filter.id) !== -1 ? 1 : 0.75,
                  backgroundColor: stringToHexColor(filter),
                }}
                key={`currentcat${filter.id}`}
                onClick={() => toggleFilter(filter)}
              >
                <img
                  src={`/assets/moods/picto_${filter.id}.png`}
                  alt="moods"
                  className="mood-picto"
                />
                <p>{filter.name}</p>
              </button>
            ))}
          </div>
          {activeFiltersState.length > 2 && (
          <div className="valid-mood">
            <button
              type="button"
              className="btn-moods"
              onClick={() => {
                dispatch(updateFavoriteMood(activeFiltersState))
                history.push('./')
              }}
            >
              {t('onboardingvalidate')}
            </button>
          </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default OnBoardingMoods
