import api from '../api'
import { STORE_ARTICLES, STORE_ARTIST, STORE_PLAYLISTS_ARTIST } from './constants'

const storeArticles = (payload) => ({
  type: STORE_ARTICLES,
  payload,
})

const storeArtist = (payload) => ({
  type: STORE_ARTIST,
  payload,
})

const storePlaylistsArtist = (payload) => ({
  type: STORE_PLAYLISTS_ARTIST,
  payload,
})

export const fetchArticles = (containerId) => async (dispatch) => {
  const { items: articles } = await api.fetchArticles(containerId)
  dispatch(storeArticles({
    articles,
  }))
}

export const fetchArtist = (artistPage) => async (dispatch) => {
  try {
    const artist = await api.fetchArtist(artistPage)
    dispatch(storeArtist({
      artist,
    }))
  } catch (e) {
    console.error(e)
  }
}

export const fetchPlaylistsArtist = (artistPage) => async (dispatch) => {
  try {
    const { items: playlistsArtist } = await api.fetchPlaylistsArtist(artistPage)
    dispatch(storePlaylistsArtist({
      playlistsArtist,
    }))
  } catch (e) {
    console.error(e)
  }
}
