import {
  STORE_PLAYLISTS,
  STORE_CURRENT_PLAYLIST,
  STORE_PLAYLIST_TRACKS,
  PROCESSING_ARTICLES_PLAYLISTS,
  PROCESSED_ARTICLES_PLAYLISTS,
  PROCESSING_CURRENT_PLAYLIST,
  PROCESSED_CURRENT_PLAYLIST,
  PROCESSING_PLAYLIST_TRACKS,
  PROCESSED_PLAYLIST_TRACKS,
  ONLY_FOR_YOU,
  SIMILAR_PLAYLISTS,
} from '../actions'

const initialState = {
  playlists: {},
  tracks: {},
  onlyForYou: [],
  processing: false,
  loadingCurrentPlaylist: false,
  similarPlaylists: [],
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_PLAYLISTS: {
      const { playlists, order } = payload

      return {
        ...state,
        playlists: { ...playlists },
        order,
      }
    }
    case STORE_CURRENT_PLAYLIST: {
      const { playlistId, currentPlaylist: playlist } = payload

      return {
        ...state,
        playlists: {
          ...state.playlists,
          [playlistId]: {
            ...playlist,
          },
        },
      }
    }
    case ONLY_FOR_YOU: {
      return {
        ...state,
        onlyForYou: payload.allPlaylists,
      }
    }
    case SIMILAR_PLAYLISTS: {
      return {
        ...state,
        similarPlaylists: payload.allPlaylists,
      }
    }
    case STORE_PLAYLIST_TRACKS: {
      const { playlistId } = payload
      // const playlist = state.playlists[playlistId]
      // const { tracks } = payload
      // const duration = tracks.reduce((acc, track) => acc + track.duration, 0)

      return {
        ...state,
        /* playlists: {
           ...state.playlists,
           [playlistId]: {
             ...playlist,
             duration,
           },
         }, */
        tracks: {
          ...state.tracks,
          [playlistId]: [...payload.tracks],
        },
      }
    }
    case PROCESSING_ARTICLES_PLAYLISTS:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_ARTICLES_PLAYLISTS:
      return {
        ...state,
        processing: false,
      }
    case PROCESSING_CURRENT_PLAYLIST:
      return {
        ...state,
        loadingCurrentPlaylist: true,
      }
    case PROCESSED_CURRENT_PLAYLIST:
      return {
        ...state,
        loadingCurrentPlaylist: false,
      }
    case PROCESSING_PLAYLIST_TRACKS:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_PLAYLIST_TRACKS:
      return {
        ...state,
        processing: false,
      }
    default:
      return state
  }
}
