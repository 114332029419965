import React, { useState, useEffect } from 'react'
import './styles/index.scss'
import { isIOS, isSafari } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { gaSender } from '../../lib'
import { getLanguage, getUnitedProjects, chooseProject, fetchProject } from '../../actions'
import config from '../../config'

const CountrySelection = () => {
  const [selectedProject, setSelectedProject] = useState()
  const [displayErrorMsg, setDisplayErrorMsg] = useState(false)
  const [firstConnexion, setFirstConnexion] = useState(localStorage.getItem('firstConnexion'))
  const dispatch = useDispatch()
  const { t } = useTranslation('countryselection')
  const projects = useSelector((state) => state.project.projects)

  useEffect(() => {
    gaSender('launch_select_project')
    localStorage.removeItem('user')
    dispatch(getLanguage())
    dispatch(getUnitedProjects())
  }, [dispatch, setSelectedProject])

  let projectsFilter
  try {
    projectsFilter = projects.reduce((acc, p) => ([
      ...acc,
      {
        label: t(p.territory[0].code),
        value: p.id,
      },
    ]), [])
    projectsFilter = projectsFilter.sort((a, b) => a.label.localeCompare(b.label))
  } catch (e) {
    console.log(e)
  }

  const projectImageSource = (projectId) => `${config.API_BASE_URL}/assets/united/flags/${projectId}.png`

  const handleOptionChange = (changeEvent) => {
    setSelectedProject(parseInt(changeEvent.target.value, 10))
  }
  const getProjectCover = (projectId) => `${config.API_BASE_URL}/assets/${projectId}/country.jpg`

  const handleFormSubmit = (formSubmitEvent) => {
    if (selectedProject) {
      formSubmitEvent.preventDefault()
      dispatch(chooseProject(selectedProject))
      dispatch(fetchProject())
    } else {
      formSubmitEvent.preventDefault()
      setDisplayErrorMsg(true)
    }
  }

  const selected = (e) => {
    setSelectedProject(e)
  }

  const changeFirstConnexion = () => {
    localStorage.setItem('firstConnexion', false)
    setFirstConnexion(false)
  }

  return (
    <div className="container_changeLanguage_page main_page">
      {firstConnexion === null && !window.matchMedia('(display-mode: standalone)').matches && isIOS && isSafari ? (
        <div className="overlay">
          <button className="overlay__btn" aria-label={t('closeOverlay')} onClick={() => changeFirstConnexion()} type="button">X</button>
          <div className="overlay__informations">
            <img className="imagelogomobile" width={90} src={`${config.REACT_APP_PREFIX}./assets/logo_digster.png`} alt="" />
            <div className="overlay__info">
              <div>
                {t('toInstall')}
                <div className="overlay__logo" />
                {t('andChoose')}
              </div>
              <div>{t('addHome')}</div>
            </div>
          </div>
          <div className="overlay__pointer" />
        </div>
      ) : (
        <main className="container__contryselect">
          <div className="container_changeLanguage">
            <Link className="link_change_language" to="/changeLanguage">{t('changeLanguage')}</Link>
          </div>
          <div className="main_content">
            <img className="imagelogo country" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
            <img className="imagelogomobile" width={70} src={`${config.REACT_APP_PREFIX}./iconDigster192.png`} alt="logo" />
            <h1 className="title__page titre_page">{t('titlecountryselection')}</h1>
            {displayErrorMsg && (
            <div className="container__errormsg">
              {t('errorMsg')}
            </div>
            )}
            <form onSubmit={handleFormSubmit}>
              <div className="wrapper__contry">
                <div className="container__contry">
                  {projectsFilter && projectsFilter.map((project) => (
                    <button
                      type="button"
                      className="one__pays"
                      onClick={() => selected(project.value)}
                      key={`project-${project.value}`}
                      value={project.value}
                    >
                      <input size="20px" className="input" type="radio" value={project.value} checked={selectedProject === project.value} onChange={handleOptionChange} />
                      <img
                        key={`img-cover-${project.value}`}
                        src={getProjectCover(project.value)}
                        alt="slide"
                        className={selectedProject === project.value ? 'cover selected' : 'cover '}
                      />
                      <img
                        key={`image-${project.value}`}
                        className="image__contry"
                        src={projectImageSource(project.value)}
                        alt="slide"
                      />
                      <p className={selectedProject === project.value ? 'selectedTitle' : ''}>{t(project.label)}</p>
                    </button>
                  ))}
                </div>
              </div>
              <div className="container__button">
                <button
                  className="button__selectcontry"
                  type="submit"
                >
                  <span className={selectedProject === undefined ? ('button__selectcountry-inactive') : ('button__selectcountry-active')}>{t('selectcountry')}</span>
                </button>
              </div>
            </form>
          </div>
        </main>
      )}
    </div>
  )
}

export default CountrySelection
