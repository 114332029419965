import React, { useState } from 'react'
import './styles/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import config from '../../config'
import { updateRgpdChoice, updateOptinRgpdChoice, hideOnBoarding } from '../../actions'
import { RgpdConfigure } from '../index'

const Rgpd = (props) => {
  const [rgpdConfigDisplay, setRgpdConfigDisplay] = useState(false)
  const { t } = useTranslation('rgpd')
  const locale = useSelector((state) => state.config.locale)
  const {
    location: {
      state,
    },
    rgpdCloseHandler,
  } = props
  const dispatch = useDispatch()

  const rgpdChoice = (idOptin, value) => {
    dispatch(updateRgpdChoice(idOptin, value))
    if (state.scene === 'settings') {
      dispatch(updateOptinRgpdChoice())
    }
    rgpdCloseHandler()
  }

  const rgpdConfigOpen = () => {
    setRgpdConfigDisplay(true)
  }

  const rgpdConfigClose = () => {
    rgpdCloseHandler()
    setRgpdConfigDisplay(false)
  }

  return (
    <div>
      { rgpdConfigDisplay ? (
        <RgpdConfigure
          location={{ state: { scene: state.scene } }}
          configCloseHandler={rgpdConfigClose}
        />
      ) : (
        <div className="bg_rgpd">
          <div className="rgpd_content">
            <button
              type="button"
              className="btn_notAgree"
              onClick={() => {
                rgpdChoice(0, false)
                dispatch(hideOnBoarding(true))
              }}
            >
              {t('rgpdnotagree')}
            </button>
            <img alt="logo" src="/assets/images/logo_digster.png" className="logo" />
            <p className="rgpd_info">{t('rgpdinfo')}</p>
            <div className="rgpd_button_container">
              <button
                type="button"
                className="btn_rgpd btn_choice"
                onClick={() => {
                  rgpdChoice(0, true)
                  dispatch(hideOnBoarding(false))
                }}
              >
                {t('rgpdagree')}
              </button>
              <button
                type="button"
                className="btn_rgpd btn_configure"
                onClick={() => {
                  rgpdConfigOpen()
                }}
              >
                {t('rgpdconfigure')}
              </button>
            </div>
            <div className="rgpdprivacy_container">
              <a
                href={`${config.API_BASE_URL}/assets/privacy-${locale}.pdf`}
                className="rgpd_privacy"
                alt={t('rgpdlink')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('rgpdprivacy')}
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

Rgpd.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      scene: PropTypes.string,
    }),
  }).isRequired,
  rgpdCloseHandler: PropTypes.func.isRequired,
}

export default Rgpd
