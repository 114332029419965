import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './styles/index.scss'
import { MainMenu, PlaylistGrid, Loader } from '../../components'
import {
  fetchUserFavoritePlaylistsForExplorer,
  updateActiveFilter,
  fetchGenres,
  fetchDecades,
  fetchMoods,
} from '../../actions'
import { stringToHexColor, gaSender } from '../../lib'
import 'react-activity/dist/library.css'

const Explore = (props) => {
  const dispatch = useDispatch()
  const playlists = useSelector((state) => state.explorer.playlists)
  const order = useSelector((state) => state.explorer.order)
  const genres = useSelector((state) => state.explorer.genres)
  const moods = useSelector((state) => state.explorer.moods)
  const decades = useSelector((state) => state.explorer.decades)
  const activeFilters = useSelector((state) => state.explorer.activeFilters)
  const hasMore = useSelector((state) => state.explorer.hasMore)
  const queue = useSelector((state) => state.queue.playlistId)
  const [activeFiltersState, setActiveFiltersState] = useState(activeFilters)
  const [currentCat, setCurrentCat] = useState([])
  const [choiceOfCat, setChoiceOfCat] = useState(null)
  const [moodCat, setMoodCat] = useState(false)
  const [offsetExplo, setOffsetExplo] = useState(0)
  const [lastOpennedCat, setLastOpennedCat] = useState(null)
  const { t } = useTranslation('explore')
  const {
    location: {
      state,
    },
  } = props

  React.useEffect(() => {
    gaSender('page_explorer')
    dispatch(fetchUserFavoritePlaylistsForExplorer(0))
    dispatch(fetchGenres())
    dispatch(fetchDecades())
    dispatch(fetchMoods())
  }, [dispatch])

  const openCat = (cat) => {
    gaSender('page_explorer_category')
    gaSender(`page_explorer_category_${cat}`)
    if (cat !== lastOpennedCat) {
      dispatch(updateActiveFilter([]))
    }
    if (cat === 'genre') {
      setActiveFiltersState([])
      setCurrentCat(genres)
      setChoiceOfCat(cat)
    } else if (cat === 'mood') {
      setActiveFiltersState([])
      setCurrentCat(moods)
      setChoiceOfCat(cat)
    } else if (cat === 'decades') {
      setActiveFiltersState([])
      setCurrentCat(decades)
      setChoiceOfCat(cat)
    }
    setLastOpennedCat(cat)
  }

  useEffect(() => {
    if (state) {
      openCat('mood')
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line
  }, [state])

  const onDeleteFilter = (index) => {
    setActiveFiltersState(activeFiltersState.filter((_, i) => i !== index))
    activeFilters.splice(index, 1)
    dispatch(updateActiveFilter(activeFilters))
    setOffsetExplo(0)
  }

  const findingFilter = (filter) => {
    let filterFound = {}
    const genresFilterFound = genres.find((genre) => genre.id === filter)
    const moodsFilterFound = moods.find((mood) => mood.id === filter)
    const decadesFilterFound = decades.find((decade) => decade.id === filter)

    if (genresFilterFound) {
      filterFound = genresFilterFound
    }
    if (moodsFilterFound) {
      filterFound = moodsFilterFound
    }
    if (decadesFilterFound) {
      filterFound = decadesFilterFound
    }
    return filterFound
  }

  const loadFunction = () => {
    dispatch(fetchUserFavoritePlaylistsForExplorer(offsetExplo))
    setOffsetExplo(offsetExplo + 20)
  }

  return (
    <main className={`container-page container-explorer explorer ${(queue !== null) ? 'container-page-with-player' : ''}`}>
      {/* main content */}
      <article className="main_content">
        <div className="d-flex-column body-width">
          <div className="filter-container">
            <div className="background-linear">
              {/* Title  */}
              <div className="mobil-title">
                {choiceOfCat || moodCat ? (
                  <Link
                    className="backArrow"
                    to="/explore"
                    onClick={() => {
                      setChoiceOfCat(null)
                      setCurrentCat([])
                      setMoodCat(false)
                      setActiveFiltersState([])
                    }}
                  >
                    <img
                      src="/assets/fleche_retour.png"
                      alt="back arrow"
                    />
                  </Link>
                ) : null}
                {choiceOfCat === 'genre' && (
                  <h1 className="title-page pad-left">{t('genres')}</h1>
                )}
                {(choiceOfCat === 'mood' || moodCat) && (
                  <h1 className="title-page pad-left">{t('moods')}</h1>
                )}
                {choiceOfCat === 'decades' && (
                  <h1 className="title-page pad-left">{t('decades')}</h1>
                )}
              </div>
              {!choiceOfCat && !moodCat && (
                <h1 className="title-page">{t('exploretitle')}</h1>
              )}
              {(choiceOfCat || moodCat) && (
                <h1 className="title-page hide-mobil">{t('exploretitle')}</h1>
              )}
              {/* Filter bloc */}
              <div className="filters">
                {!choiceOfCat && !moodCat ? (
                  <div className="filters-header">
                    <p className="labeled">{t('filter')}</p>
                    <div className="flex-filter">
                      <button
                        onClick={() => openCat('genre')}
                        type="button"
                        className="show-desktop-inactive"
                      >
                        <span>{t('genres')}</span>
                        <img
                          src="/assets/picto_fleche_v.png"
                          alt="down Arrow"
                        />
                      </button>
                      <button
                        onClick={() => openCat('mood')}
                        type="button"
                        className="show-desktop-inactive"
                      >
                        <span>{t('moods')}</span>
                        <img
                          src="/assets/picto_fleche_v.png"
                          alt="down Arrow"
                        />
                      </button>
                      <button
                        onClick={() => openCat('decades')}
                        type="button"
                        className="show-desktop-inactive"
                      >
                        <span>{t('decades')}</span>
                        <img
                          src="/assets/picto_fleche_v.png"
                          alt="down Arrow"
                        />
                      </button>
                      {currentCat.length > 0 && (
                        // <div>
                        <button
                          onClick={() => {
                            setCurrentCat([])
                          }}
                          type="button"
                          className="close-btn"
                        >
                          <span>{t('closebtn')}</span>
                        </button>
                        // </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {(choiceOfCat || moodCat) && (
                  <div className="hide-mobil filter-md">
                    <p className="labeled">{t('filter')}</p>
                    <div className="flex-filter">
                      <button
                        onClick={() => openCat('genre')}
                        type="button"
                        className={choiceOfCat === 'genre' ? 'show-desktop-active' : 'show-desktop'}
                      >
                        <span>{t('genres')}</span>
                        <img
                          src={choiceOfCat === 'genre' ? '/assets/picto_fleche_go.svg' : '/assets/picto_fleche_v_gray.png'}
                          alt="down Arrow"
                        />
                      </button>
                      <button
                        onClick={() => openCat('mood')}
                        type="button"
                        className={choiceOfCat === 'mood' ? 'show-desktop-active' : 'show-desktop'}
                      >
                        <span>{t('moods')}</span>
                        <img
                          src={choiceOfCat === 'mood' ? '/assets/picto_fleche_go.svg' : '/assets/picto_fleche_v_gray.png'}
                          alt="down Arrow"
                        />
                      </button>
                      <button
                        onClick={() => openCat('decades')}
                        type="button"
                        className={choiceOfCat === 'decades' ? 'show-desktop-active' : 'show-desktop'}
                      >
                        <span>{t('decades')}</span>
                        <img
                          src={choiceOfCat === 'decades' ? '/assets/picto_fleche_go.svg' : '/assets/picto_fleche_v_gray.png'}
                          alt="down Arrow"
                        />
                      </button>
                      {currentCat.length > 0 && (
                        // <div>
                        <button
                          onClick={() => {
                            setCurrentCat([])
                            setActiveFiltersState(activeFilters)
                            setChoiceOfCat()
                          }}
                          type="button"
                          className="close-btn"
                        >
                          {t('closebtn')}
                        </button>
                        // </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="filter-category-content">
              <div className="filter-innercontent">
                {activeFiltersState.length > 0 && currentCat.length > 0 && (
                  <div className="active-filters">
                    <div className="filter-choice">
                      {activeFiltersState.map((filter, index) => {
                        const filterFound = findingFilter(filter)
                        return (
                          <div key={`activefilters${filter}`}>
                            <button
                              type="button"
                              style={{ backgroundColor: stringToHexColor(filterFound) }}
                              onClick={() => {
                                onDeleteFilter(index)
                              }}
                            >
                              <span>{filterFound.name}</span>
                              <span className="closeBtn">x</span>
                            </button>
                          </div>
                        )
                      })}
                    </div>
                    {(currentCat.length > 0 || moodCat) && activeFiltersState.length > 0 && (
                      <button
                        onClick={() => {
                          setActiveFiltersState([])
                          dispatch(updateActiveFilter([]))
                        }}
                        type="button"
                        className="delete-btn-mobil"
                      >
                        <img
                          src="/assets/picto_supp.png"
                          alt="dustbin"
                        />
                      </button>
                    )}
                  </div>
                )}
                {activeFiltersState.length === 0 && currentCat.length > 0 && (
                  // <div>
                  <div className="active-filters">
                    <div className="filter-choice">
                      {activeFilters.map((filter, index) => {
                        const filterFound = findingFilter(filter)
                        return (
                          <div key={`activefilters${filter}`}>
                            <button
                              type="button"
                              style={{ backgroundColor: stringToHexColor(filterFound) }}
                              onClick={() => {
                                onDeleteFilter(index)
                              }}
                            >
                              <span>{filterFound.name}</span>
                              <span>x</span>
                            </button>
                          </div>
                        )
                      })}
                    </div>
                    {(currentCat.length > 0 || moodCat) && activeFiltersState.length > 0 && (
                      <button
                        onClick={() => {
                          setActiveFiltersState([])
                          dispatch(updateActiveFilter([]))
                        }}
                        type="button"
                        className="delete-btn-mobil"
                      >
                        <img
                          src="/assets/picto_supp.png"
                          alt="dustbin"
                        />
                      </button>
                    )}
                  </div>
                  // </div>
                )}

                {activeFiltersState.length > 0 && currentCat.length === 0 && (
                  <div>
                    <div className="filter-choice-mobile">
                      <div className="filter-choice">
                        {activeFilters.map((filter, index) => {
                          const filterFound = findingFilter(filter)
                          return (
                            <div key={`activefilters2${filter}`}>
                              <button
                                type="button"
                                style={{ backgroundColor: stringToHexColor(filterFound) }}
                                onClick={() => onDeleteFilter(index)}
                              >
                                <span>{filterFound.name}</span>
                                <span>x</span>
                              </button>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {(activeFilters.length > 0 && activeFiltersState.length === 0) && (
                  <div className="filter-choice-mobile">
                    <div className="filter-choice">
                      {activeFiltersState.map((filter, index) => {
                        const filterFound = findingFilter(filter)

                        return (
                          <div key={`activefilters2${filter}`}>
                            <button
                              type="button"
                              style={{ backgroundColor: stringToHexColor(filterFound) }}
                              onClick={() => onDeleteFilter(index)}
                            >
                              <span>{filterFound.name}</span>
                              <span>x</span>
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {currentCat.length > 0 && (
                  <div className="absolute">
                    <div
                      className={`category-pannel 
                        ${(queue !== null) ? 'category-pannel-with-player-only' : ''}
                        ${(currentCat.length > 0 || moodCat) && (activeFiltersState.length > 0) ? 'category-pannel-with-apply-btn' : ''}
                        ${(queue !== null) && (currentCat.length > 0 || moodCat) && (activeFiltersState.length > 0) ? 'category-pannel-with-player-and-btn' : ''}
                        `}
                    >
                      {choiceOfCat === 'genre' && (
                      <div className="flex">
                        {currentCat.map((filter) => (
                          <button
                            key={`genre${filter.id}`}
                            type="button"
                            style={{ backgroundColor: stringToHexColor(filter) }}
                            onClick={() => {
                              const index = activeFiltersState.indexOf(filter.id)
                              const filters = [
                                ...activeFiltersState,
                              ]
                              if (index !== -1) {
                                filters.splice(index, 1)
                              } else {
                                filters.push(filter.id)
                              }
                              setActiveFiltersState(filters)
                            }}
                          >
                            {filter.name}
                          </button>
                        ))}
                      </div>
                      )}
                      {choiceOfCat === 'mood' && (
                      <div className="flex">
                        {currentCat.map((filter) => (
                          <button
                            key={`moods${filter.id}`}
                            type="button"
                            className="mood-btn"
                            style={{ backgroundColor: stringToHexColor(filter) }}
                            onClick={() => {
                              const index = activeFiltersState.indexOf(filter.id)
                              const filters = [
                                ...activeFiltersState,
                              ]
                              if (index !== -1) {
                                filters.splice(index, 1)
                              } else {
                                filters.push(filter.id)
                              }
                              setActiveFiltersState(filters)
                            }}
                          >
                            <img
                              src={`/assets/moods/picto_${filter.id}.png`}
                              alt="moods"
                            />
                            {filter.name}
                          </button>
                        ))}
                      </div>
                      )}
                      {choiceOfCat === 'decades' && (
                        <div className="flex">
                          {currentCat.sort((a, b) => b.name.localeCompare(a.name)).map((filter) => (
                            <button
                              key={`decades${filter.id}`}
                              type="button"
                              className="decades-btn"
                              style={{ backgroundColor: stringToHexColor(filter) }}
                              onClick={() => {
                                const index = activeFiltersState.indexOf(filter.id)
                                const filters = [
                                  ...activeFiltersState,
                                ]
                                if (index !== -1) {
                                  filters.splice(index, 1)
                                } else {
                                  filters.push(filter.id)
                                }
                                setActiveFiltersState(filters)
                              }}
                            >
                              {filter.name}
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {moodCat && (
                  <div className="absolute">
                    <div
                      className={`category-pannel 
                        ${(currentCat.length > 0 || moodCat) && (activeFiltersState.length > 0) ? 'category-pannel-with-apply-btn' : ''}
                        ${(queue !== null) && (currentCat.length > 0 || moodCat) && (activeFiltersState.length > 0) ? 'category-pannel-with-player' : ''}
                        `}
                    >
                      <div className="flex list-btn">
                        {moods.map((filter) => (
                          <button
                            key={`moods${filter.id}`}
                            type="button"
                            className="mood-btn"
                            style={{ backgroundColor: stringToHexColor(filter) }}
                            onClick={() => {
                              const index = activeFiltersState.indexOf(filter.id)
                              const filters = [
                                ...activeFiltersState,
                              ]
                              if (index !== -1) {
                                filters.splice(index, 1)
                              } else {
                                filters.push(filter.id)
                              }
                              setActiveFiltersState(filters)
                              setCurrentCat(moods)
                            }}
                          >
                            <img
                              src={`/assets/moods/picto_${filter.id}.png`}
                              alt="moods"
                            />
                            {filter.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {(currentCat.length > 0 || moodCat) && activeFiltersState.length > 0 && (
                  // <div>
                  <div className={`apply ${queue !== null ? 'apply-with-player' : ''}`}>
                    <div className="apply_content">
                      <button
                        onClick={() => {
                          dispatch(updateActiveFilter(activeFiltersState))
                          setOffsetExplo(0)
                          setCurrentCat([])
                          setChoiceOfCat(null)
                          setMoodCat(false)
                        }}
                        type="button"
                        className="apply-btn"
                      >
                        {` ${t('apply')} (${activeFiltersState.length})`}
                      </button>
                      <button
                        onClick={() => {
                          setActiveFiltersState([])
                          dispatch(updateActiveFilter([]))
                          setOffsetExplo(0)
                        }}
                        type="button"
                        className="delete-btn"
                      >
                        <img
                          src="/assets/picto_supp.png"
                          alt="dustbin"
                        />
                        <span className="text">{t('delete')}</span>
                      </button>
                    </div>
                  </div>
                  // </div>
                )}
              </div>
            </div>
          </div>
          {order.length > 0 && (
            <div style={{ height: 'auto', overflow: 'auto' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadFunction}
                hasMore={hasMore}
                loader={<div className="loader" key={0}><Loader color="#3CAD00" size={25} /></div>}
                useWindow={false}
              >
                <PlaylistGrid playlists={playlists} order={order} />
              </InfiniteScroll>
            </div>
          )}
        </div>
      </article>
      {/* menu */}
      <div className="main_menu">
        <MainMenu />
      </div>
    </main>
  )
}

Explore.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      moodCat: PropTypes.string,
    }),
  }).isRequired,
}

export default Explore
