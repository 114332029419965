import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import { propTypes } from 'react-bootstrap/esm/Image'
import Carousel from 'react-multi-carousel'
import { useDispatch } from 'react-redux'
import { gaSender } from '../../lib'

import config from '../../config'
import configureStore from '../../store'
import { ButtonGroup } from '..'
import { playFirstTrackFromPlaylist } from '../../actions'

const { store } = configureStore()

const FPlaylistGrid = (props) => {
  const {
    user,
  } = store.getState()
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { favorplaylists, responsive } = props
  const dispatch = useDispatch()

  const coverWidth = 224
  const coverHeight = 224
  const favorplaylistImageSource = (favorplaylistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${favorplaylistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=${coverWidth}&height=${coverHeight}&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const btnPlay = (favorplaylistId) => {
    dispatch(playFirstTrackFromPlaylist(favorplaylistId))
  }

  const renderItem = (playlist) => (
    <div key={`item-${favorplaylists[playlist].id}`} className="container-playlist-suggestion">
      <Link
        className="link-item"
        key={`ref-${favorplaylists[playlist].id}`}
        to={`${config.REACT_APP_PREFIX}/playlist/${favorplaylists[playlist].id}`}
        onClick={() => {
          gaSender('action_mymusic_screen_click_online_list', favorplaylists[playlist].id, favorplaylists[playlist].title)
        }}
      >
        <figure className="picture_content">
          <img
            className="picture"
            src={favorplaylistImageSource(favorplaylists[playlist].id)}
            alt="slide"
          />
        </figure>

        <div className="container-description">
          <h3 className="playlistTitle">
            {favorplaylists[playlist] &&
                    favorplaylists[playlist].metadata && favorplaylists[playlist].metadata.title}
          </h3>
          <p className="playlistDescription">
            {favorplaylists[playlist] &&
                    favorplaylists[playlist].metadata &&
                    favorplaylists[playlist].metadata.description}
          </p>
          <div className="container-play">
            <button type="button" className="btn-play" onClick={() => btnPlay(favorplaylists[playlist].id)}>
              <img src="/assets/picto_player_little.svg" alt="button play" className="image-button" />
            </button>
          </div>
        </div>
      </Link>
    </div>
  )

  return (
    <div className="slider_content">
      <Carousel
        arrows={false}
        keyBoardControl
        partialVisible
        additionalTransfrom={0}
        responsive={responsive}
        slidesToSlide={1}
        renderButtonGroupOutside={true}
        customButtonGroup={<ButtonGroup />}
      >
        {Object.keys(favorplaylists).map((playlist) => (
          renderItem(playlist)
        ))}
      </Carousel>
    </div>

  )
}

FPlaylistGrid.propTypes = {
  favorplaylists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: propTypes.string,
    metadata: PropTypes.objectOf({
      description: propTypes.string,
    }) })).isRequired,
  responsive: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default FPlaylistGrid
