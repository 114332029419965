/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { gaSender } from '../../lib'
import config from '../../config'
import api from '../../api/index'

const ChangeLanguagePage = () => {
  const [langs, setLangs] = useState()
  const [langSelected, setLangSelected] = useState(i18next.language)
  const changeLanguage = () => {
    i18next.changeLanguage(langSelected)
    localStorage.setItem('lang', langSelected)
  }

  useEffect(() => {
    gaSender('page_select_language')
    const response = api.getUnitedProjectsLanguages()
    response.then((data) => setLangs(data))
  }, [])
  const { t } = useTranslation('changelanguage')

  return (
    <div className="container_change_language">
      <div>
        <img className="logoI" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
        <img className="logomobileI" width={70} src={`${config.REACT_APP_PREFIX}./iconDigster192.png`} alt="logo" />
      </div>
      <h4 className="title_change_language">{t('selectLanguageTitle')}</h4>
      <div>
        {langs && langs.map((lang) => (
          <button
            type="button"
            key={lang.id}
            className={langSelected === lang.code ? 'selected' : 'lists'}
            onClick={() => setLangSelected(lang.code)}
          >
            {t(lang.name)}
          </button>
        ))}
      </div>
      <button
        type="button"
        className="container__link"
        onClick={() => {
          window.history.back()
          changeLanguage()
        }}
      >
        <p className="link__selectlanguage">{t('validate')}</p>
      </button>
    </div>
  )
}

export default ChangeLanguagePage
