import React from 'react'
import { useTranslation } from 'react-i18next'
import './styles/index.scss'

const ModalRefreshSubscription = () => {
  const { t } = useTranslation('modalSubscription')

  return (
    <div>
      <div className="modalRefreshSubscriptionOverlay" />
      <div className="modalRefreshSubscription">
        <h1 dangerouslySetInnerHTML={{ __html: t('modalRefreshTitle') }} />
      </div>
    </div>
  )
}

export default ModalRefreshSubscription
