import React from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { getUrlDeeplink } from '../../lib/helpers'

const AppBanner = () => {
  const { t } = useTranslation('appBanner')

  return (
    <a href={getUrlDeeplink()} target="_blank" rel="noopener noreferrer" className="appBanner">
      <div className="banner-text">
        <h1>{t('title')}</h1>
        <p>{t('content')}</p>
      </div>
      <div className="banner-illu">
        <img src="/assets/images/phonedigster.png" alt="" />
      </div>
    </a>
  )
}

export default AppBanner
