import { REHYDRATE } from 'redux-persist'
import {
  STORE_EXPLORE_GENRES,
  STORE_EXPLORE_MOODS,
  STORE_EXPLORE_DECADES,
  STORE_EXPLORE_ACTIVE_FILTER,
  STORE_EXPLORE_PLAYLISTS,
  STORE_EXPLORE_PLAYLISTS_PROCESSING,
  STORE_EXPLORE_PLAYLISTS_PROCESSED,
} from '../actions'

const initialState = {
  genres: [],
  moods: [],
  decades: [],
  activeFilters: [],
  playlists: [],
  order: [],
  rehydrated: false,
  processing: false,
  hasMore: false,
  source: null,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case REHYDRATE: {
      const incoming = (payload && payload.articles) || {}
      return {
        ...state,
        ...incoming,
        rehydrated: true,
        processing: false,
      }
    }
    case STORE_EXPLORE_GENRES:
      return {
        ...state,
        genres: payload.genres,
      }
    case STORE_EXPLORE_MOODS:
      return {
        ...state,
        moods: payload.moods,
      }
    case STORE_EXPLORE_DECADES:
      return {
        ...state,
        decades: payload.decades,
      }
    case STORE_EXPLORE_ACTIVE_FILTER:
      return {
        ...state,
        activeFilters: payload.activeFilters,
      }
    case STORE_EXPLORE_PLAYLISTS:
      return {
        ...state,
        playlists: payload.playlists,
        order: payload.order,
        hasMore: payload.hasMore,
      }
    case STORE_EXPLORE_PLAYLISTS_PROCESSING:
      return {
        ...state,
        processing: true,
        hasMore: false,
      }
    case STORE_EXPLORE_PLAYLISTS_PROCESSED:
      return {
        ...state,
        processing: false,
      }
    default:
      return state
  }
}
