import api from '../api'
import {
  FAVORITE_ADD_TRACK,
  FAVORITE_REMOVE_TRACK,
  PROCESSING_FAVORITES_TRACKS,
  PROCESSED_FAVORITES_TRACKS,
  STORE_FAVORITES_TRACKS,
  RELOAD_FAVORITES_TRACKS,
  FAVORITE_ADD_PLAYLIST,
  FAVORITE_REMOVE_PLAYLIST,
  PROCESSING_FAVORITES_PLAYLISTS,
  PROCESSED_FAVORITES_PLAYLISTS,
  STORE_FAVORITES_PLAYLISTS,
  RELOAD_FAVORITES_PLAYLISTS,
  FAVORITE_PLAYLIST_ID,
} from './constants'
import { trackEvent, playlistEvent } from './analytics'
import { pauseTrack, playTrack, setTime } from './player'
import { noQueue } from './queue'

const processingFavoritesTracks = () => ({
  type: PROCESSING_FAVORITES_TRACKS,
})

const processingFavoritesPlaylists = () => ({
  type: PROCESSING_FAVORITES_PLAYLISTS,
})

const processedFavoritesTracks = () => ({
  type: PROCESSED_FAVORITES_TRACKS,
})

const processedFavoritesPlaylists = () => ({
  type: PROCESSED_FAVORITES_PLAYLISTS,
})

const trackAddedToFavorite = (track) => ({
  type: FAVORITE_ADD_TRACK,
  payload: { track },
})

const playlistAddedToFavorite = (fplaylist) => ({
  type: FAVORITE_ADD_PLAYLIST,
  payload: { fplaylist },
})

const trackRemovedFromFavorite = (track) => ({
  type: FAVORITE_REMOVE_TRACK,
  payload: { track },
})

const playlistRemovedFromFavorite = (fplaylist) => ({
  type: FAVORITE_REMOVE_PLAYLIST,
  payload: { fplaylist },
})

const storeFavoritesTracks = (tracks) => ({
  type: STORE_FAVORITES_TRACKS,
  payload: {
    tracks,
  },
})

const storeFavoritesPlaylists = (fplaylists) => ({
  type: STORE_FAVORITES_PLAYLISTS,
  payload: {
    fplaylists,
  },
})

export const addToFavorite = (track) => async (dispatch) => {
  await api.addTrackToFavorites(track.id)
  dispatch(trackAddedToFavorite(track))
  const { id, title } = track
  dispatch(trackEvent('favorite_add', { id, title }))
}

export const addPlaylistToFavoritesPlaylists = (fplaylist) => async (dispatch) => {
  await api.addPlaylistToFavorites(fplaylist.id)
  dispatch(playlistAddedToFavorite(fplaylist))
  const { id, metadata: { title } } = fplaylist
  dispatch(playlistEvent('favorite_add', { id, metadata: { title } }))
}

export const removeFromFavorite = (track) => async (dispatch, getState) => {
  const {
    queue: { queueIndex, playingTrackId, playlistId, trackIndex },
    favorite: { tracks },
    player: { currentTime },
  } = getState()
  await api.removeTrackFromFavorites(track.id)
  if (playlistId === FAVORITE_PLAYLIST_ID) {
    if (playingTrackId === track.id) {
      if (playingTrackId === tracks.slice(-1)[0].id && tracks.length > 0) {
        dispatch(playTrack(tracks[0], FAVORITE_PLAYLIST_ID, 0, 0))
        dispatch(pauseTrack())
        dispatch(noQueue()) // close the player
      } else {
        const newQueue = tracks.findIndex((e) => e.id === track.id) + 1
        dispatch(playTrack(tracks[newQueue], FAVORITE_PLAYLIST_ID, queueIndex, queueIndex))
      }
    } else if (tracks.findIndex((e) => e.id === track.id) < trackIndex) {
      let newQueue = tracks.findIndex((e) => e.id === playingTrackId)
      let newTrackIndex = newQueue - 1
      if (newQueue < 0) {
        newQueue = 0
      }
      if (newTrackIndex < 0) {
        newTrackIndex = 0
      }
      dispatch(playTrack(tracks[newQueue], FAVORITE_PLAYLIST_ID, newTrackIndex, newTrackIndex))
      dispatch(setTime(false, currentTime))
    }
  }
  dispatch(trackRemovedFromFavorite(track))
  const { id, title } = track
  dispatch(trackEvent('favorite_remove', { id, title }))
}

export const removePlaylistFromFavoritesPlaylists = (fplaylist) => async (dispatch) => {
  await api.removePlaylistFromFavorites(fplaylist.id)
  dispatch(playlistRemovedFromFavorite(fplaylist))
  const { id, metadata: { title } } = fplaylist
  dispatch(playlistEvent('favorite_remove', { id, metadata: { title } }))
}

const fetchFavoritesWithOffsetAndLimit = async (offset, limit, dispatch) => {
  const fav = await api.fetchFavorites(offset, limit)
  const tracks = fav.items
  if (offset === 0) {
    dispatch({ type: RELOAD_FAVORITES_TRACKS })
  }
  dispatch(storeFavoritesTracks(tracks))
  if (tracks.length === limit) {
    fetchFavoritesWithOffsetAndLimit(offset + limit, limit, dispatch)
  }
}

const fetchFavoritesPlaylistsWithOffsetAndLimit = async (offset, limit, dispatch) => {
  const favp = await api.fetchFavoritesPlaylists(offset, limit)
  const fplaylists = favp?.items
  if (offset === 0) {
    dispatch({ type: RELOAD_FAVORITES_PLAYLISTS })
  }
  dispatch(storeFavoritesPlaylists(fplaylists))
  if (fplaylists.length === limit) {
    fetchFavoritesPlaylistsWithOffsetAndLimit(offset + limit, limit, dispatch)
  }
}

export const fetchFavorites = () => async (dispatch) => {
  dispatch(processingFavoritesTracks())
  await fetchFavoritesWithOffsetAndLimit(0, 20, dispatch)
  dispatch(processedFavoritesTracks())
}

export const fetchFavoritesPlaylists = () => async (dispatch) => {
  dispatch(processingFavoritesPlaylists())
  await fetchFavoritesPlaylistsWithOffsetAndLimit(0, 20, dispatch)
  dispatch(processedFavoritesPlaylists())
}
