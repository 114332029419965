export const FAVORITE_PLAYLIST_ID = -1
export const TOPTRACK_PLAYLIST_ID = -2

export const ASK_CODE_FREE_TRIAL_REQUEST = 'ASK_CODE_FREE_TRIAL_REQUEST'

export const CHOOSE_UNITED_PROJECT_ID = 'CHOOSE_UNITED_PROJECT_ID'

export const CHECK_USER_FREE_TRIAL_REQUEST = 'CHECK_USER_FREE_TRIAL_REQUEST'
export const CHECK_USER_REGISTRATION_REQUEST = 'CHECK_USER_REGISTRATION_REQUEST'
export const CHECK_USER_REGISTRATION_SUCCESS = 'CHECK_USER_REGISTRATION_SUCCESS'
export const CHECK_USER_REGISTRATION_FAILURE = 'CHECK_USER_REGISTRATION_FAILURE'
export const RESET_REGISTRATION_FAILURE = 'RESET_REGISTRATION_FAILURE'

export const CHECK_VOUCHER_REQUEST = 'CHECK_VOUCHER_REQUEST'
export const CHECK_VOUCHER_SUCCESS = 'CHECK_VOUCHER_SUCCESS'

export const LOGIN_METHOD = 'LOGIN_METHOD'

export const STORE_PROJECT_LINKS = 'STORE_PROJECT_LINKS'

export const CHECK_USER_VOUCHER_REQUEST = 'CHECK_USER_VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'

export const CHECK_INTERSTITIAL_REQUEST = 'CHECK_INTERSTITIAL_REQUEST'
export const INTERSTITIAL_SUCCESS = 'INTERSTITIAL_SUCCESS'

export const CODE_VALIDATION_SUCCESSFULLY = 'CODE_VALIDATION_SUCCESSFULLY'

export const FORGETPWD_FAILED = 'FORGETPWD_FAILED'
export const FORGETPWD_SUCCESSFULLY = 'FORGETPWD_SUCCESSFULLY'

export const FAVORITE_ADD_TRACK = 'FAVORITE_ADD_TRACK'
export const FAVORITE_REMOVE_TRACK = 'FAVORITE_REMOVE_TRACK'
export const FAVORITES_LIST_ID = 'FAVORITES_LIST_ID'

export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE'

export const LOGOUT = 'LOGOUT'
export const RELOGIN_REQUEST = 'RELOGIN_REQUEST'
export const RELOGIN_FINISHED = 'RELOGIN_FINISHED'
export const RELOGIN_FAILURE = 'RELOGIN_FAILURE'

export const PROCESSING_ARTICLES_PLAYLISTS = 'PROCESSING_ARTICLES_PLAYLISTS'
export const PROCESSED_ARTICLES_PLAYLISTS = 'PROCESSED_ARTICLES_PLAYLISTS'
export const PROCESSING_CURRENT_PLAYLIST = 'PROCESSING_CURRENT_PLAYLIST'
export const PROCESSED_CURRENT_PLAYLIST = 'PROCESSED_CURRENT_PLAYLIST'
export const PROCESSING_PLAYLIST_TRACKS = 'PROCESSING_PLAYLIST_TRACKS'
export const PROCESSED_PLAYLIST_TRACKS = 'PROCESSED_PLAYLIST_TRACKS'
export const PROCESSING_FAVORITES_TRACKS = 'PROCESSING_FAVORITES_TRACKS'
export const PROCESSED_FAVORITES_TRACKS = 'PROCESSED_FAVORITES_TRACKS'
export const PROCESSING_PROJECT = 'PROCESSING_PROJECT'
export const PROCESSED_PROJECT = 'PROCESSED_PROJECT'
export const PROCESSING_TERRITORIES = 'PROCESSING_TERRITORIES'
export const PROCESSED_TERRITORIES = 'PROCESSED_TERRITORIES'
export const PROCESSING_TOP_TRACKS = 'PROCESSING_TOP_TRACKS'
export const PROCESSED_TOP_TRACKS = 'PROCESSED_TOP_TRACKS'

export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'

export const RELOAD_FAVORITES_TRACKS = 'RELOAD_FAVORITES_TRACKS'

export const STORE_ARTICLES = 'STORE_ARTICLES'
export const STORE_ARTIST = 'STORE_ARTIST'
export const STORE_PLAYLISTS_ARTIST = 'STORE_PLAYLISTS_ARTIST'
export const STORE_CONFIG = 'STORE_CONFIG'
export const STORE_CURRENT_PLAYLIST = 'STORE_CURRENT_PLAYLIST'
export const STORE_FAVORITES_TRACKS = 'STORE_FAVORITES_TRACKS'
export const STORE_PLAYLISTS = 'STORE_PLAYLISTS'
export const STORE_PLAYLIST_TRACKS = 'STORE_PLAYLIST_TRACKS'
export const STORE_PROJECT = 'STORE_PROJECT'
export const STORE_UNITED_PROJECTS = 'STORE_UNITED_PROJECTS'
export const STORE_TERRITORIES = 'STORE_TERRITORIES'
export const STORE_TOP_TRACKS = 'STORE_TOP_TRACKS'
export const REMOVE_PROJECTS = ' REMOVE_PROJECTS'

export const ONLY_FOR_YOU = 'ONLY_FOR_YOU'
export const SIMILAR_PLAYLISTS = 'SIMILAR_PLAYLISTS'

export const UPDATE_NOTIFICATION_VALUE = 'UPDATE_NOTIFICATION_VALUE'

export const USER_LOGIN = 'USER_LOGIN'

export const QUEUE_ADD_TRACKS = 'QUEUE_ADD_TRACKS'
export const QUEUE_TOGGLE_SHUFFLE = 'QUEUE_TOGGLE_SHUFFLE'
export const QUEUE_TOGGLE_REPEAT = 'QUEUE_TOGGLE_REPEAT'
export const QUEUE_DISABLE_REPEAT = 'QUEUE_DISABLE_REPEAT'
export const QUEUE_SKIP_NEXT_TRACK = 'QUEUE_SKIP_NEXT_TRACK'
export const QUEUE_SKIP_PREVIOUS_TRACK = 'QUEUE_SKIP_PREVIOUS_TRACK'
export const QUEUE_ENDED = 'QUEUE_ENDED'
export const NO_QUEUE = 'NO_QUEUE'

export const TIME_UPDATE = 'TIME_UPDATE'
export const SEEK_TO = 'SEEK_TO'
export const OPEN_PLAYER = 'OPEN_PLAYER'
export const CLOSE_PLAYER = 'CLOSE_PLAYER'
export const CHANGE_VOLUME = 'CHANGE_VOLUM'
export const PLAY_TRACK = 'PLAY_TRACK'
export const PAUSE_TRACK = 'PAUSE_TRACK'
export const RESUME_TRACK = 'RESUME_TRACK'

export const PLAYER_STATUS_IDLE = 'PLAYER_STATUS_IDLE'
export const PLAYER_STATUS_PLAYING = 'PLAYER_STATUS_PLAYING'
export const PLAYER_STATUS_PAUSED = 'PLAYER_STATUS_PAUSED'
export const PLAYER_STATUS_LOADING = 'PLAYER_STATUS_LOADING'
export const PLAYER_STATUS_SEEKING = 'PLAYER_STATUS_SEEKING'
export const UPDATE_LOGIN = 'UPDATE_LOGIN'

export const FAVORITE_ADD_PLAYLIST = 'FAVORITE_ADD_PLAYLIST'
export const FAVORITE_REMOVE_PLAYLIST = 'FAVORITE_REMOVE_PLAYLIST'
export const PROCESSING_FAVORITES_PLAYLISTS = 'PROCESSING_FAVORITES_PLAYLISTS'
export const PROCESSED_FAVORITES_PLAYLISTS = 'PROCESSED_FAVORITES_PLAYLISTS'
export const STORE_FAVORITES_PLAYLISTS = 'STORE_FAVORITES_PLAYLISTS'
export const RELOAD_FAVORITES_PLAYLISTS = 'RELOAD_FAVORITES_PLAYLISTS'

export const STORE_EXPLORE_GENRES = 'STORE_EXPLORE_GENRES'
export const STORE_EXPLORE_MOODS = 'STORE_EXPLORE_MOODS'
export const STORE_EXPLORE_DECADES = 'STORE_EXPLORE_DECADES'
export const STORE_EXPLORE_ACTIVE_FILTER = 'STORE_EXPLORE_ACTIVE_FILTER'
export const STORE_EXPLORE_PLAYLISTS = 'STORE_EXPLORE_PLAYLISTS'
export const STORE_EXPLORE_PLAYLISTS_PROCESSING = 'STORE_EXPLORE_PLAYLISTS_PROCESSING'
export const STORE_EXPLORE_PLAYLISTS_PROCESSED = 'STORE_EXPLORE_PLAYLISTS_PROCESSED'

export const MAINTENANCE = 'MAINTENANCE'

export const UPDATE_ONBOARDING_VALUE = ' UPDATE_ONBOARDING_VALUE'
export const HAS_ONBOARDED = 'HAS_ONBOARDED'
export const ON_BOARDING_SKIP = 'ON_BOARDING_SKIP'
export const ON_BOARDING_RELAUNCH = 'ON_BOARDING_RELAUNCH'
export const ON_BOARDING_GENDER = 'ON_BOARDING_GENDER'
export const ON_BOARDING_YEAR = 'ON_BOARDING_YEAR'
export const ON_BOARDING_GENRE = 'ON_BOARDING_GENRE'
export const ON_BOARDING_MOOD = 'ON_BOARDING_MOOD'
export const HIDE_ONBOARDING_VALUE = 'HIDE_ONBOARDING_VALUE'

export const GET_LANGUAGES = 'GET_LANGUAGES'
export const STORE_ONBOARDING_GENRES = 'STORE_ONBOARDING_GENRES'
export const STORE_ONBOARDING_MOODS = 'STORE_ONBOARDING_MOODS'

export const UPDATE_RGPD_CHOICE = ' UPDATE_RGPD_CHOICE'
export const UPDATE_COOKIE_CHOICE = 'UPDATE_COOKIE_CHOICE'

export const UPDATE_TUTORIAL_VALUE = 'UPDATE_TUTORIAL_VALUE'

export const VOUCHER_DATA_POPIN = 'VOUCHER_DATA_POPIN'
export const VOUCHER_DATA_POPIN_ATCONECTION = 'VOUCHER_DATA_POPIN_ATCONECTION'
