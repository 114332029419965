import React from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'react-activity'

const Loader = ({ color, size }) => (
  <Spinner color={color} size={size} />
)

Loader.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

export default Loader
