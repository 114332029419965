import ReactGA from 'react-ga'
import api from '../api'
import {
  CHOOSE_UNITED_PROJECT_ID,
  PROCESSING_PROJECT,
  PROCESSED_PROJECT,
  PROCESSING_ARTICLES_PLAYLISTS,
  PROCESSED_ARTICLES_PLAYLISTS,
  STORE_PROJECT,
  STORE_UNITED_PROJECTS,
  LOGIN_METHOD,
  STORE_PROJECT_LINKS,
} from './constants'
import { fetchArticles } from './articles'
import { fetchPlaylists } from './playlists'
import { getLanguage } from './config'
import { fetchFavorites } from './favorite'
import { history } from '../lib'

const processingArticlesAndPlaylists = () => ({
  type: PROCESSING_ARTICLES_PLAYLISTS,
})

const processedArticlesAndPlaylists = () => ({
  type: PROCESSED_ARTICLES_PLAYLISTS,
})

const processingProject = () => ({
  type: PROCESSING_PROJECT,
})

const processedProject = () => ({
  type: PROCESSED_PROJECT,
})

const storeProject = (project) => ({
  type: STORE_PROJECT,
  payload: {
    project,
  },
})

const storeProjectLinks = (links) => ({
  type: STORE_PROJECT_LINKS,
  payload: {
    links,
  },
})

const storeUnitedProjects = (projects) => ({
  type: STORE_UNITED_PROJECTS,
  payload: { projects },
})

const loginMethods = (methods) => ({
  type: LOGIN_METHOD,
  payload: { methods },
})

export const fetchProject = (projectId) => async (dispatch) => {
  dispatch(processingProject())
  let project = {}
  if (projectId) {
    project = await api.fetchProject(projectId)
  } else {
    project = await api.fetchProject()
  }
  dispatch(storeProject(project))
  dispatch(processedProject())
}

export const fetchProjectLinks = (projectId) => async (dispatch, getState) => {
  try {
    const {
      project: { PROJECT_ID },
    } = getState()
    const id = projectId || PROJECT_ID
    const links = await api.fetchProjectLinks(id)
    dispatch(storeProjectLinks(links))
  } catch (e) {
    throw e
  }
}

export const fetchArticlesAndPlaylists = () => async (dispatch, getState) => {
  dispatch(processingArticlesAndPlaylists())
  await dispatch(fetchProject())

  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const { projects } = getState().project
  const project = projects.find((prj) => prj.id === parseInt(PROJECT_ID, 10))

  dispatch(getLanguage())
  dispatch(fetchArticles(project.lists['article-list']))
  dispatch(fetchPlaylists(project.lists['list-container']))
  dispatch(fetchFavorites())
  dispatch(processedArticlesAndPlaylists())
}

export const getUnitedProjects = () => async (dispatch) => {
  const projects = await api.getUnitedProjects()
  dispatch(storeUnitedProjects(projects))
}

export const getLoginMethods = (projectId) => async (dispatch) => {
  try {
    const methods = await api.getLoginMethods(projectId)
    dispatch(loginMethods(methods))
  } catch (e) {
    throw e
  }
}

export const chooseProject = (projectId, fromAutoLogin = false) => async (dispatch, getState) => {
  dispatch(fetchProjectLinks(projectId))
  dispatch({ type: CHOOSE_UNITED_PROJECT_ID, payload: { projectId } })
  ReactGA.set({ dimension2: projectId })
  await dispatch(getLoginMethods(projectId))
  const { project: { methods } } = getState()
  const denyList = ['login_dv', 'free_trial', 'login_jmt', 'discovery_mode', 'login_bcd']
  if ((methods.indexOf('voucher') !== -1 || methods.indexOf('no_sms_voucher') !== -1) && !denyList.find((element) => methods.indexOf(element) !== -1)) {
    history.push('/voucher')
  } else if (!fromAutoLogin) {
    history.push('/phonenumber')
  }
}
