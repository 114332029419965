/* eslint-disable camelcase */
import axios from 'axios'
import config from '../config'
import { getUserFromResponse, isUserTokenExpired, isUserTokenExpiredOffline, history, refreshSession } from '../lib'

import { logout, refreshTokenSuccess, trackException, hideOnBoarding } from '../actions'

let store = null

const api = axios.create({
  baseURL: config.API_BASE_URL,
})

api.defaults.headers.post['Content-Type'] = 'application/json'

const post = async (path, data = null) => {
  const res = await api({
    method: 'post',
    url: path,
    data,
  })
  return res?.data
}

const get = async (path, params = {}, cancelToken = null) => {
  const conf = {
    method: 'get',
    url: path,
    params,
  }

  if (cancelToken) {
    conf.cancelToken = cancelToken
  }

  const res = await api(conf)
  return res?.data
}

const put = async (path, data, headers = {}) => {
  const res = await api({
    method: 'put',
    url: path,
    data,
    headers,
  })
  return res?.data
}

const del = async (path, data = {}) => {
  const res = await api({
    method: 'delete',
    url: path,
    data,
  })
  return res?.data
}

const getLanguage = () => {
  if (window.localStorage.lang) {
    return window.localStorage.lang
  }
  if (navigator.languages) {
    return navigator.languages[0].substr(0, 2)
  }
  const { config: { locale } } = store.getState()
  return locale
}

const checkUserRegistration = async (phoneNumber) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const apikey_id = config.API_KEY_ID

  return post(`/projects/${PROJECT_ID}/user/registered?apikey_id=${apikey_id}`, { login: phoneNumber })
}

const checkUserFreeTrial = async (phoneNumber, password) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const apikey_id = config.API_KEY_ID

  const response = await post(`/projects/${PROJECT_ID}/trial/checkcode?apikey_id=${apikey_id}&login=${encodeURIComponent(phoneNumber)}&code=${password}`)
  const user = getUserFromResponse(response)
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('refreshToken', user.refreshToken)
  return getUserFromResponse(response)
}

const askCodeForFreeTrial = async (phoneNumber) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const apikey_id = config.API_KEY_ID

  return post(`/projects/${PROJECT_ID}/trial/askcode?apikey_id=${apikey_id}&login=${encodeURIComponent(phoneNumber)}`)
}
const resetPassword = async (login) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  try {
    return await get(`/projects/${PROJECT_ID}/forgot-password?login=${login}`)
  } catch (error) {
    console.log(error)
  }
}

const checkVoucher = async (phoneNumber, code, password) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const response = await post(`/projects/${PROJECT_ID}/vouchers/use?login=${phoneNumber}&vouchercode=${code}&code=${password}`)
  const user = getUserFromResponse(response)
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('refreshToken', user.refreshToken)
  return getUserFromResponse(response)
}
const loadMaintenance = async () => {
  try {
    return await get('/status')
  } catch (error) {
    throw error
  }
}

const voucher = async (phone, code) => {
  const { config: { locale }, project: { methods } } = store.getState()
  const lang = locale || getLanguage()
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  let response
  if (methods.indexOf('no_sms_voucher') !== -1) {
    response = await post(`/projects/${PROJECT_ID}/vouchers?login=${phone}&vouchercode=${code}`)
    const user = getUserFromResponse(response)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('refreshToken', user.refreshToken)
    response = getUserFromResponse(response)
  } else {
    response = await post(`/projects/${PROJECT_ID}/vouchers/askcode?login=${phone}&vouchercode=${code}&lang=${lang}`)
  }
  return response
}

const verifyInterstitialPic = async (setId, accessToken) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  try {
    return get(`assets/${PROJECT_ID}/vouchers/${setId}/desktop.png?authorization=${accessToken}`)
  } catch (error) {
    console.log(error)
  }
}
const fetchArticles = async (containerId, offset = 0, limit = 100) => {
  const { config: { locale } } = store.getState()
  const lang = locale || getLanguage()
  return get(`/lists/${containerId}/articles`, { lang, limit, offset })
}

const fetchArtist = async (artistPage) => {
  const { config: { locale: lang } } = store.getState()
  const response = await get(`lists/${artistPage}`, { lang })
  return response
}

const fetchPlaylistsArtist = async (artistPage) => {
  const lang = getLanguage()
  return get(`lists/${artistPage}/lists`, { lang, limit: 100 })
}

const fetchPlaylists = async (containerId, offset = 0, limit = 100, cancelToken = null) => {
  const lang = getLanguage()
  const params = {
    lang,
    limit,
    offset,
  }
  return get(`/lists/${containerId}/lists`, params, cancelToken)
}

const fetchPlaylist = async (listId) => {
  const { config: { locale } } = store.getState()
  const lang = locale || getLanguage()
  return get(`/lists/${listId}`, { lang })
}

const fetchPlaylistTracks = async (playlistId) => get(`/lists/${playlistId}/tracks`, { limit: 200 })

const fetchTopTracks = async (projectId) => get(`/top/tracks?project_id=${projectId}&limit=20`)

const fetchProject = async (projectId) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  let project = {}
  if (projectId) {
    project = await get(`/projects/${projectId}`)
  } else {
    project = await get(`/projects/${PROJECT_ID}`)
  }
  return {
    ...project,
  }
}

const fetchProjectLinks = async (projectId) => {
  try {
    if (projectId > 0) {
      return await get(`/assets/${projectId}/links.json`)
    }
  } catch (error) {
    throw error
  }
}

const updateUserGender = async (gender) => put('/me', { user: gender })

const getUserGender = async () => {
  try {
    const { user: { authUser } } = store.getState()
    const token = authUser.accessToken
    return await get('/me', { token })
  } catch (error) {
    throw error
  }
}

const updateUserBirth = async (birthdate) => put('/me', { user: birthdate })

const getUserBirth = async () => {
  try {
    const { user: { authUser } } = store.getState()
    const token = authUser.accessToken
    return await get('/me', { token })
  } catch (error) {
    throw error
  }
}

const updateFavoriteGenre = async (genre) => post('/me/favorites/genres?clean=true', { items: genre })

const getFavoriteGenres = async () => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    return await get(`/me/favorites/genres?lang=${lang}`, { token })
  } catch (error) {
    throw error
  }
}

const updateFavoriteMood = async (mood) => post('/me/favorites/moods?clean=true', { items: mood })

const getFavoriteMoods = async () => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    return await get(`/me/favorites/moods?lang=${lang}`, { token })
  } catch (error) {
    throw error
  }
}

const updateOptinRgpdChoice = async () => {
  try {
    const { rgpd: { choiceRgpd } } = store.getState()
    const id = choiceRgpd.idOptin
    const val = choiceRgpd.value
    return put(`/me/optins/${id}`, { value: val })
  } catch (error) {
    throw error
  }
}

const updateOptinCookieChoice = async () => {
  try {
    let id
    let val
    const choice = []
    const acceptRgpd = [true, true, true, true]
    const refuseRgpd = [false, false, false, false]
    const { rgpd: { choiceCookie } } = store.getState()
    choiceCookie.forEach((element) => {
      id = element.idOptin
      val = element.value
      choice.push(val)
      return put(`/me/optins/${id}`, { value: val })
    })
    if (JSON.stringify(choice) === JSON.stringify(acceptRgpd)) {
      store.dispatch(hideOnBoarding(false))
    }
    if (JSON.stringify(choice) === JSON.stringify(refuseRgpd)) {
      store.dispatch(hideOnBoarding(true))
    } else {
      store.dispatch(hideOnBoarding(false))
    }
  } catch (error) {
    throw error
  }
}

const getOptinChoice = async () => {
  try {
    return get('/me/optins')
  } catch (error) {
    throw error
  }
}

const fetchTerritories = async (containerId) => {
  const lang = getLanguage()
  return get(`/lists/${containerId}/projects`, { lang })
}

const getUnitedProjects = async () => get('/project_group/3')

const loginWithCredentials = async (login, password) => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const apikey_id = config.API_KEY_ID

  const response = await post(`/projects/${PROJECT_ID}/token?apikey_id=${apikey_id}`, { login, password })
  const user = getUserFromResponse(response)
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('refreshToken', user.refreshToken)
  return getUserFromResponse(response)
}

const autoLoginWithSubscriptionId = async (subscription_id) => {
  const apikey_id = config.API_KEY_ID
  const response = await get(`/autologin?apikey_id=${apikey_id}&subscription_id=${subscription_id}`)
  const user = getUserFromResponse(response)
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('refreshToken', user.refreshToken)
  return getUserFromResponse(response)
}

const getSubId = async () => get('/me/subscription_id')

const fetchFavorites = async (offset = 0, limit = 20) => get('/me/favorites/tracks', { offset, limit })

const fetchFavoritesPlaylists = async (offset = 0, limit = 20) => get('/me/favorites/lists', { offset, limit })

const addTrackToFavorites = async (track) => post('/me/favorites/tracks', {
  items: [track],
  clean: false,
})

const addPlaylistToFavorites = async (fplaylist) => post('/me/favorites/lists', {
  items: [fplaylist],
  clean: false,
})

const removeTrackFromFavorites = async (track) => del('/me/favorites/tracks', {
  items: [track],
})

const removePlaylistFromFavorites = async (fplaylist) => del('/me/favorites/lists', {
  items: [fplaylist],
})

const sendStats = async (
  // eslint-disable-next-line camelcase
  status,
  id,
  creation_datetime,
  time,
  device_type,
  playlist_id,
  play_mode,
  current_position,
  full,
  format,
) => post(
  '/stats',
  {
    items: [
      {
        status,
        id,
        creation_datetime,
        time,
        device_type,
        playlist_id,
        play_mode,
        current_position,
        full,
        format,
      }],
  },
)

const fetchCategories = async (category, limit = 1) => {
  try {
    const lang = getLanguage()
    const PROJECT_ID = localStorage.getItem('PROJECT_ID')
    return await get(`projects/${PROJECT_ID}/categories?id_parent_category=${category}&min_tag_lists_needed=${limit}`, { lang, limit: 100 })
  } catch (error) {
    console.log(error)
  }
}

const fetchMoods = async (offset = 0, limit = 20) => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    return await get('me/recommendations/moods', { lang, offset, limit, token })
  } catch (error) {
    throw error
  }
}
const fetchGenres = async (offset = 0, limit = 20) => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    return await get('me/recommendations/genres', { lang, offset, limit, token })
  } catch (error) {
    throw error
  }
}

const fetchSimilarPlaylists = async (id, offset = 0, limit = 20) => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    return await get(`/lists/${id}/similars`, { lang, offset, limit, token })
  } catch (error) {
    throw error
  }
}

const fetchPlaylistsWithCategories = async (categories, offset, limit, cancelToken = null) => {
  try {
    const { config: { locale } } = store.getState()
    const lang = locale || getLanguage()
    let cat = ''
    categories.forEach((category) => {
      cat += `&category=${category}`
    })
    return await get(`/lists?lang=${lang}${cat}&offset=${offset}&limit=${limit}&type=1`, null, cancelToken)
  } catch (error) {
    console.log(error)
  }
}

const fetchPlaylistsFavoriteUser = async (categories, offset, limit) => {
  try {
    const { config: { locale }, user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = locale || getLanguage()
    let cat = ''
    if (categories) {
      categories.forEach((category) => {
        cat += `&id_categories=${category}`
      })
    }
    return await get(`/me/explorer?lang=${lang}${cat}&limit=${limit}&offset=${offset}`, { token })
  } catch (error) {
    console.log(error)
  }
}

const fetchPlaylistForOnlyForYou = async () => {
  try {
    const { user: { authUser } } = store.getState()
    const token = authUser.accessToken
    const lang = getLanguage()
    return await get(`/me/recommendations/only_for_me?lang=${lang}`, { token })
  } catch (error) {
    console.log(error)
  }
}

const updateHasOnBoarded = async () => {
  try {
    const { user: { authUser } } = store.getState()
    const token = authUser.accessToken
    return await get('/me/has_onboarded', { token })
  } catch (error) {
    console.log(error)
  }
}

const getUnitedProjectsLanguages = async () => {
  try {
    return await get('project_group/3/languages')
  } catch (error) {
    throw error
  }
}

const getToken = async () => {
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  return get(`/projects/${PROJECT_ID}/token`)
}
const getLoginMethods = async (projectId) => {
  try {
    const response = await get(`/projects/${projectId}/login_methods`)
    return response
  } catch (e) {
    throw e
  }
}

const setStore = (s) => {
  store = s
}

const requestWithAuthorization = (request, token) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `bearer ${token}`,
  },
})

api.interceptors.request.use((cfg) => {
  const originalRequest = cfg
  const {
    user: { authUser },
  } = store.getState()

  const isLogin = cfg.url.indexOf('/token') !== -1
  if (originalRequest.url.indexOf('translations') !== -1
    || originalRequest.url.indexOf('links.json') !== -1
    || originalRequest.url.indexOf('languages') !== -1
    || originalRequest.url.indexOf('version') !== -1
    || originalRequest.url.indexOf('project_group') !== -1
    || originalRequest.url.indexOf('status') !== -1) {
    return Promise.resolve(originalRequest)
  }

  if (authUser && authUser.accessToken && !isLogin) {
    if (isUserTokenExpired(authUser.accessToken)) {
      return getToken().then(async (tokens) => {
        const { access_token: newAccessToken, refresh_token: newRefreshToken } = tokens

        localStorage.removeItem('user')
        const user = refreshSession(newAccessToken, newRefreshToken)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('refreshToken', user.refreshToken)

        await store.dispatch(refreshTokenSuccess(newAccessToken, newRefreshToken))
        return Promise.resolve(requestWithAuthorization(originalRequest, newAccessToken))
      }, () => {
        store.dispatch(logout(true))
        return Promise.reject(new Error('E_REFRESH_TOKEN_FAILED'))
      })
    }

    if (isUserTokenExpiredOffline(authUser.accessToken)) {
      store.dispatch(logout(true))
      return Promise.reject(new Error('E_REFRESH_TOKEN_FAILED'))
    }
  }
  let token = null
  const refreshToken = localStorage.getItem('refreshToken')
  if (authUser || refreshToken) {
    token = (cfg.method === 'get' && isLogin) ? refreshToken || authUser.refreshToken : authUser.accessToken
  }
  if (token) {
    return requestWithAuthorization(cfg, token)
  }
  return cfg
}, (error) => Promise.reject(error))

api.interceptors.response.use((response) => response, (error) => {
  let code
  if (error?.response?.data?.code) {
    ({ response: { data: { code } } } = error)
  }

  if (!error.request) {
    if (code !== 'E-USER-SUBSCRIPTION-INVALID') {
      history.push('/phonenumber')
    }
    return Promise.resolve()
  }

  if (code === 'E-USER-SUBSCRIPTION-INVALID') {
    return Promise.resolve()
  }

  const { status, responseURL: url } = error.request
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')

  if (url.indexOf(`/projects/${PROJECT_ID}/trial/checkcode?apikey_id`) !== -1) {
    switch (status) {
      case 403:
        code = 'FREE_TRIAL_INVALID_CODE'
        break
      default:
        code = 'FREE_TRIAL_ERROR'
    }
  }

  if (url.indexOf(`/projects/${PROJECT_ID}/token?apikey_id`) !== -1) {
    switch (status) {
      case 403:
        code = 'LOGIN_INVALID_CODE'
        break
      default:
        code = 'LOGIN_ERROR'
    }
  }

  if (url.indexOf(`/assets/${PROJECT_ID}/vouchers`) !== -1) {
    switch (status) {
      case 404:
        code = 'Not Found'
        break
      default:
        code = 'NO-PIC'
    }
  }

  if (error.message === 'cancel') {
    return true
  }
  store.dispatch(trackException(code))
  return Promise.reject(new Error(code))
})

export default {
  get,
  post,
  put,
  askCodeForFreeTrial,
  checkUserRegistration,
  checkUserFreeTrial,
  loginWithCredentials,
  fetchArticles,
  fetchTopTracks,
  fetchPlaylists,
  fetchPlaylist,
  fetchPlaylistTracks,
  fetchProject,
  fetchTerritories,
  getUnitedProjects,
  addTrackToFavorites,
  removeTrackFromFavorites,
  resetPassword,
  fetchFavorites,
  addPlaylistToFavorites,
  removePlaylistFromFavorites,
  fetchFavoritesPlaylists,
  sendStats,
  fetchArtist,
  fetchPlaylistsArtist,
  fetchCategories,
  fetchPlaylistsWithCategories,
  loadMaintenance,
  updateUserGender,
  updateUserBirth,
  updateFavoriteGenre,
  updateFavoriteMood,
  setStore,
  fetchPlaylistsFavoriteUser,
  fetchPlaylistForOnlyForYou,
  fetchMoods,
  fetchGenres,
  fetchSimilarPlaylists,
  updateHasOnBoarded,
  getUserGender,
  getUserBirth,
  getFavoriteGenres,
  getFavoriteMoods,
  voucher,
  checkVoucher,
  verifyInterstitialPic,
  getLoginMethods,
  getUnitedProjectsLanguages,
  fetchProjectLinks,
  updateOptinRgpdChoice,
  updateOptinCookieChoice,
  getOptinChoice,
  autoLoginWithSubscriptionId,
  getToken,
  getSubId,
}
