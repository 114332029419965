import React, { useState } from 'react'
import './styles/index.scss'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { MainMenu, Rgpd, ModalCancelSubscription } from '../../components'
import { relaunchOnBoarding, fetchProjectLinks, logoutUser, getOptinChoice } from '../../actions'
import { gaSender } from '../../lib'

const Settings = () => {
  const { t } = useTranslation('settings')
  const dispatch = useDispatch()
  const links = useSelector((state) => state.project.links)
  const locale = useSelector((state) => state.config.locale)
  const projectId = useSelector((state) => state.project.PROJECT_ID)
  const [rgpdDisplay, setRgpdDisplay] = useState(false)
  const hideMyMusicProfil = useSelector((state) => state.onboarding.hideMyMusicProfil)
  const authUser = useSelector((state) => state.user.authUser)
  const rgpdChoice = useSelector((state) => state.rgpd)
  const [isShowingModalCancelSubscription, setIsShowingModalCancelSubscription] = useState(false)

  const handleLogoutUser = () => {
    dispatch(logoutUser())
    localStorage.removeItem('refreshToken')
  }

  const toggleModal = () => {
    setIsShowingModalCancelSubscription(!isShowingModalCancelSubscription)
  }

  const cancelSubscription = () => {
    console.log('dispatch vers appel api(POST /webhooks/dv/unsubscribe')
  }

  const subcriptionDate = () => {
    const endDate = new Date(authUser.subscription.end_date).toUTCString()
    if (localStorage.getItem('lang') === 'fr') {
      return dayjs(endDate).format('DD/MM/YYYY')
    }
    return dayjs(endDate).format('MM/DD/YYYY')
  }

  React.useEffect(() => {
    gaSender('page_settings')
    dispatch(fetchProjectLinks(projectId))
    dispatch(getOptinChoice())
  }, [dispatch, projectId])

  const rgpd = () => {
    dispatch(getOptinChoice())
    setRgpdDisplay(true)
  }

  const rgpdClose = () => {
    setRgpdDisplay(false)
  }

  const checkAllowMusicProfile = () => {
    const personalData = rgpdChoice.choiceCookie.find((x) => x.idOptin === 1)
    const personalizedContent = rgpdChoice.choiceCookie.find((x) => x.idOptin === 3)
    if (!personalData?.value || !personalizedContent?.value) {
      return false
    }
    return true
  }

  return (
    <main className="container-page container_settings">
      {/* main content */}
      <article className="main_content">
        {rgpdDisplay && (
          <Rgpd location={{ state: { scene: 'settings' } }} rgpdCloseHandler={rgpdClose} />
        )}
        {/* <div className="container_settings--"> */}
        <h1 className="title-page">{t('settings')}</h1>
        <div className="settings_language">
          <Link className="link_change_language" to="/changeLanguage">
            {t('changeLanguage')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </Link>
        </div>
        {(!hideMyMusicProfil && checkAllowMusicProfile() !== false) && (
        <div className="settings_onboarding">
          <Link
            className="relaunch-link"
            to="/onboardingwelcome"
            onClick={() => dispatch(relaunchOnBoarding())}
          >
            {t('relaunchonboarding')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </Link>
        </div>
        )}
        {links && links.contactlinks && (
        <div className="settings_contact">
          <a
            href={`${links.contactlinks[locale]}`}
            alt={t('help')}
            className="link-register"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('help')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </a>
        </div>
        )}
        {links && links.credit && (
        <div className="settings_credit">
          <a
            href={`${links.credit[locale]}`}
            alt={t('credit')}
            className="link-register"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('credit')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </a>
        </div>
        )}
        <div className="settings_rgpd">
          <Link
            to="#"
            className="link_change_rgpd"
            onClick={() => { rgpd() }}
          >
            {t('changeRgpd')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </Link>
        </div>
        <div className="settings_logout">
          <Link
            to="/phonenumber"
            onClick={() => handleLogoutUser()}
          >
            {t('logout')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </Link>
        </div>
        <div className="settings_tutorial">
          <Link to="/tutorial">
            {t('tutorial')}
            <img src="/assets/picto_fleche_go.svg" alt="" className="image-button" />
          </Link>
        </div>
        <div className="settings_subscription">
          <p>{t('subscriptionTitle')}</p>
          <div className="subscription">
            <p>
              {t('subscriptionDesc')}
              {' '}
              {subcriptionDate()}
            </p>
            <button
              type="button"
              onClick={() => setIsShowingModalCancelSubscription(true)}
            >
              <p>{t('cancelSub')}</p>
            </button>
          </div>
        </div>
        {isShowingModalCancelSubscription && (
          <ModalCancelSubscription isShowing={isShowingModalCancelSubscription} hide={toggleModal} cancel={cancelSubscription} />
        )}
        {/* </div> */}
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
    </main>
  )
}

export default Settings
