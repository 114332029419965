import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import { useTranslation } from 'react-i18next'
import { resetPassword, fetchProject } from '../../actions'
import './styles/index.scss'
import { gaSender } from '../../lib'

const ForgotPwd = () => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [loginUser, setLoginUser] = useState('')
  const user = useSelector((reducerState) => reducerState.user)
  const { t } = useTranslation('forgotpwd')
  const methods = useSelector((reducerState) => reducerState.project.methods)
  const projects = useSelector((reducerState) => reducerState.project.projects)
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const project = projects[PROJECT_ID]
  const processing = useSelector((reducerState) => reducerState.project.processing)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isAlertActive: false,
  })
  let phonePrefix = null
  if (project) {
    phonePrefix = project.phone_prefix
  }

  const checkMethod = useCallback(async () => {
    if (methods) {
      if (methods.indexOf('login_dv') !== -1) {
        setState((prevState) => ({
          ...prevState,
          loginDv: true,
          loginBCD: false,
        }))
      }
      if (methods.indexOf('login_bcd') !== -1) {
        setState((prevState) => ({
          ...prevState,
          loginBCD: true,
          loginDv: false,
        }))
      }
    }
  }, [methods])

  React.useEffect(() => {
    gaSender('action_auth_screen_click_forgot_pwd')
    localStorage.removeItem('user')
    checkMethod()
    dispatch(fetchProject())
  }, [dispatch, checkMethod])

  const handleSubmit = (async () => {
    gaSender('action_auth_screen_click_pwd_request')
    const { loginDv, loginBCD } = state
    if (loginDv) {
      try {
        const login = encodeURIComponent(phoneNumber.replace(phonePrefix, ''))
        dispatch(resetPassword(login))
      } catch (e) {
        console.log(e)
      }
    } else if (loginBCD) {
      console.log('login BCD forgot password', loginBCD, loginUser)
    }
  })

  const {
    loginDv,
    loginBCD,
  } = state

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }
  return (
    <div className="forgot-pwd__page main_page">
      <div className="container-forgot-pwd container_page">
        <Link className="link_return" to="/identification">
          <img src="/assets/fleche_retour.png" alt="return" />
        </Link>
        {/* <div className="content-forget-pwd"> */}
        <img className="imagelogo" src="./logo_digster768.svg" alt="logo" />
        <h1 className="titre_page">{t('titleforgotpwd')}</h1>
        {loginDv ? (
          <p className="chapo_text">{t('forgotpwdDv')}</p>
        ) : (
          <p className="chapo_text">{t('forgotpwd')}</p>
        )}
        {!processing && (
        <div className="container__code">
          {loginBCD ? (
            <div className="container__input">
              <input
                type="text"
                placeholder={t('placeholder')}
                className="input"
                value={loginUser}
                onChange={(e) => {
                  setLoginUser(e.target.value)
                }}
                onKeyPress={(event) => handleKeyPress(event)}
              />
            </div>
          ) : (
            <div className="container__input__dv">
              <span className="prefix">
                {phonePrefix}
              </span>
              <input
                className="input"
                value={phoneNumber}
                placeholder={t('placeholderDv')}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                }}
                onKeyPress={(event) => handleKeyPress(event)}
              />
            </div>
          )}
        </div>
        )}
        {user.error && (<p className="wrong alert alert-danger">{t('alert')}</p>)}
        <div className="container__button">
          <button className="button__validation" alt={t('calltoaction')} type="button" onClick={() => handleSubmit()}>{t('calltoaction')}</button>
        </div>
        {/* </div> */}
      </div>
    </div>
  )
}

export default ForgotPwd
