import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getProjectNameByIdProject, history } from '../../lib'
import './styles/index.scss'

const RegistrationPortal = ({ match }) => {
  const { project_id: projectId } = match.params
  const { t } = useTranslation('registrationPortal')
  const projects = useSelector((reducerState) => reducerState.project.projects)
  const [projectName, setProjectName] = useState('')
  const connect = () => {
    history.push('/selectcountry')
  }

  const register = () => {
    history.push(`/subscribe?territory=${projectName}`)
  }

  useEffect(() => {
    if (projectId) {
      const pName = getProjectNameByIdProject(parseInt(projectId, 10), projects)
      if (pName) {
        setProjectName(pName)
      }
    }
  }, [projectId, projects])

  return (
    <div className="registrationPortal__page">
      <div className="containerRegistration">
        <img className="containerRegistration__imagelogo" width={126} src="/logo_digster768.svg" alt="logo" />
        <div className="container__button">
          <button
            className="button__registrationPortal"
            type="button"
            onClick={() => connect()}
          >
            <span>{t('connection')}</span>
          </button>
          <button
            className="button__registrationPortal"
            type="button"
            onClick={() => register()}
          >
            <span>{t('registration')}</span>
          </button>
        </div>
        <div className="containerRegistration__storeBtn">
          <a title="apple store" href="https://apps.apple.com/fr/app/digster/id1520638158">
            <img src="/ios-download.png" alt="logo" />
          </a>
          <a title="google play store" href="https://play.google.com/store/apps/details?id=uol.digster">
            <img src="/google-download-btn.png" alt="logo" />
          </a>
        </div>
      </div>
    </div>
  )
}
RegistrationPortal.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape({
    project_id: PropTypes.string,
  })).isRequired,
}
export default RegistrationPortal
