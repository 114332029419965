import React, { useEffect } from 'react'
import './styles/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainMenu, Player, PlaylistHeader, TracksList } from '../../components'
import { fetchFavorites } from '../../actions'
import configureStore from '../../store'
import { gaSender } from '../../lib'

const { store } = configureStore()

const MyFavorites = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('mymusic')
  const {
    user,
  } = store.getState()
  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const ftracks = useSelector((state) => state.favorite.tracks)
  const queue = useSelector((state) => state.queue.playlistId)

  useEffect(() => {
    gaSender('page_favorite_screen')
    dispatch(fetchFavorites())
  }, [dispatch])

  return (
    <div className={`container-page playlist ${(queue !== null) ? 'container-page-with-player' : ''}`}>
      {/* main content */}
      <article className="main_content">
        <div className="main-playlist">
          <div className="content_playlist">
            {ftracks.length > 0 ? (
              <>
                <PlaylistHeader
                  isMyFavorites={true}
                  playlist={ftracks}
                  tracks={ftracks}
                />
                <div className="body-list-group">
                  {ftracks && (
                  <TracksList isMyFavorites={true} tracks={ftracks} user={user} lang={lang} />
                  )}
                </div>
              </>
            ) : (
              <div className="empty-fav">
                <button className="backArrow" type="button" onClick={() => window.history.back()}>
                  <img
                    src="/assets/fleche_retour.png"
                    alt="back arrow"
                  />
                </button>
                <p className="title-section">{t('zerotrackliked')}</p>
              </div>
            )}

          </div>
        </div>
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
      <Player
        isMyFavorites={true}
      />
    </div>
  )
}

export default MyFavorites
