import {
  VOUCHER_DATA_POPIN,
} from './constants'

export const updateVoucherPopIn = (voucherPopInData, voucherPopInTime) => (dispatch) => {
  dispatch({
    type: VOUCHER_DATA_POPIN,
    payload: {
      voucherPopInData,
      voucherPopInTime,
    },
  })
}
