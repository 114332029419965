import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RELOGIN_FINISHED } from '../../actions'
import config from '../../config'
import './styles/index.scss'

const Relogin = () => {
  const { relogin, reloginError } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { t } = useTranslation('relogin')

  if (relogin) {
    return (
      <div className="containerRelogin">
        <button type="button" className="containerRelogin__close" aria-label="Close" onClick={() => dispatch({ type: RELOGIN_FINISHED })}>
          <img src="assets/croix.png" alt="" />
        </button>
        <img
          width={130}
          src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`}
          alt="logo"
        />
        {reloginError ? (
          <p className="containerRelogin__message" dangerouslySetInnerHTML={{ __html: t('error') }} />
        ) : (
          <p className="containerRelogin__message">{t('connection')}</p>
        )}
      </div>
    )
  }

  return null
}

export default Relogin
