import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'

const ButtonGroup = ({ next, previous }) => (
  <div className="carousel-button-group">
    <button className="btn btn-link" type="button" onClick={() => previous()}>
      <img
        src="/assets/arrow_left.svg"
        alt="back arrow"
      />
    </button>
    <button className="btn btn-link" type="button" onClick={() => next()}>
      <img
        src="/assets/arrow_right.svg"
        alt="next arrow"
      />
    </button>
  </div>
)

ButtonGroup.propTypes = {
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired,
}

export default ButtonGroup
