import React, { useState, useCallback } from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { history, gaSender } from '../../lib'
import { updateUserBirth } from '../../actions'
import { OnBoardingSkipButton } from '../../components'

const OnBoardingBirth = () => {
  const { t } = useTranslation('onboarding')
  const [errorYear, setErrorYear] = useState('')
  const [errorNumb, setErrorNumb] = useState('')
  const yearUser = useSelector((state) => state.onboarding.yearUser)
  const [yearBirth, setYearBirth] = useState(yearUser || '')
  const dispatch = useDispatch()

  React.useEffect(() => {
    gaSender('page_onboarding_birthday')
  })

  const handleChangeYear = (e) => setYearBirth(e.target.value)
  const handleSubmit = useCallback(async () => {
    if (yearBirth.length !== 4) {
      setErrorNumb(true)
    } else {
      const date = new Date()
      const yearNumber = date.getFullYear()
      if (yearBirth <= yearNumber && yearBirth >= 1900) {
        try {
          await dispatch(updateUserBirth({ birthdate: `${yearBirth}-10-10` }))
          history.push('./onboardinggenres')
        } catch (e) {
          throw e
        }
      } else {
        setErrorYear(true)
      }
    }
  }, [dispatch, yearBirth])

  return (
    <div className="container_onboarding_birth">
      <div className="bg-onboarding-birth">
        <OnBoardingSkipButton />
        <div className="onboarding-birth-content">
          <h1>{t('titlebirth')}</h1>
          <input
            className="input-birth"
            type="number"
            onChange={handleChangeYear}
            value={yearBirth}
            name="birthdate"
            style={{ textAlign: 'center' }}
          />
          {errorNumb && (<p>{t('onboardingbirthnumberror')}</p>)}
          {errorYear && (<p>{t('onboardingbirtherror')}</p>)}
          <button
            className="btn-birth"
            type="button"
            onClick={() => handleSubmit(yearBirth)}
          >
            {t('onboardingvalidate')}

          </button>
        </div>
      </div>
    </div>
  )
}
export default OnBoardingBirth
