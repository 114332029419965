import React from 'react'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import { fetchTerritories } from '../../actions'
import 'react-multi-carousel/lib/styles.css'
import { ButtonGroup } from '..'
import { useItemsToDispayHelper } from '../../lib/ItemsToDisplayHelper'
import { gaSender } from '../../lib'

const TopCountry = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('topcountry')
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const projects = useSelector((reducerState) => reducerState.project.projects)
  const processing = useSelector((reducerState) => reducerState.project.processing)
  const territories = useSelector((state) => state.territories.territories.items)
  const project = projects.find((p) => p.id.toString() === PROJECT_ID)
  let topTrackContainer = null
  if (project) {
    topTrackContainer = project.lists['top-track-container']
  }

  const slopeFactor = 188 // largeur des images dans le carousel
  const margin = 200

  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 2.3,
      pprojectialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2.7,
      pprojectialVisibilityGutter: 30,
    },
    tabletXL: {
      breakpoint: {
        max: 1440,
        min: 1024,
      },
      items: 3.5,
      partialVisibilityGutter: 30,
    },
    desktopSM: {
      breakpoint: {
        max: 3000,
        min: 1440,
      },
      items: useItemsToDispayHelper(slopeFactor, margin).integerToDisplay,
      partialVisibilityGutter: useItemsToDispayHelper(slopeFactor, margin).remainderToDisplay,
    },
  }

  React.useEffect(() => {
    if (project) {
      dispatch(fetchTerritories(topTrackContainer))
    }
  }, [dispatch, topTrackContainer, project])

  const getProjectFlag = (projectId) => {
    let apiPath = ''
    try {
      apiPath = `assets/united/flags/${projectId}.png`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}`
  }

  const getProjectCover = (projectId) => {
    let apiPath = ''
    try {
      apiPath = `assets/${projectId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/country.jpg`
  }

  return (
    <div className="container__top-country slider_bloc">
      {!processing && territories && (
        <div className="container-main-carousel">
          <div className="container-titles-extra slider-title">
            <h2 className="title-extra title">{t('topcountry')}</h2>
          </div>
          <div className="slider-body">
            <Carousel
              arrows
              keyBoardControl
              responsive={responsive}
              slidesToSlide={1}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {Object.keys(territories).map((territory) => (
                territories[territory] && (
                  <div key={`item-${territories[territory].id}`} className="slider_item superposition ">
                    <Link
                      id={`user_goTopTrack_country_${territories[territory].territory[0].code}`}
                      key={`link-${territories[territory].id}`}
                      className="text-white"
                      to={`${config.REACT_APP_PREFIX}/toptracks/${territories[territory].id}`}
                      onClick={() => {
                        gaSender('top_tracks', territories[territory].id)
                      }}
                    >
                      <figure className="img_content">
                        <img
                          key={`img-cover-${territories[territory].id}`}
                          src={getProjectCover(territories[territory].id)}
                          alt="slide"
                          className="cover"
                        />
                        <img
                          key={`img-flag-${territories[territory].id}`}
                          src={getProjectFlag(territories[territory].id)}
                          alt="slide"
                          className="flag"
                        />
                      </figure>
                      <div className="container-description-territory">
                        <p><strong>{t(territories[territory].territory[0].name)}</strong></p>
                      </div>
                    </Link>
                  </div>
                )
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  )
}

export default TopCountry
