import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Seeker } from '..'
import config from '../../config'
import { gaSender } from '../../lib'
import {
  fetchPlaylistTracks, skipNextTrack, skipPreviousTrack, addToFavorite,
  removeFromFavorite, toggleRepeat, pauseTrack, resumeTrack,
  setVolume, setTime, toggleShuffle, updateSeekTime, sendStats, fetchTopTracks, fetchFavorites,
  FAVORITE_PLAYLIST_ID, TOPTRACK_PLAYLIST_ID,
} from '../../actions'
import configureStore from '../../store'

const { store } = configureStore()

const Player = ({ isMyFavorites }) => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)

  const dispatch = useDispatch()
  const {
    user,
  } = store.getState()
  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { t } = useTranslation('player')
  const queue = useSelector((state) => state.queue)
  const player = useSelector((state) => state.player)
  const favoriteTracks = useSelector((state) => state.favorite.tracks)
  const topTracks = useSelector((state) => state.toptracks.topTracks)
  const playlistTracks = useSelector((state) => state.playlists.tracks)
  const [seekValue, setSeekValue] = useState(null)
  let tracks = []

  useEffect(() => {
    setTimeout(() => {
      if (queue.playlistId === FAVORITE_PLAYLIST_ID) {
        dispatch(fetchFavorites())
      } else if (queue.playlistId === TOPTRACK_PLAYLIST_ID && queue.territoryId) {
        dispatch(fetchTopTracks(queue.territoryId))
      } else if (queue.playlistId > 0) {
        dispatch(fetchPlaylistTracks(queue.playlistId))
      }
    }, 100)
    setInterval(() => {
      dispatch(updateSeekTime())
    }, 100)
  }, [dispatch, queue.playlistId, queue.territoryId])

  const isFavorite = () => {
    let fav = false
    favoriteTracks.forEach((tr) => {
      if (tr.id === queue.playingTrackId) {
        fav = tr.favorite
      }
    })
    return fav
  }

  const onClickFavoriteButton = () => {
    if (queue.playlistId !== null && queue.playlistId !== -1) {
      const track = tracks[queue.trackIndex]
      if (isFavorite()) {
        dispatch(removeFromFavorite(track))
        gaSender('favorite_remove', track.id, track.title)
      } else {
        dispatch(addToFavorite(track))
        gaSender('favorite_add', track.id, track.title)
      }
    }
    if (queue.playlistId !== null && queue.playlistId === -1) {
      const track = favoriteTracks[queue.trackIndex]
      if (isFavorite()) {
        dispatch(removeFromFavorite(track))
        gaSender('favorite_remove', track.id, track.title)
      } else {
        dispatch(addToFavorite(track))
        gaSender('favorite_add', track.id, track.title)
      }
    }
  }

  const isPlaying = () => {
    let playing = null
    if (player.status === 'PLAYER_STATUS_PLAYING') {
      playing = true
    } else if (player.status === 'PLAYER_STATUS_PAUSED') {
      playing = false
    }
    return playing
  }

  const onClickPlayPause = () => {
    if (isPlaying()) {
      dispatch(pauseTrack())
    } else {
      dispatch(resumeTrack())
    }
  }

  const onclickPrev = () => {
    if (!isMyFavorites) {
      if (tracks && queue.playlistId !== null) {
        dispatch(skipPreviousTrack())
      }
    }
    if (isMyFavorites) {
      if (tracks && favoriteTracks !== null) {
        dispatch(skipPreviousTrack())
      }
    }
  }

  const onClickNext = () => {
    if (!isMyFavorites) {
      if (tracks && queue.playlistId !== null) {
        dispatch(skipNextTrack())
      }
    }
    if (isMyFavorites) {
      if (tracks && favoriteTracks !== null) {
        dispatch(skipNextTrack())
      }
    }
  }

  const onClickRepeat = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(toggleRepeat())
    }
  }

  const onClickRandom = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(toggleShuffle())
    }
  }

  const onClickMin = () => {
    dispatch(setVolume(0))
  }

  const onClickMax = () => {
    dispatch(setVolume(1))
  }

  const onChangeVolume = (e) => {
    dispatch(setVolume(e.target.value))
  }

  const onSeekBarChangeStarted = () => {
    dispatch(sendStats('seeked', queue.playingTrackId, queue.playlistId, player.currentTime))
  }

  const computeSeekedTime = (time) => {
    const computedSeekedTime = Math.floor(tracks[queue.trackIndex].duration * time)
    return computedSeekedTime
  }

  const onSeekBarChangeEnd = (endValue) => {
    if (endValue) {
      setSeekValue(null)
      const seekedTime = computeSeekedTime(endValue)
      dispatch(setTime(
        true,
        seekedTime,
      ))
    }
  }

  const trackImageSource = () => {
    let apiPath = ''
    try {
      apiPath = `albums/${(queue.playlistId !== -1) ? (tracks[queue.trackIndex].id_album) : (favoriteTracks[queue.trackIndex].id_album)}`
    } catch (error) {
      console.log(error)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=112&height=112&lang=${lang}&authorization=${user.authUser.accessToken}`
  }
  const playlistImageSource = () => {
    let apiPath = ''
    try {
      if (!queue.territoryId) {
        if (queue.playlistId !== -1) {
          apiPath = `${config.API_BASE_URL}/lists/${queue.playlistId}/cover.png?width=223&height=223&lang=${lang}&authorization=${user.authUser.accessToken}`
        } else {
          const path = `albums/${favoriteTracks[queue.trackIndex].id_album}`
          apiPath = `${config.API_BASE_URL}/${path}/cover.png?width=223&height=223&lang=${lang}&authorization=${user.authUser.accessToken}`
        }
        return apiPath
      }
      return `${config.API_BASE_URL}/assets/${queue.territoryId}/country.jpg`
    } catch (error) {
      console.log(error)
    }
  }

  const renderArtists = () => {
    let string = ''
    if (tracks.length > 0) {
      const numberOfArtists = tracks[queue.trackIndex].artists.length
      for (let i = 0; i < numberOfArtists; i++) {
        if (i === 0) {
          string = `${tracks[queue.trackIndex].artists[i].name} `
        } else {
          string = `${string}&${tracks[queue.trackIndex].artists[i].name}`
        }
      }
    }
    return string
  }

  const checkType = () => {
    if (queue.playlistId === FAVORITE_PLAYLIST_ID) {
      tracks = favoriteTracks
    } else if (queue.playlistId === TOPTRACK_PLAYLIST_ID && queue.territoryId) {
      tracks = topTracks[queue.territoryId]
    } else if (queue.playlistId > 0) {
      tracks = playlistTracks[queue.playlistId]
    }
  }

  const className = window.location.pathname.indexOf('onboarding') !== -1 ? 'onboarding_player' : ''

  return (
    <footer>
      {checkType()}
      {queue.playlistId && tracks.length > 0 && window.location.pathname !== '/audioplayer' && (
        <div className={`container__miniplayers ${className}`}>
          {queue.playlistId && (
          <div className="cover_content">
            <div className="cover_track_mobile">
              <img
                alt="trackimage"
                src={trackImageSource()}
                className="picture__track"
              />
            </div>
            <div className="cover_track">
              <img
                alt="trackimage"
                src={trackImageSource()}
                className="picture__track"
              />
            </div>
          </div>

          )}
          <div className="player">
            {queue.playlistId && tracks && tracks.length > 0 && (
            <div className="container_trackBar">
              <Seeker
                onChangeStart={(startValue) => onSeekBarChangeStarted(startValue)}
                onChangeEnd={(endValue) => onSeekBarChangeEnd(endValue)}
                onChange={(value) => {
                  setSeekValue(Math.floor(
                    tracks[queue.trackIndex].duration * value,
                  ))
                }}
                totaltime={tracks[queue.trackIndex].duration}
                seekValue={seekValue}
                currentTime={player.currentTime}
                styleBgBar={{ background: '#414141', borderRadius: 4, height: 4 }}
                styleBar={{ background: 'linear-gradient(270deg, #C8D900, #3CAD00)', borderRadius: 0, height: 4 }}
                styleHandle={window.innerWidth >= 767 ? { background: '#c8d900', height: 17, width: 17, marginTop: -6 } : { height: 0, width: 0 }}
              />
            </div>
            )}

            <div className="container__miniplayer__button">
              <div className="container__description__track">
                {queue.playlistId && queue.playlistId !== -1 && (
                <button type="button" onClick={() => onClickFavoriteButton()}>
                  <img src={isFavorite() ? '/assets/picto_heart_actif.png' : '/assets/picto_heart_inactif.svg'} alt={isFavorite() ? 'heart_actif' : 'heart_inactif'} />
                </button>
                )}
                {queue.playlistId && queue.playlistId === -1 && (
                <button type="button" onClick={() => onClickFavoriteButton()}>
                  <img src={isFavorite() ? '/assets/picto_heart_actif.png' : '/assets/picto_heart_inactif.svg'} alt={isFavorite() ? 'heart_actif' : 'heart_inactif'} />
                </button>
                )}
                <div className="description__track">
                  <p className="title">
                    {queue.playlistId
                        && queue.playlistId !== -1
                        && (tracks[queue.trackIndex].title)}

                    {queue.playlistId
                        && queue.playlistId !== -1
                        && (tracks[queue.trackIndex].explicit && (
                        <sup className="explicit">E</sup>
                        ))}
                  </p>
                  <p className="title">
                    {queue.playlistId
                        && favoriteTracks[queue.trackIndex]
                        && queue.playlistId === -1
                        && (favoriteTracks[queue.trackIndex].title)}
                    {queue.playlistId
                        && favoriteTracks[queue.trackIndex]
                        && queue.playlistId === -1
                        && (favoriteTracks[queue.trackIndex].isExplicit && (
                        <sup className="explicit">E</sup>
                        ))}
                  </p>
                  <p>{queue.playlistId && renderArtists()}</p>
                </div>
              </div>
              {/* button for player */}
              <div className="container__buttons">
                {queue && !queue.repeat && !queue.repeatOnce && (
                <button type="button" className="inactif" onClick={onClickRepeat}>
                  <img className="button_repeat" src="/assets/picto_repeat.svg" alt="repeat" />
                </button>
                )}
                {queue && !queue.repeat && queue.repeatOnce && (
                <button type="button" className={queue.repeatOnce ? 'inactif btn_repeatonce' : ''} onClick={onClickRepeat}>
                  <img src={queue.repeatOnce ? '/assets/pictoo_repeat_actif.svg' : '/assets/picto_repeat.svg'} alt="repeat" />
                  <p className={queue.repeatOnce ? 'active_repeatonce' : 'no_repeatonce'}>1</p>
                </button>
                )}
                {queue && queue.repeat && !queue.repeatOnce && (
                <button type="button" className="inactif" onClick={onClickRepeat}>
                  <img src={queue.repeat ? '/assets/pictoo_repeat_actif.svg' : '/assets/picto_repeat.svg'} alt="repeat" />
                </button>
                )}
                <button type="button" onClick={onclickPrev}>
                  <img src="/assets/picto_precedent.svg" alt="previous" />
                </button>
                <button type="button" onClick={onClickPlayPause}>
                  <img src={isPlaying() ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'} alt={isPlaying() ? 'break' : 'play'} />
                </button>
                <button type="button" onClick={onClickNext}>
                  <img src="/assets/picto_next_little.svg" alt="next button" />
                </button>
                <button type="button" className="inactif" onClick={onClickRandom}>
                  <img src={queue.shuffle ? '/assets/picto_shuf_actif.svg' : '/assets/picto_shuffle.svg'} alt="ramdom" />
                </button>
                <button type="button" className="inactif" alt="playlist">
                  {
                        queue.playlistId
                        && queue.playlistId === FAVORITE_PLAYLIST_ID
                        && (
                        <Link
                          className="see__the__playlist"
                          to={`${config.REACT_APP_PREFIX}/myfavorites`}
                        >
                          <img src="/assets/picto_playlist.svg" alt="playlist" />
                        </Link>
                        )
                      }
                  {
                        queue.playlistId
                        && queue.playlistId === TOPTRACK_PLAYLIST_ID
                        && queue.territoryId
                        && (
                        <Link className="see__the__playlist" to={`${config.REACT_APP_PREFIX}/toptracks/${queue.territoryId}`}>
                          <img src="/assets/picto_playlist.svg" alt="playlist" />
                        </Link>
                        )
                      }
                  {queue.playlistId && queue.playlistId > 0 && (
                  <Link className="see__the__playlist" to={`${config.REACT_APP_PREFIX}/playlist/${queue.playlistId}`}>
                    <img src="/assets/picto_playlist.svg" alt="playlist" />
                  </Link>
                  )}
                </button>
              </div>
              {/* volume and time */}
              <div className="container__miniplayer__vol">
                <div className="volume_content">
                  <button type="button" onClick={onClickMin} className="min_vol">
                    <img src="/assets/picto_son_inactif.svg" alt="vol-" />
                  </button>
                  <div className="container__range__vol inactif3">
                    <input type="range" value={player.currentVolume} onChange={onChangeVolume.bind(this)} min="0" max="1" step="0.05" />
                  </div>
                  <button type="button" onClick={onClickMax} className="max_vol">
                    <img src="/assets/picto_son.svg" alt="vol+" />
                  </button>
                </div>
                <div className="time">
                  {dayjs.utc(player.currentTime * 1000).format('mm:ss')}
                  {' / '}
                  {queue.playlistId && queue.playlistId !== -1 && (dayjs.utc(tracks[queue.trackIndex].duration * 1000).format('mm:ss'))}
                  {queue.playlistId && queue.playlistId === -1 && favoriteTracks[queue.trackIndex] && (dayjs.utc(favoriteTracks[queue.trackIndex].duration * 1000).format('mm:ss'))}
                </div>
              </div>
            </div>
          </div>
          {
                queue.playlistId
                && queue.playlistId === FAVORITE_PLAYLIST_ID
                && (
                <div className="superposition__picture__miniplayer">
                  <Link
                    className="see__the__playlist"
                    to={`${config.REACT_APP_PREFIX}/myfavorites`}
                  >
                    {t('seetheplaylist')}
                  </Link>
                  <img
                    alt="playlistimage"
                    src={playlistImageSource()}
                  />
                </div>
                )
              }
          {
                queue.playlistId
                && queue.playlistId === TOPTRACK_PLAYLIST_ID
                && queue.territoryId
                && (
                <div className="superposition__picture__miniplayer">
                  <figure className="picture__miniplayer">
                    <Link className="see__the__playlist" to={`${config.REACT_APP_PREFIX}/toptracks/${queue.territoryId}`}>
                      <span className="text">{t('seetheplaylist')}</span>
                      <img alt="playlistimage" src={playlistImageSource()} />
                    </Link>
                  </figure>
                </div>
                )
              }
          {
                queue.playlistId && queue.playlistId > 0 && (
                <div className="superposition__picture__miniplayer">
                  <figure className="picture__miniplayer">
                    <Link className="see__the__playlist" to={`${config.REACT_APP_PREFIX}/playlist/${queue.playlistId}`}>
                      <span className="text">{t('seetheplaylist')}</span>
                      <img alt="playlistimage" src={playlistImageSource()} />
                    </Link>
                  </figure>
                </div>
                )
              }
        </div>
      )}
    </footer>
  )
}

Player.defaultProps = {
  isMyFavorites: false,
}
Player.propTypes = {
  isMyFavorites: PropTypes.bool,
}

export default Player
