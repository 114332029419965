import {
  ASK_CODE_FREE_TRIAL_REQUEST, CHECK_INTERSTITIAL_REQUEST, CHECK_USER_FREE_TRIAL_REQUEST, CHECK_USER_REGISTRATION_FAILURE, CHECK_USER_REGISTRATION_REQUEST,
  CHECK_USER_REGISTRATION_SUCCESS, CHECK_USER_VOUCHER_REQUEST, CHECK_VOUCHER_REQUEST,
  CHECK_VOUCHER_SUCCESS, FORGETPWD_FAILED,
  FORGETPWD_SUCCESSFULLY, INTERSTITIAL_SUCCESS, LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_TOKEN_SUCCESS, RELOGIN_FAILURE, RELOGIN_FINISHED, RELOGIN_REQUEST, RESET_REGISTRATION_FAILURE,
  UPDATE_LOGIN, VOUCHER_SUCCESS,
} from '../actions'

const authUser = JSON.parse(localStorage.getItem('user'))
const initialState = authUser ? { loggedIn: true, authUser, relogin: false, reloginError: false } : { relogin: false, reloginError: false }

// eslint-disable-next-line default-param-last
export default function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        authUser: action.user,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        authUser: action.user,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        authUser: {
          error: action.error,
        },
      }
    case LOGOUT:
      localStorage.removeItem('user')
      return {}
    case REFRESH_TOKEN_SUCCESS: {
      return {
        loggedIn: true,
        authUser: JSON.parse(localStorage.getItem('user')),
      }
    }
    case RELOGIN_REQUEST:
      return {
        ...state,
        relogin: true,
        reloginError: false,
      }
    case RELOGIN_FINISHED:
      return {
        ...state,
        relogin: false,
        reloginError: false,
      }
    case RELOGIN_FAILURE:
      return {
        ...state,
        reloginError: true,
      }
    case CHECK_USER_REGISTRATION_REQUEST: {
      return {
        ...state,
        registration: {
          phoneNumber: action.payload.phoneNumber,
        },
      }
    }
    case FORGETPWD_FAILED: {
      return {
        ...state,
        error: action.error,
      }
    }
    case FORGETPWD_SUCCESSFULLY: {
      return {
        ...state,
        error: null,
      }
    }
    case CHECK_USER_REGISTRATION_SUCCESS: {
      return {
        ...state,
        registration: {
          phoneNumber: action.payload.phoneNumber,
          freeTrial: action.payload.response.go_to_free_trial,
          providerRegisterComplete: action.payload.response.provider_register_complete,
        },
      }
    }
    case CHECK_USER_REGISTRATION_FAILURE: {
      return {
        ...state,
        registration: {
          phoneNumber: action.error.phoneNumber,
          error: action.error.response.error,
        },
      }
    }
    case RESET_REGISTRATION_FAILURE: {
      return {
        ...state,
        registration: {},
      }
    }
    case CHECK_VOUCHER_REQUEST: {
      return {
        ...state,
        voucher: {
          phoneNumber: action.payload.phoneNumber,
          code: action.payload.code,
        },
      }
    }
    case CHECK_VOUCHER_SUCCESS: {
      return {
        ...state,
        voucher: {
          phoneNumber: action.payload.phoneNumber,
          code: action.payload.code,
        },
      }
    }
    case CHECK_USER_VOUCHER_REQUEST: {
      return {
        ...state,
        interstitial: {
          phoneNumber: action.payload.phoneNumber,
          code: action.payload.code,
          password: action.payload.password,
        },
      }
    }
    case VOUCHER_SUCCESS: {
      return {
        ...state,
        interstitial: {
          id: action.payload.user.idVoucherSet,
          accessToken: action.payload.user.accessToken,
          loggedIn: true,
          authUser: JSON.parse(localStorage.getItem('user')),
        },
      }
    }
    case ASK_CODE_FREE_TRIAL_REQUEST: {
      return {
        ...state,
      }
    }
    case CHECK_USER_FREE_TRIAL_REQUEST: {
      return {
        ...state,
      }
    }
    case CHECK_INTERSTITIAL_REQUEST: {
      return {
        ...state,
      }
    }
    case INTERSTITIAL_SUCCESS: {
      return {
        ...state,
        interstitialPic: true,
        loggedIn: true,
        authUser: JSON.parse(localStorage.getItem('user')),
      }
    }
    case UPDATE_LOGIN: {
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      }
    }
    default:
      return state
  }
}
