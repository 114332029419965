import React from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import 'react-multi-carousel/lib/styles.css'
import { useItemsToDispayHelper } from '../../lib/ItemsToDisplayHelper'
import configureStore from '../../store'
import { ButtonGroup } from '..'

const { store } = configureStore()

const SimilarPlaylists = (props) => {
  const {
    user,
  } = store.getState()
  const { t } = useTranslation('similarplaylist')
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { playlists } = props

  const slopeFactor = 188 // largeur des images dans le carousel
  const margin = 200

  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 2.3,
      pprojectialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2.7,
      pprojectialVisibilityGutter: 30,
    },
    tabletXL: {
      breakpoint: {
        max: 1440,
        min: 1024,
      },
      items: 3.5,
      partialVisibilityGutter: 30,
    },
    desktopSM: {
      breakpoint: {
        max: 3000,
        min: 1440,
      },
      items: useItemsToDispayHelper(slopeFactor, margin).integerToDisplay,
      partialVisibilityGutter: useItemsToDispayHelper(slopeFactor, margin).remainderToDisplay,
    },
  }

  const playlistImageSource = (playlistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const renderItem = (playlist) => (
    <div key={`item-${playlist.id}`} className="similar-playlist__item">
      <a href={`${config.REACT_APP_PREFIX}/playlist/${playlist.id}`}>
        <figure className="similar-playlist__item__picture-content">
          <img
            className="similar-playlist__item__picture-content__picture"
            src={playlistImageSource(playlist.id)}
            alt="slide"
          />
        </figure>
        <p className="similar-playlist__item__title">
          {playlist && playlist.metadata && playlist.metadata.title}
        </p>
      </a>
    </div>
  )

  return (
    <div className="similar-playlist">
      <p className="similar-playlist__title">{t('title')}</p>
      {playlists && (
        <Carousel
          arrows={false}
          keyBoardControl
          partialVisible
          responsive={responsive}
          slidesToSlide={1}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
        >
          {playlists && playlists.length > 0 && playlists.map((playlist) => (
            renderItem(playlist)
          ))}
        </Carousel>
      )}
    </div>
  )
}

SimilarPlaylists.propTypes = {
  playlists: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default SimilarPlaylists
