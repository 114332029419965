import {
  STORE_ARTICLES,
  STORE_ARTIST,
  STORE_PLAYLISTS_ARTIST,
} from '../actions'

const initialState = {
  articles: [],
  processing: false,
  artist: {},
  playlistsArtist: {},
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_ARTICLES:
      return {
        ...state,
        articles: payload.articles,
      }
    case STORE_ARTIST: {
      return {
        ...state,
        artist: payload.artist,
      }
    }
    case STORE_PLAYLISTS_ARTIST: {
      return {
        ...state,
        playlistsArtist: payload.playlistsArtist,
      }
    }
    default:
      return state
  }
}
