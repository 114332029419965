import {
  PLAY_TRACK,
  QUEUE_ADD_TRACKS,
  QUEUE_TOGGLE_SHUFFLE,
  QUEUE_TOGGLE_REPEAT,
  QUEUE_DISABLE_REPEAT,
  QUEUE_SKIP_NEXT_TRACK,
  QUEUE_ENDED,
  NO_QUEUE,
  LOGOUT,
} from '../actions'

/*
tracksIndexes[] : array with the indexes of tracks. The indexes will be shuffled if needed
queueIndex : index in tracksIndexes of the playing track
*/

const initialState = {
  playlistId: null,
  queueIndex: 0,
  playingTrackId: null,
  tracksIndexes: [],
  shuffle: false,
  repeat: false,
  repeatOnce: false,
  radio: null,
}

const unShuffledTracks = (numberOfTracks) =>
  Array.from(new Array(numberOfTracks), (val, index) => index)

const shuffledTracks = (numberOfTracks, queueIndex) => {
  const shuffledArray = unShuffledTracks(numberOfTracks)
    .map((a) => [Math.random(), a])
    .sort((a, b) => a[0] - b[0])
    .map((a) => a[1])
  const i = shuffledArray.findIndex((e) => e === queueIndex) || 0
  shuffledArray[i] = shuffledArray[0]
  shuffledArray[0] = queueIndex
  return shuffledArray
}

export default (state = initialState, action = {}) => {
  const { type, payload } = action
  switch (type) {
    case PLAY_TRACK: {
      return {
        ...state,
        radio: null,
        ...payload,
      }
    }
    case QUEUE_ADD_TRACKS: {
      return {
        ...state,
        radio: null,
        ...payload,
        tracksIndexes: unShuffledTracks(payload.numberOfTracks),
      }
    }
    case NO_QUEUE: {
      return {
        ...state,
        playlistId: null,
        playingTrackId: null,
      }
    }
    case QUEUE_SKIP_NEXT_TRACK: {
      return {
        ...state,
      }
    }
    case QUEUE_ENDED:
      return {
        ...state,
        playingTrackId: null,
      }
    case QUEUE_TOGGLE_SHUFFLE: {
      const { tracksIndexes, queueIndex } = state
      const { indexArray, index } = !state.shuffle
        ? { indexArray: shuffledTracks(tracksIndexes.length, queueIndex), index: 0 }
        : {
          indexArray: unShuffledTracks(tracksIndexes.length),
          index: tracksIndexes[queueIndex],
        }
      return {
        ...state,
        tracksIndexes: indexArray,
        queueIndex: index,
        shuffle: !state.shuffle,
      }
    }
    case QUEUE_TOGGLE_REPEAT: {
      // NO REPEAT => REPEAT ONCE => REPEAT
      let { repeat, repeatOnce } = state
      if (repeatOnce && !repeat) {
        repeat = true
        repeatOnce = false
      } else if (repeat && !repeatOnce) {
        repeat = false
        repeatOnce = false
      } else {
        repeatOnce = true
        repeat = false
      }
      return {
        ...state,
        repeat,
        repeatOnce,
      }
    }
    case QUEUE_DISABLE_REPEAT: {
      let { repeat, repeatOnce } = state
      repeatOnce = false
      repeat = false
      return {
        ...state,
        repeat,
        repeatOnce,
      }
    }

    case LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
