import ReactGA from 'react-ga'
import api from '../api'
import {
  ASK_CODE_FREE_TRIAL_REQUEST,
  CHECK_USER_FREE_TRIAL_REQUEST,
  CHECK_USER_REGISTRATION_REQUEST,
  CHECK_USER_REGISTRATION_SUCCESS,
  CHECK_USER_REGISTRATION_FAILURE,
  CHECK_VOUCHER_REQUEST,
  CHECK_VOUCHER_SUCCESS,
  CHECK_USER_VOUCHER_REQUEST,
  CHECK_INTERSTITIAL_REQUEST,
  INTERSTITIAL_SUCCESS,
  VOUCHER_SUCCESS,
  FORGETPWD_FAILED,
  FORGETPWD_SUCCESSFULLY,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_LOGIN,
  HIDE_ONBOARDING_VALUE,
  VOUCHER_DATA_POPIN_ATCONECTION,
} from './constants'
import { sendStats } from './stats'
import { updateHasOnBoarded } from './onboarding'
import { history } from '../lib'
import { fetchArticlesAndPlaylists } from './project'
import { refreshTokenSuccess } from './token'
import { updateOptinRgpdChoice, updateOptinCookieChoice } from './rgpd'
import { noQueue } from './queue'
import { pauseTrack } from './player'

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT })
  try {
    dispatch(pauseTrack())
  } catch (e) {
    console.log(e)
  }
  dispatch(noQueue())
}

export const autoLoginWithSubscriptionId = (subscriptionId) => {
  const request = (user) => ({ type: LOGIN_REQUEST, user })
  const success = (user) => ({ type: LOGIN_SUCCESS, user })
  const failure = (error) => ({ type: LOGIN_FAILURE, error })

  return async (dispatch, getState) => {
    (request())
    try {
      dispatch(logoutUser())
      localStorage.removeItem('user')
      const user = await api.autoLoginWithSubscriptionId(subscriptionId)
      if (user.accessToken && user.refreshToken) {
        console.log(`Login with autoLogin : User logged in with tokenDv ${user.accessToken} ########## (${user.refreshToken})`)
        const { REACT_APP_INFRA = 'dev' } = process.env
        localStorage.setItem('userId', user.id)
        localStorage.setItem('dimension1', 'subscriptionType-classical')
        if (REACT_APP_INFRA === 'prod') {
          ReactGA.set({ userId: user.id, dimension1: 'subscriptionType-classical' })
        }
        dispatch(success(user))
        await dispatch(updateHasOnBoarded())

        const { choiceRgpd, choiceCookie } = getState().rgpd
        if (choiceRgpd && choiceRgpd.idOptin === 0) {
          await dispatch(updateOptinRgpdChoice())
        }
        if (choiceCookie.length > 0) {
          await dispatch(updateOptinCookieChoice())
        }
      } else {
        dispatch(failure(user))
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const loginWithCredentials = (login, password) => {
  const request = (user) => ({ type: LOGIN_REQUEST, user })
  const success = (user) => ({ type: LOGIN_SUCCESS, user })
  const failure = (error) => ({ type: LOGIN_FAILURE, error })

  return async (dispatch, getState) => {
    (request())
    try {
      dispatch(logoutUser())
      localStorage.removeItem('user')
      const user = await api.loginWithCredentials(login, password)
      if (user.accessToken && user.refreshToken) {
        console.log(`Login with credentials : User logged in with token ${user.accessToken} ########## (${user.refreshToken})`)
        const { REACT_APP_INFRA = 'dev' } = process.env
        localStorage.setItem('userId', user.id)
        localStorage.setItem('dimension1', 'subscriptionType-classical')
        if (REACT_APP_INFRA === 'prod') {
          ReactGA.set({ userId: user.id, dimension1: 'subscriptionType-classical' })
        }
        dispatch(success(user))
        await dispatch(updateHasOnBoarded())
        const { choiceRgpd, choiceCookie } = getState().rgpd
        if (choiceRgpd && choiceRgpd.idOptin === 0) {
          await dispatch(updateOptinRgpdChoice())
        }
        if (choiceCookie.length > 0) {
          await dispatch(updateOptinCookieChoice())
        }
        const { onboarding: { start, hasOnboarded } } = getState()

        if (hasOnboarded) {
          history.push('/')
        }
        if (start === null && !hasOnboarded) {
          history.push('/onboardingwelcome')
        }
        if (start && !hasOnboarded) {
          history.push('/onboardinggender')
        }
      } else {
        dispatch(failure(user))
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const logout = (byRefreshTokenFailed) => async (dispatch, getState) => {
  if (byRefreshTokenFailed) {
    const {
      queue: { playlistId: PlaylistId, playingTrackId: trackId },
      player: { currentTime: CurrentTime },
    } = getState()
    await dispatch(sendStats('stopped', trackId, PlaylistId, CurrentTime))
  }

  dispatch(logoutUser())
  localStorage.removeItem('user')
  history.push('/phonenumber')
}

export const askCodeForFreeTrial = (phoneNumber) => {
  const request = (payload) => ({ type: ASK_CODE_FREE_TRIAL_REQUEST, payload })
  return async () => {
    (request(phoneNumber))
    try {
      await api.askCodeForFreeTrial(phoneNumber)
    } catch (e) {
      console.log(e)
    }
  }
}

export const checkUserRegistration = (phoneNumber) => {
  const request = (payload) => ({ type: CHECK_USER_REGISTRATION_REQUEST, payload })
  const success = (payload) => ({ type: CHECK_USER_REGISTRATION_SUCCESS, payload })
  const failure = (error) => ({ type: CHECK_USER_REGISTRATION_FAILURE, error })
  return async (dispatch) => {
    (request(phoneNumber))
    try {
      const response = await api.checkUserRegistration(phoneNumber)
      if (response) {
        dispatch(success({ response, phoneNumber }))
      } else {
        dispatch(failure({ response, phoneNumber }))
      }
      return response
    } catch (e) {
      throw e
    }
  }
}

export const voucher = (phoneNumber, code) => {
  const request = (payload) => ({ type: CHECK_VOUCHER_REQUEST, payload })
  const success = (payload) => ({ type: CHECK_VOUCHER_SUCCESS, payload })
  return async (dispatch, getState) => {
    request(phoneNumber, code)
    try {
      const user = await api.voucher(phoneNumber, code)
      const { project: { methods } } = getState()
      if (methods.indexOf('no_sms_voucher') !== -1) {
        if (user.accessToken && user.refreshToken) {
          console.log(`Login with credentials : User logged in with token ${user.accessToken} ########## (${user.refreshToken})`)
          const { REACT_APP_INFRA = 'dev' } = process.env
          localStorage.setItem('userId', user.id)
          localStorage.setItem('dimension1', 'subscriptionType-voucher')
          if (REACT_APP_INFRA === 'prod') {
            ReactGA.set({ userId: user.id, dimension1: 'subscriptionType-voucher' })
          }
          dispatch({ type: VOUCHER_DATA_POPIN_ATCONECTION, payload: code })
          dispatch({ type: LOGIN_SUCCESS, user })
          dispatch({ type: HIDE_ONBOARDING_VALUE, payload: { hideMyMusicProfil: true } })
          history.push('/')
        }
      } else {
        history.push('/vouchervalidation')
        dispatch({ type: HIDE_ONBOARDING_VALUE, payload: { hideMyMusicProfil: true } })
        dispatch(success({ phoneNumber, code }))
      }
    } catch (e) {
      throw e
    }
  }
}
export const gotoNextPageAfterLogin = (accessToken, refreshToken) => async (dispatch) => {
  await dispatch(refreshTokenSuccess(accessToken, refreshToken))
  await dispatch(fetchArticlesAndPlaylists())
  history.push('/')
}

export const checkVoucher = (phoneNumber, code, password) => {
  const request = (payload) => ({ type: CHECK_USER_VOUCHER_REQUEST, payload })
  const success = (payload) => ({ type: VOUCHER_SUCCESS, payload })
  return async (dispatch, getState) => {
    (request(phoneNumber, code, password))
    try {
      const user = await api.checkVoucher(phoneNumber, code, password)
      if (user.accessToken && user.refreshToken) {
        dispatch({ type: LOGIN_SUCCESS, user })
        const { choiceRgpd, choiceCookie } = getState().rgpd
        if (choiceRgpd && choiceRgpd.idOptin === 0) {
          await dispatch(updateOptinRgpdChoice())
        }
        if (choiceCookie.length > 0) {
          await dispatch(updateOptinCookieChoice())
        }
      }
      if (user.idVoucherSet) {
        dispatch(success({ user, phoneNumber, code, password }))
      }
    } catch (e) {
      throw e
    }
  }
}

export const resetPassword = (phoneNumber) => {
  const request = (payload) => ({ type: CHECK_USER_REGISTRATION_REQUEST, payload })
  const success = (payload) => ({ type: FORGETPWD_SUCCESSFULLY, payload })
  const failure = (error) => ({ type: FORGETPWD_FAILED, error })
  return async (dispatch) => {
    (request(phoneNumber))
    try {
      const response = await api.resetPassword(phoneNumber)
      if (response && response.code) {
        dispatch(success({ response, phoneNumber }))
        history.push('/identification')
      } else {
        dispatch(failure('error'))
      }
    } catch (e) {
      console.log(e)
    }
  }
}

export const verifyInterstitialPic = (setId, accessToken) => {
  const request = (payload) => ({ type: CHECK_INTERSTITIAL_REQUEST, payload })
  const success = (payload) => ({ type: INTERSTITIAL_SUCCESS, payload })
  return async (dispatch) => {
    request(setId, accessToken)
    try {
      const response = await api.verifyInterstitialPic(setId, accessToken)
      if (response) {
        dispatch(success({ response, accessToken }))
      }
    } catch (e) {
      throw e
    }
  }
}

export const checkUserFreeTrial = (phoneNumber, password) => {
  const request = (payload) => ({ type: CHECK_USER_FREE_TRIAL_REQUEST, payload })
  const success = (user) => ({ type: LOGIN_SUCCESS, user })
  return async (dispatch) => {
    (request(phoneNumber))
    const response = await api.checkUserFreeTrial(phoneNumber, password)
    if (response.accessToken && response.refreshToken) {
      dispatch({ type: HIDE_ONBOARDING_VALUE, payload: { hideMyMusicProfil: true } })
      dispatch(success(response))
      history.push('/')
    }
  }
}

export const updateLogin = (phoneNumber) => ({
  type: UPDATE_LOGIN,
  payload: {
    phoneNumber,
  },

})

export const loginWithUserToken = (unparsedUser) => {
  const request = (user) => ({ type: LOGIN_REQUEST, user })
  const success = (user) => ({ type: LOGIN_SUCCESS, user })
  const failure = (error) => ({ type: LOGIN_FAILURE, error })

  return async (dispatch, getState) => {
    (request())
    try {
      dispatch(logoutUser())
      localStorage.removeItem('user')
      const user = {
        accessToken: unparsedUser.access_token,
        refreshToken: unparsedUser.refresh_token,
      }
      if (user.accessToken && user.refreshToken) {
        console.log(`Login with query token : User logged in with token ${user.accessToken} ########## (${user.refreshToken})`)
        const { REACT_APP_INFRA = 'dev' } = process.env
        localStorage.setItem('userId', user.id)
        if (REACT_APP_INFRA === 'prod') {
          ReactGA.set({ userId: user.id })
        }
        dispatch(success(user))
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('refreshToken', user.refreshToken)
        await dispatch(updateHasOnBoarded())
        const { choiceRgpd, choiceCookie } = getState().rgpd
        if (choiceRgpd && choiceRgpd.idOptin === 0) {
          await dispatch(updateOptinRgpdChoice())
        }
        if (choiceCookie.length > 0) {
          await dispatch(updateOptinCookieChoice())
        }
        const { onboarding: { start, hasOnboarded } } = getState()
        if (hasOnboarded) {
          history.push('/')
        }
        if (start === null && !hasOnboarded) {
          history.push('/onboardingwelcome')
        }
        if (start && !hasOnboarded) {
          history.push('/onboardinggender')
        }
      } else {
        dispatch(failure(user))
      }
    } catch (e) {
      console.log(e)
    }
  }
}
