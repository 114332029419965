import { REHYDRATE } from 'redux-persist'
import {
  UPDATE_RGPD_CHOICE,
  UPDATE_COOKIE_CHOICE,
} from '../actions'

const initialState = {
  checkRgpdValue: false,
  choiceRgpd: {},
  choiceCookie: [],
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case REHYDRATE: {
      const incoming = (payload && payload.rgpd) || {}
      return {
        ...state,
        ...incoming,
        rehydrated: true,
      }
    }
    case UPDATE_RGPD_CHOICE: {
      const { idOptin, value } = payload
      return {
        ...state,
        checkRgpdValue: true,
        choiceRgpd:
          { idOptin, value },
      }
    }
    case UPDATE_COOKIE_CHOICE: {
      const { choiceCookie } = state
      const { idOptin, value } = payload
      if (choiceCookie.length > 0) {
        choiceCookie.forEach((c, index) => {
          const choiceInside = c
          if (idOptin === choiceInside.idOptin) {
            choiceCookie.splice(index, 1)
          }
        })
      }
      return {
        ...state,
        checkRgpdValue: true,
        choiceCookie: [
          ...state.choiceCookie,
          { idOptin, value },

        ],
      }
    }
    default:
      return state
  }
}
