import React from 'react'
import { useDispatch } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { skipOnBoarding } from '../../actions'

const OnBoardingSkipButton = () => {
  const { t } = useTranslation('onboarding')
  const dispatch = useDispatch()
  const onClickCallToAction = (async () => {
    try {
      await dispatch(skipOnBoarding())
    } catch (e) {
      console.log(e)
    }
  })

  return (
    <button
      type="button"
      className="btn-skip"
      onClick={() => { onClickCallToAction() }}
    >
      {t('btnskip')}
    </button>
  )
}

export default OnBoardingSkipButton
