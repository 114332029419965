import api from '../api'
import {
  PROCESSING_TOP_TRACKS,
  PROCESSED_TOP_TRACKS,
  STORE_TOP_TRACKS,
} from './constants'

const processingTopTracks = () => ({
  type: PROCESSING_TOP_TRACKS,
})

const processedTopTracks = () => ({
  type: PROCESSED_TOP_TRACKS,
})

const storeTopTracks = (payload) => async (dispatch) => {
  const { tracks } = payload
  dispatch({
    type: STORE_TOP_TRACKS,
    payload: {
      ...payload,
      tracks,
    },
  })
}

export const fetchTopTracks = (territoryId) => async (dispatch) => {
  dispatch(processingTopTracks())
  const topTracks = await api.fetchTopTracks(territoryId)
  const tracks = topTracks.items
  await dispatch(storeTopTracks({
    territoryId,
    tracks,
  }))
  dispatch(processedTopTracks())
}
