import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { getUrlDeeplink } from '../../lib/helpers'
import './styles/index.scss'

const ModalQRCode = ({ isShowing, hide }) => {
  const { t } = useTranslation('modalQRCode')
  const ref = useDetectClickOutside({ onTriggered: () => {
    if (isShowing) {
      hide()
    }
  } })

  if (isShowing) {
    return (
      <div className="modalQRCode-bg">
        <div ref={ref} className="modalQRCode">
          <img alt="logo" src="/assets/images/logo_digster.png" className="logo" />
          <h1>{t('modalDesc')}</h1>
          <div className="modal-qrCode">
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={getUrlDeeplink()}
            />
          </div>
          <p>{t('modalBottom')}</p>
          <button type="button" onClick={hide} className="closebtn">
            <img alt="close" src="/assets/croix.png" />
          </button>
        </div>
      </div>
    )
  }
  return (null)
}

ModalQRCode.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
}
export default ModalQRCode
