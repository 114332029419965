import React, { useState, useEffect, useRef } from 'react'
import './styles/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import 'react-phone-number-input/style.css'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { voucher, updateLogin, getUnitedProjects, getLoginMethods } from '../../actions'
import { Rgpd } from '../../components'
import config from '../../config'
import { gaSender, getProjecIdByProjectName } from '../../lib'

const Voucher = () => {
  const [state, setState] = useState({
    isAlertActive: false,
  })
  const [error, setError] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [campaignCode, setCampaignCode] = useState('')
  const [voucherCode, setVoucherCode] = useState('')
  const [mustAutoSubmit, setMustAutoSubmit] = useState(true)
  const [rgpdDisplay, setRgpdDisplay] = useState(false)
  const { t: tError } = useTranslation('error')
  const dispatch = useDispatch()
  const { t } = useTranslation('voucher')
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const projects = useSelector((reducerState) => reducerState.project.projects)
  const phone = useSelector((reducerState) => reducerState.user.phoneNumber)
  const checkRgpdValue = useSelector((reducerState) => reducerState.rgpd.checkRgpdValue)
  const voucherCodeInput = useRef(null)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const { territory } = useParams()

  const query = useQuery()
  const queryPhoneNumber = query.get('phone_number')
  const queryVoucherCode = query.get('voucher_code')

  useEffect(() => {
    if (!projects || projects.length === 0 || !Array.isArray(projects)) {
      dispatch(getUnitedProjects())
    }
    if ((!PROJECT_ID || PROJECT_ID === String(undefined)) && projects && queryVoucherCode) {
      const projectCode = queryVoucherCode.substring(0, 2)
      localStorage.setItem('PROJECT_ID', getProjecIdByProjectName(projectCode, projects))
    }
    if (localStorage.getItem('PROJECT_ID') && localStorage.getItem('PROJECT_ID') !== String(undefined)) {
      dispatch(getLoginMethods(localStorage.getItem('PROJECT_ID')))
    }
  }, [PROJECT_ID, dispatch, projects, queryVoucherCode])

  useEffect(() => {
    if (phone) {
      setPhoneNumber(phone)
    } else if (queryPhoneNumber) {
      setPhoneNumber(queryPhoneNumber)
    } else {
      setPhoneNumber(phoneNumber)
    }
    if (queryVoucherCode) {
      setCampaignCode(queryVoucherCode.substring(3, 5))
      setVoucherCode(queryVoucherCode.substring(6, 30))
    } else {
      setCampaignCode(campaignCode)
      setVoucherCode(voucherCode)
    }
    if (campaignCode.length === 2) {
      voucherCodeInput.current.focus()
    }
    if (phone) {
      setPhoneNumber(phone)
    }
  }, [dispatch, phone, phoneNumber, campaignCode, queryPhoneNumber, queryVoucherCode, voucherCode])

  useEffect(() => {
    gaSender('page_authentification_voucher')
  }, [])

  useEffect(() => {
    if (territory) {
      dispatch(getUnitedProjects())
    }
  }, [dispatch, territory])

  let project = {}
  if (territory) {
    if (Array.isArray(projects)) {
      project = projects.find((projectObj) => String(projectObj.territory[0].code) === territory)
    } else {
      project = Object.values(projects).find((projectObj) => String(projectObj.territory[0].code) === territory)
    }
    if (project) {
      localStorage.setItem('PROJECT_ID', project.id)
    }
  } else if (Array.isArray(projects)) {
    project = projects.find((projectObj) => String(projectObj.id) === PROJECT_ID)
  } else {
    project = projects[PROJECT_ID]
  }

  useEffect(() => {
    if (!checkRgpdValue) {
      setMustAutoSubmit(false)
      setRgpdDisplay(true)
    }
  }, [checkRgpdValue])

  let phonePrefix = null
  let territoryCode = null
  if (project && project.territory) {
    territoryCode = project.territory[0].code.toUpperCase()
    phonePrefix = project.phone_prefix
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = async () => {
    if (!phoneNumber || !campaignCode || !voucherCode) {
      setState((prevState) => ({
        ...prevState,
        isAlertActive: true,
      }))
    }
    const code = `${territoryCode}-${campaignCode}-${voucherCode}`
    if (phoneNumber.indexOf('+33') !== -1) {
      phonePrefix = ''
    }
    try {
      await dispatch(voucher(phoneNumber, code))
    } catch (e) {
      setError(e.message)
    }
  }

  const goBack = (login) => {
    dispatch(updateLogin(login))
  }

  const rgpd = () => {
    if (!checkRgpdValue) {
      setRgpdDisplay(true)
    }
  }

  const rgpdClose = () => {
    setRgpdDisplay(false)
  }

  useEffect(() => {
    if (queryPhoneNumber && queryVoucherCode && mustAutoSubmit && campaignCode && voucherCode && territoryCode && phoneNumber) {
      setMustAutoSubmit(false)
      setTimeout(() => handleSubmit(), 500)
    }
  }, [campaignCode, handleSubmit, mustAutoSubmit, phoneNumber, queryPhoneNumber, queryVoucherCode, rgpdDisplay, territoryCode, voucherCode])

  const { isAlertActive } = state
  return (
    <div>
      {rgpdDisplay && (
        <Rgpd location={{ state: { scene: 'askphonenumber' } }} rgpdCloseHandler={rgpdClose} />
      )}
      <div className="container-voucher">
        <img className="imagelogo" width={160} src={`${config.REACT_APP_PREFIX}/logo_digster768.svg`} alt="logo" />
        <Link
          className="link_return"
          to="/phonenumber"
          onClick={() => goBack(phoneNumber)}
        >
          <img src="/assets/fleche_retour.png" alt="return" />
        </Link>
        <h1>{t('titlevoucher')}</h1>
        <p>{t('vouchertext')}</p>
        <div className="container__input">
          <div className="first-row">
            <span className="prefix">
              {phonePrefix}
            </span>
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="input"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={checkRgpdValue}
              onFocus={() => rgpd()}
            />
          </div>
          <div className="code">
            <input
              type="text"
              value={territoryCode}
              className="territory"
            />
            <span>-</span>
            <input
              type="number"
              value={campaignCode}
              onChange={(e) => setCampaignCode(e.target.value)}
              className="campaign"
            />
            <span>-</span>
            <input
              type="text"
              ref={voucherCodeInput}
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              className="voucher"
            />
          </div>
        </div>
        {error && (<p className="error">{tError(error)}</p>)}
        {isAlertActive && (<p className="error">{t('error')}</p>)}
        <div className="container__button">
          <button className="button-voucher" type="button" onClick={() => handleSubmit()}>{t('calltoaction')}</button>
        </div>
      </div>
    </div>
  )
}
export default Voucher
