import React from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import configureStore from '../../store'
import { ButtonGroup } from '..'
import { useItemsToDispayHelper } from '../../lib/ItemsToDisplayHelper'
import { gaSender } from '../../lib'

const { store } = configureStore()

const ArticlesCarousel = (props) => {
  const { user } = store.getState()
  const { t } = useTranslation('articlecarousel')
  const { articles: arts } = props
  const coverWidth = 528
  const slopeFactor = 528 // wanted width to our pictures
  const margin = 150

  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 75,
    },
    mobileXL: {
      breakpoint: {
        max: 767,
        min: 465,
      },
      items: 1,
      partialVisibilityGutter: 200,
    },
    tablet: {
      breakpoint: {
        max: 1023,
        min: 768,
      },
      items: 1,
      partialVisibilityGutter: 225,
    },
    desktopSM: {
      breakpoint: {
        max: 1439,
        min: 1024,
      },
      items: 1,
      partialVisibilityGutter:
        (coverWidth *
          useItemsToDispayHelper(slopeFactor, margin).remainderToDisplay) /
          100 +
        200,
    },
    desktopM: {
      breakpoint: {
        max: 4000,
        min: 1440,
      },
      items: useItemsToDispayHelper(slopeFactor, margin).integerToDisplay + 1,
      partialVisibilityGutter: useItemsToDispayHelper(slopeFactor, margin)
        .remainderToDisplay,
    },
  }

  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang

  const articleImageSource = (article) => {
    let apiPath = ''
    try {
      apiPath = `articles/${article.id}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover-v5.png?width=1000&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const getListIdFromRelatedContent = (relatedContent) => {
    if (relatedContent) {
      return parseInt(relatedContent.split(':')[1], 10)
    }
    return null
  }

  const getListTypeFromRelatedContent = (relatedContent) => {
    if (relatedContent) {
      return relatedContent.split(':')[2]
    }
    return null
  }

  const getArticleRef = (art) => {
    if (
      getListTypeFromRelatedContent(art.related_content_url) === 'artist-page'
    ) {
      return `${
        config.REACT_APP_PREFIX
      }/artistpage/${getListIdFromRelatedContent(art.related_content_url)}`
    }
    return `${config.REACT_APP_PREFIX}/playlist/${getListIdFromRelatedContent(
      art.related_content_url,
    )}`
  }

  return (
    <div className="container__big-titles slider_bloc">
      <div className="slider-content">
        <div className="moods-titles-extra slider-title">
          <h2 className="title title-extra-moods no-title-on-carousel">
            <Link to="/explore" className="link__title">
              <span>{t('title')}</span>
              <img src="/assets/picto_fleche_go.svg" alt="See all" />
            </Link>
          </h2>
        </div>
        <div className="slider-body">
          <Carousel
            className="mt-mobile carousel"
            infinite
            autoPlay={false}
            keyBoardControl
            partialVisible={true}
            responsive={responsive}
            slidesToSlide={1}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
          >
            {Object.values(arts).map((art) => (
              <div className="slider_item">
                <div
                  key={`item-${art.id}`}
                  className="text-white container-article"
                >
                  <figure className="imgCover">
                    <Link
                      key={`ref-${art.id}`}
                      to={getArticleRef(art)}
                      onClick={() => {
                        gaSender(
                          'action_home_screen_click_banner',
                          getListIdFromRelatedContent(art.related_content_url),
                        )
                      }}
                    >
                      <img
                        src={articleImageSource(art)}
                        alt="slide"
                        className="big-pictures"
                      />
                    </Link>
                  </figure>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

ArticlesCarousel.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default ArticlesCarousel
