import React, { useState, useCallback } from 'react'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import 'react-phone-number-input/style.css'

import { useTranslation } from 'react-i18next'
import { Spinner, Rgpd } from '../../components'
import { checkUserRegistration, fetchProject, updateLogin, askCodeForFreeTrial, fetchProjectLinks } from '../../actions'
import { RESET_REGISTRATION_FAILURE, REMOVE_PROJECTS } from '../../actions/constants'
import { history, gaSender } from '../../lib'
import config from '../../config'

const AskPhoneNumber = () => {
  const { search } = window.location
  const query = new URLSearchParams(search)

  const [loginUser, setLoginUser] = useState('')
  const [loginFromAccessPortal] = useState(query.get('login') ? query.get('login') : null)
  const [pending, setPending] = useState(false)
  const [state, setState] = useState({
    isAlertActive: false,
  })
  const [rgpdDisplay, setRgpdDisplay] = useState(false)
  const [noPhoneNumber, setNoPhoneNumber] = useState(false)
  const [noLogin, setNoLogin] = useState(false)

  const phoneFromVoucher = useSelector((reducerState) => reducerState.user.phoneNumber)
  const { t: tError } = useTranslation('error')

  const checkRgpdValue = useSelector((reducerState) => reducerState.rgpd.checkRgpdValue)
  const serverSideError = useSelector((reducerState) => {
    const registration = reducerState.user.registration || {}
    return registration.error
  })
  const dispatch = useDispatch()

  const { t } = useTranslation('askphonenumber')
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const projects = useSelector((reducerState) => reducerState.project.projects)
  const processing = useSelector((reducerState) => reducerState.project.processing)
  const methods = useSelector((reducerState) => reducerState.project.methods)
  const links = useSelector((reducerState) => reducerState.project.links)
  const locale = useSelector((reducerState) => reducerState.config.locale)
  const project = projects[PROJECT_ID]

  let phonePrefix = null
  if (project) {
    phonePrefix = project.phone_prefix
  }

  const goBack = () => {
    dispatch({ type: RESET_REGISTRATION_FAILURE })
    dispatch({ type: REMOVE_PROJECTS })
  }

  const {
    isAlertActive,
    showVoucherLink,
    notRegistered,
    notFreeTrial,
    showRegisteredLink,
    showRegisteredBcdLink,
    freeTrialOnly,
  } = state

  const hasFreeTrialMethod = useCallback(async () => {
    if ((methods.indexOf('free_trial') !== -1 && methods.indexOf('login_dv') === -1
        && methods.indexOf('login_jmt') === -1 && methods.indexOf('discovery_mode') === -1
        && methods.indexOf('login_bcd') === -1)
    ) {
      setState((prevState) => ({
        ...prevState,
        freeTrialOnly: true,
      }))
    }
  }, [methods])

  const checkMethod = useCallback(async () => {
    if (methods) {
      if (methods.indexOf('voucher') !== -1 || methods.indexOf('no_sms_voucher') !== -1) {
        setState((prevState) => ({
          ...prevState,
          showVoucherLink: true,
        }))
      }
      if (methods.indexOf('login_dv') !== -1 && methods.indexOf('login_bcd') === -1) {
        setState((prevState) => ({
          ...prevState,
          showRegisteredLink: true,
        }))
      }
      if (methods.indexOf('login_bcd') !== -1) {
        setState((prevState) => ({
          ...prevState,
          showRegisteredBcdLink: true,
          showRegisteredLink: true,
        }))
      }
      hasFreeTrialMethod()
    }
  }, [methods, hasFreeTrialMethod])

  const goToVoucherPage = (login) => {
    dispatch(updateLogin(login))
    history.push('/voucher')
  }

  const handleSubmit = (async () => {
    setNoLogin(false)
    if (loginUser) {
      setState(() => ({
        isAlertActive: false,
      }))
      let login = ''
      login = loginUser.replace(/ /g, '')

      try {
        setPending(true)
        const response = await dispatch(checkUserRegistration(login))
        if (!response.user_registered && !response.go_to_free_trial) {
          setState(() => ({
            notRegistered: true,
          }))
          setPending(false)
        }
        if (freeTrialOnly && !response.go_to_free_trial) {
          setState(() => ({
            notFreeTrial: true,
          }))
        }
        if (response.user_registered && !freeTrialOnly) {
          history.push(`/identification?phoneNumber=${login}`)
        } else if (response.go_to_free_trial) {
          try {
            await dispatch(askCodeForFreeTrial(login))
          } catch (e) {
            console.log(e)
          }
          history.push('/freetrial')
        }
        checkMethod()
      } catch (e) {
        if (e.message === 'E-USER-CREDENTIALS-INVALID') {
          checkMethod()
          setPending(false)
          setState(() => ({
            isAlertActive: true,
          }))
        }
      }
    } else if (showRegisteredBcdLink) {
      setNoLogin(true)
    } else {
      setNoPhoneNumber(true)
    }
  })

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  const rgpd = () => {
    if (!checkRgpdValue) {
      setRgpdDisplay(true)
    }
  }

  const rgpdClose = () => {
    setRgpdDisplay(false)
  }

  React.useEffect(() => {
    gaSender('page_authentification_screen')
    localStorage.removeItem('user')
    checkMethod()
    dispatch(fetchProject())
    if (phoneFromVoucher) {
      setLoginUser(phoneFromVoucher)
    } else if (loginFromAccessPortal) {
      setLoginUser(loginFromAccessPortal)
    }
    if (!links) {
      dispatch(fetchProjectLinks(PROJECT_ID))
    }
  }, [
    dispatch,
    phoneFromVoucher,
    PROJECT_ID,
    checkMethod,
    links,
    loginFromAccessPortal,
  ])

  return (
    <div className="container__phoneNumber">
      {pending && (
        <Spinner message={t('connection')} />
      )}
      {!pending && (
      <div className="main_content">
        {rgpdDisplay && (
          <Rgpd location={{ state: { scene: 'askphonenumber' } }} rgpdCloseHandler={rgpdClose} />
        )}
        <div className="container_changeLanguage">
          <Link className="link_change_language" to="/changeLanguage">{t('changeLanguage')}</Link>
        </div>
        <div className="content__phoneNumber">
          <img className="imagelogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
          <div className="container__askPhoneNumber">
            <Link className="link_return" to="/selectcountry" onClick={goBack}>
              <img src="/assets/fleche_retour.png" alt="return" />
            </Link>
            <div className="title_content">
              <h1>{t('titleaskphonenumber')}</h1>
              {showRegisteredBcdLink ? (
                <p>{t('enterphonenumber')}</p>
              ) : (
                <p>{t('enterphonenumberDv')}</p>
              )}
            </div>
            {!processing && (
            <div className="container__input">
              {showRegisteredBcdLink ? (
                <div className="container__input__bcd">
                  {notRegistered && showRegisteredBcdLink && (<p className="error__registered">{tError('E-NO-REGISTERED-BCD')}</p>)}
                  {noLogin && (<p className="error__registered">{t('nologin')}</p>)}
                  <input
                    className="input"
                    readOnly={!checkRgpdValue}
                    value={loginUser}
                    type="number"
                    placeholder={t('placeholder')}
                    onChange={(e) => setLoginUser(e.target.value)}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onFocus={() => rgpd()}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={checkRgpdValue}
                  />
                  {links?.forgotLogin && locale && (
                    <Link to={links.forgotLogin[locale]} className="forgotLogin">
                      <p>{t('linkForgotLogin')}</p>
                    </Link>
                  )}
                </div>

              ) : (
                <div className="container__input__dv">
                  <span className="prefix">
                    {phonePrefix}
                  </span>
                  <input
                    className="input"
                    type="number"
                    readOnly={!checkRgpdValue}
                    value={loginUser}
                    placeholder={t('placeholderDv')}
                    onChange={(e) => setLoginUser(e.target.value)}
                    onKeyPress={(event) => handleKeyPress(event)}
                    onFocus={() => rgpd()}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={checkRgpdValue}
                  />
                </div>
              )}
            </div>
            )}
            {showRegisteredLink && project && PROJECT_ID && (
              <div className="container__link">
                <Link className="link__informations" to={`/subscribe?territory=${project.territory[0].code}`}>{t('loginnew')}</Link>
              </div>
            )}
            {serverSideError && (<p className="wrong">{t(serverSideError)}</p>)}
            {isAlertActive && (<p className="wrong">{t('alert')}</p>)}
            {notRegistered && !showRegisteredBcdLink && (<p className="wrong">{tError('E-NO-REGISTERED-DV')}</p>)}
            {notFreeTrial && (<p className="wrong">{tError('FREE_TRIAL_EXPIRED')}</p>)}
            {noPhoneNumber && (<p className="wrong">{t('nophonenumber')}</p>)}
            <div className="container__button">
              <button className="button__askphone" type="button" onClick={() => handleSubmit()}>{t('calltoaction')}</button>
            </div>
            {showVoucherLink && (
              <Link className="link__code" type="button" onClick={() => goToVoucherPage(loginUser)}>{t('voucher')}</Link>
            )}
          </div>
        </div>
      </div>
      )}
    </div>
  )
}

export default AskPhoneNumber
