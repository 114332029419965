import api from '../api'
import {
  STORE_EXPLORE_GENRES,
  STORE_EXPLORE_MOODS,
  STORE_EXPLORE_DECADES,
  STORE_EXPLORE_ACTIVE_FILTER,
  STORE_EXPLORE_PLAYLISTS,
  STORE_EXPLORE_PLAYLISTS_PROCESSING,
  STORE_EXPLORE_PLAYLISTS_PROCESSED,
} from './constants'
import { getLanguage } from './config'

export const fetchGenres = () => async (dispatch, getState) => {
  try {
    const { CATEGORIES } = getState().config
    const response = await api.fetchCategories(CATEGORIES.genre)
    const { items } = response
    dispatch({
      type: STORE_EXPLORE_GENRES,
      payload: {
        genres: items,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const fetchDecades = () => async (dispatch, getState) => {
  try {
    const { CATEGORIES } = getState().config
    const response = await api.fetchCategories(CATEGORIES.decade)
    const { items } = response
    dispatch({
      type: STORE_EXPLORE_DECADES,
      payload: {
        decades: items,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const fetchMoods = () => async (dispatch, getState) => {
  try {
    const { CATEGORIES } = getState().config
    const response = await api.fetchCategories(CATEGORIES.mood)
    const { items } = response
    dispatch({
      type: STORE_EXPLORE_MOODS,
      payload: {
        moods: items,
      },
    })
  } catch (e) {
    console.log(e)
  }
}

export const fetchUserFavoritePlaylistsForExplorer = (offset) => async (dispatch, getState) => {
  const limit = 20
  let hasMore = false

  try {
    dispatch({ type: STORE_EXPLORE_PLAYLISTS_PROCESSING })
    const { explorer: { activeFilters }, onboarding: { favoriteGenre, favoriteMood } } = getState()
    let { explorer: { playlists, order } } = getState()

    const favoriteFilters = favoriteGenre.concat(favoriteMood)

    if (offset === 0) {
      playlists = []
      order = []
    }

    let listsForExplorer = []

    dispatch(getLanguage())

    if (activeFilters.length > 0) {
      const playlistsFromCategories = await api.fetchPlaylistsFavoriteUser(
        activeFilters,
        offset,
        limit,
      )
      if (playlistsFromCategories && playlistsFromCategories.items) {
        listsForExplorer = playlistsFromCategories.items
      }
    } else if (favoriteGenre.length > 0) {
      const playlistsFromUserFavorite = await api.fetchPlaylistsFavoriteUser(
        favoriteFilters,
        offset,
        limit,
      )
      if (playlistsFromUserFavorite && playlistsFromUserFavorite.items) {
        listsForExplorer = playlistsFromUserFavorite.items
      }
    } else if (favoriteGenre.length === 0 && favoriteMood.length === 0) {
      const playlistWithoutCategories = await api.fetchPlaylistsFavoriteUser(
        null,
        offset,
        limit,
      )
      if (playlistWithoutCategories && playlistWithoutCategories.items) {
        listsForExplorer = playlistWithoutCategories.items
      }
    }

    hasMore = listsForExplorer.length === limit
    playlists = playlists.concat(listsForExplorer)

    const allPlaylists = listsForExplorer.filter((playlist) => playlist.nb_items > 0)
    allPlaylists.forEach((playlist) => {
      order.push(playlist.id)
    })

    allPlaylists.forEach((playlist) => {
      playlists[playlist.id] = {
        ...playlist,
      }
    })

    dispatch({
      type: STORE_EXPLORE_PLAYLISTS,
      payload: {
        playlists,
        order,
        hasMore,
      },
    })
  } catch (e) {
    console.log(e)
  } finally {
    dispatch({ type: STORE_EXPLORE_PLAYLISTS_PROCESSED })
  }
}

export const updateActiveFilter = (activeFilters) => async (dispatch) => {
  const offset = 0
  dispatch({
    type: STORE_EXPLORE_ACTIVE_FILTER,
    payload: {
      activeFilters,
    },
  })
  dispatch(fetchUserFavoritePlaylistsForExplorer(offset))
}
