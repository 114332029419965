import {
  STORE_CONFIG,
} from '../actions'
import config from '../config'

const initialState = {
  ...config,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_CONFIG:
      return {
        ...state,
        locale: payload.locale,
      }
    default:
      return state
  }
}
