import {
  PROCESSING_TOP_TRACKS,
  PROCESSED_TOP_TRACKS,
  STORE_TOP_TRACKS,
} from '../actions'

const initialState = {
  topTracks: [],
  processing: false,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_TOP_TRACKS: {
      const { territoryId } = payload

      return {
        ...state,
        topTracks: {
          ...state.topTracks,
          [territoryId]: [...payload.tracks],
        },
      }
    }
    case PROCESSING_TOP_TRACKS:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_TOP_TRACKS:
      return {
        ...state,
        processing: false,
      }
    default:
      return state
  }
}
