import React from 'react'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import 'react-multi-carousel/lib/styles.css'
import configureStore from '../../store'
import { ButtonGroup } from '..'
import { useItemsToDispayHelper } from '../../lib/ItemsToDisplayHelper'
import { gaSender } from '../../lib'

const { store } = configureStore()

const OnlyForYou = (props) => {
  const {
    user,
  } = store.getState()
  const { t } = useTranslation('onlyforyou')
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { playlists } = props

  const slopeFactor = 188 // largeur des images dans le carousel
  const margin = 200

  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 2.3,
      pprojectialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2.7,
      pprojectialVisibilityGutter: 30,
    },
    tabletXL: {
      breakpoint: {
        max: 1440,
        min: 1024,
      },
      items: 3.5,
      partialVisibilityGutter: 30,
    },
    desktopSM: {
      breakpoint: {
        max: 3000,
        min: 1440,
      },
      items: useItemsToDispayHelper(slopeFactor, margin).integerToDisplay,
      partialVisibilityGutter: useItemsToDispayHelper(slopeFactor, margin).remainderToDisplay,
    },
  }

  const array = Object.keys(playlists)
  const playlistsArray = [array[0], array[1], array[2], array[3], array[4], array[5]]
  const playlistImageSource = (playlistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const renderItem = (index) => {
    const playlist = playlists[playlistsArray[index]]
    if (!playlist) {
      return (<div />)
    }
    return (
      <div className="container__playlist">
        {playlist && (
          <div className="slider_item">
            <Link
              to={`${config.REACT_APP_PREFIX}/playlist/${playlist.id}`}
              onClick={() => {
                gaSender('action_home_screen_click_online_list', playlist.id)
              }}
            >
              <figure className="picture_content">
                <img
                  className="picture"
                  src={playlistImageSource(playlist.id)}
                  alt="slide"
                />
              </figure>
              <p className="playlistTitle">
                {playlist && playlist.metadata && playlist.metadata.title}
              </p>
            </Link>
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="container-main-carousel container__only slider_bloc">
      <div className="container-titles-only slider-title">
        <h2 className="title title-only">
          <Link to="/explore" className="link__title">
            <span>{t('onlyforyou')}</span>
            <img src="/assets/picto_fleche_go.svg" alt={t('seeall')} />
          </Link>
        </h2>
        <Link
          className="button__seemore"
          to={{ pathname: '/explore' }}
          onClick={() => {
            gaSender('action_onlyforyou_see_all')
          }}
        >
          <text>{t('seeall')}</text>
        </Link>
      </div>
      <div className="slider-body">
        <Carousel
          arrows={false}
          keyBoardControl
          partialVisible
          responsive={responsive}
          slidesToSlide={1}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
        >
          {renderItem(0)}
          {renderItem(1)}
          {renderItem(2)}
          {renderItem(3)}
          {renderItem(4)}
          {renderItem(5)}
        </Carousel>
      </div>
    </div>
  )
}

OnlyForYou.propTypes = {
  playlists: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default OnlyForYou
