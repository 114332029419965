import { REHYDRATE } from 'redux-persist'

import { UPDATE_TUTORIAL_VALUE } from '../actions'

const initialState = {
  hasStartTutorial: false,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case REHYDRATE: {
      const incoming = (payload && payload.tutorial) || {}
      return {
        ...state,
        ...incoming,
        rehydrated: true,
      }
    }
    case UPDATE_TUTORIAL_VALUE: {
      const { start } = payload
      return {
        ...state,
        hasStartTutorial: start,
      }
    }
    default:
      return state
  }
}
