import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginWithUserToken } from '../../actions'

const ByPassQueryToken = () => {
  const [querySent, setQuerySent] = useState(false)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const dispatch = useDispatch()
  const query = useQuery()

  const rawData = query.get('user')

  const logger = useCallback(async () => {
    try {
      const user = JSON.parse(rawData)
      await dispatch(loginWithUserToken(user))
    } catch (e) {
      console.error(e)
    }
  }, [dispatch, rawData])

  useEffect(() => {
    if (rawData !== null && querySent === false) {
      setQuerySent(true)
      logger()
    }
  }, [dispatch, logger, rawData, querySent, setQuerySent])

  return (
    <div>
      login by query
    </div>
  )
}

export default ByPassQueryToken
