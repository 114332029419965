import {
  STORE_TERRITORIES,
  PROCESSING_TERRITORIES,
  PROCESSED_TERRITORIES,
} from '../actions'

const initialState = {
  territories: [],
  processing: true,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_TERRITORIES: {
      const { projects: territories } = payload
      return {
        ...state,
        territories,
      }
    }
    case PROCESSING_TERRITORIES:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_TERRITORIES:
      return {
        ...state,
        processing: false,
      }
    default:
      return state
  }
}
