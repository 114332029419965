import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { jwtDecode } from 'jwt-decode'
import { isAuthorized } from '../../lib'

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const redirectPath = localStorage.getItem('user') ? '/' : '/selectcountry'
  return (
    <Route
      {...rest}
      render={(props) => (
        (localStorage.getItem('user') && isAuthorized(jwtDecode(localStorage.getItem('user')).role, access)) ? <Component {...props} /> : <Redirect to={{ pathname: `${redirectPath}` }} />
      )}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
  access: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.string,
    PropTypes.any,
  ]).isRequired,
}

export default PrivateRoute
