import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import config from '../../config'
import configureStore from '../../store'

const { store } = configureStore()

const PlaylistGrid = (props) => {
  const {
    user,
  } = store.getState()
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const queue = useSelector((state) => state.queue.playlistId)
  const { playlists, order } = props

  const playlistImageSource = (playlistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=224&height=224&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  return (
    <div className={`container__playlistGrid ${(queue !== null) ? 'container-playlistGrid-with-player' : ''}`}>
      <div className="content__list">
        {order.map((playlist) => (
          <div key={`item-${playlists[playlist].id}`} className="text-white">
            <Link key={`ref-${playlists[playlist].id}`} to={`${config.REACT_APP_PREFIX}/playlist/${playlists[playlist].id}`}>
              <figure className="container__img">
                <img
                  src={playlistImageSource(playlists[playlist].id)}
                  alt="slide"
                />
              </figure>
              {playlists[playlist] && playlists[playlist].metadata && (
                <div className="container-description">
                  <p className="title">
                    {playlists[playlist].metadata.title}
                  </p>
                </div>
              )}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

PlaylistGrid.propTypes = {
  playlists: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
    metadata: PropTypes.shape({
      title: PropTypes.string,
    }),
  })).isRequired,
  order: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default PlaylistGrid
