import React from 'react'
import './styles/index.scss'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { history, gaSender } from '../../lib'
import { OnBoardingSkipButton } from '../../components'
import { updateOnBoardingValue } from '../../actions'

const OnBoardingWelcome = () => {
  const { t } = useTranslation('onboarding')
  const dispatch = useDispatch()

  React.useEffect(() => {
    gaSender('page_onboarding_welcome')
  })

  return (
    <div className="container_onboarding_welcome">
      <div className="bg-onboarding-welcome">
        <OnBoardingSkipButton />
        <div className="onboarding-welcome-content">
          <h1>{t('titlewelcome')}</h1>
          <p>{t('textwelcome')}</p>
          <button
            type="button"
            className="btn-welcome"
            onClick={() => {
              dispatch(updateOnBoardingValue(true))
              history.push('/onboardinggender')
            }}
          >
            {t('btnonwelcome')}
          </button>
        </div>
      </div>
    </div>
  )
}
export default OnBoardingWelcome
