import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import {
  LOGIN_SUCCESS,
  RELOGIN_FAILURE,
  RELOGIN_FINISHED,
  RELOGIN_REQUEST,
  loadMaintenance,
} from './actions'
import api from './api'
import {
  OverlayPortaitWarning,
  Player,
  PrivateRoute,
  Relogin,
  VoucherPopIn,
} from './components'
import {
  authorizedRole,
  getUserFromResponse,
  history,
  isUserTokenExpired,
} from './lib'
import {
  AccessPortal,
  ArtistPage,
  AskPhoneNumber,
  AudioPlayer,
  ByPassQueryToken,
  ChangeLanguagePage,
  CountrySelection,
  Explore,
  ForgotLogin,
  ForgotPwd,
  FreeTrialPass,
  Home,
  Identification,
  Maintenance,
  MyFavorites,
  MyMusic,
  OnBoardingBirth,
  OnBoardingGender,
  OnBoardingGenres,
  OnBoardingMoods,
  OnBoardingWelcome,
  Playlist,
  Settings,
  Subscribe,
  TopTracks,
  Tutorial,
  Voucher,
  VoucherValidation,
} from './pages'
import RegistrationPortal from './pages/RegistrationPortal'
import './styles/template.scss'

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

const { REACT_APP_INFRA = 'dev' } = process.env
if (REACT_APP_INFRA === 'prod') {
  ReactGA.initialize('G-0YBRZY68QK')

  if (localStorage.getItem('userId')) {
    ReactGA.set({ userId: localStorage.getItem('userId') })
  }

  if (localStorage.getItem('dimension1')) {
    ReactGA.set({ dimension1: localStorage.getItem('dimension1') })
  }

  if (localStorage.getItem('PROJECT_ID')) {
    ReactGA.set({ dimension2: localStorage.getItem('PROJECT_ID') })
  }
}

export default function BasicExample() {
  const tracks = useSelector((state) => state.playlists.tracks)
  const queue = useSelector((state) => state.queue)
  const dispatch = useDispatch()

  const refreshToken = localStorage.getItem('refreshToken')
  const user = localStorage.getItem('user')

  if (refreshToken && !user) {
    if (isUserTokenExpired(refreshToken)) {
      localStorage.removeItem('refreshToken')
    } else {
      dispatch({ type: RELOGIN_REQUEST })
      api
        .getToken()
        .then((response) => {
          if (response) {
            dispatch({ type: RELOGIN_FINISHED })
            const usr = getUserFromResponse({
              access_token: response.access_token,
              refresh_token: response.refresh_token,
            })
            localStorage.setItem('user', JSON.stringify(usr))
            dispatch({ type: LOGIN_SUCCESS, user: usr })
            history.push('/')
          } else {
            dispatch({ type: RELOGIN_FAILURE })
          }
        })
        .catch(() => {
          dispatch({ type: RELOGIN_FAILURE })
        })
    }
  }

  const getSubId = async () => {
    const accessToken = localStorage.getItem('user')
    if (accessToken) {
      const subId = await api.getSubId()
      if (subId?.subscription_id) {
        localStorage.setItem('subId', subId?.subscription_id)
      }
    }
  }

  useEffect(() => {
    getSubId()
  }, [])

  useEffect(() => {
    dispatch(loadMaintenance())
  }, [dispatch])
  return (
    <Router history={history}>
      <div>
        <OverlayPortaitWarning />
        <VoucherPopIn />
        <Relogin />
        <Switch>
          <PrivateRoute
            exact
            path="/"
            component={Home}
            access={authorizedRole.USER}
          />
          <Route path="/registration-portal/:project_id" component={RegistrationPortal} />
          <Route path="/subscription-success:type?" component={AccessPortal} />
          <Route path="/selectcountry" component={CountrySelection} />
          <Route path="/test" component={ByPassQueryToken} />
          <Route path="/phonenumber" component={AskPhoneNumber} />
          <Route path="/identification" component={Identification} />
          <Route path="/voucher/:territory?" component={Voucher} />
          <Route path="/vouchervalidation" component={VoucherValidation} />
          <Route path="/freetrial" component={FreeTrialPass} />
          <Route path="/forgotPwd" component={ForgotPwd} />
          <Route path="/maintenance" component={Maintenance} />
          <Route path="/changeLanguage" component={ChangeLanguagePage} />
          <Route path="/subscribe" component={Subscribe} />
          <Route path="/forgotLogin" component={ForgotLogin} />
          <Route path="/playlist/:id/:project_id?" component={Playlist} />
          <PrivateRoute
            path="/onboardingwelcome"
            component={OnBoardingWelcome}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/onboardinggender"
            component={OnBoardingGender}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/onboardingbirth"
            component={OnBoardingBirth}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/onboardinggenres"
            component={OnBoardingGenres}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/onboardingmoods"
            component={OnBoardingMoods}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/mymusic"
            component={MyMusic}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/settings"
            component={Settings}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/explore"
            component={Explore}
            access={authorizedRole.USER}
          />

          <PrivateRoute
            path="/audioplayer"
            component={AudioPlayer}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/toptracks/:id"
            component={TopTracks}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/artistpage/:id"
            component={ArtistPage}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/myfavorites"
            component={MyFavorites}
            access={authorizedRole.USER}
          />
          <PrivateRoute
            path="/tutorial"
            component={Tutorial}
            access={authorizedRole.USER}
          />
        </Switch>
        <Player tracks={tracks} queue={queue} />
      </div>
    </Router>
  )
}
