import api from '../api'
import {
  STORE_TERRITORIES,
  PROCESSING_TERRITORIES,
  PROCESSED_TERRITORIES,
} from './constants'

const storeTerritories = (projects) => ({
  type: STORE_TERRITORIES,
  payload: { projects },
})

const processingTerritories = () => ({
  type: PROCESSING_TERRITORIES,
})

const processedTerritories = () => ({
  type: PROCESSED_TERRITORIES,
})

export const fetchTerritories = (containerId) => async (dispatch) => {
  dispatch(processingTerritories())
  const territories = await api.fetchTerritories(containerId)
  dispatch(storeTerritories(territories))
  dispatch(processedTerritories())
}
