import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Carousel from 'react-multi-carousel'
import { Link } from 'react-router-dom'
import { IoIosMusicalNotes } from 'react-icons/io'
import { FaHeart, FaPlus } from 'react-icons/fa'
import config from '../../config'
import { MainMenu, FPlaylistGrid, ButtonGroup } from '../../components'
import {
  fetchArticlesAndPlaylists,
  fetchFavoritesPlaylists,
  playFirstTrackFromPlaylist,
} from '../../actions'
import { history, gaSender } from '../../lib'
import configureStore from '../../store'
import { useItemsToDispayHelper } from '../../lib/ItemsToDisplayHelper'

const { store } = configureStore()
const MyMusic = () => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const { t } = useTranslation('mymusic')
  const dispatch = useDispatch()
  const playlists = useSelector((state) => state.playlists.playlists)
  const ftracks = useSelector((state) => state.favorite.tracks)
  const favoplaylists = useSelector((state) => state.favorite.fplaylists)
  const queue = useSelector((state) => state.queue.playlistId)
  React.useEffect(() => {
    gaSender('page_my_music_screen')
    dispatch(fetchArticlesAndPlaylists())
    dispatch(fetchFavoritesPlaylists())
  }, [dispatch])
  const { user } = store.getState()
  const coverWidth = 224
  const coverHeight = 224
  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang

  const parseTimeFav = (
    initSec,
    hourText = ' h',
    minText = ' min',
    plural = '',
  ) => {
    let sec = 0
    const tracksList = ftracks
    if (!initSec && tracksList) {
      const tracksArray = Object.keys(tracksList)
      for (let i = 0; i < tracksList.length; i++) {
        if (tracksList[tracksArray[i]].duration) {
          sec += tracksList[tracksArray[i]].duration
        }
      }
    } else {
      sec = initSec
    }

    const dayUtc = dayjs.utc(sec * 1000)
    const hours = Number(dayUtc.format('HH'))
    const min = dayUtc.format('mm')

    let formatted = ''
    if (hours > 0) {
      formatted = `${hours}${hourText}`
      if (hours > 1) {
        formatted += plural
      }
      formatted += ' '
    }
    if (min > 0) {
      formatted += `${min}${minText}`
      if (min > 1) {
        formatted += plural
      }
    }
    return formatted
  }

  const suggestplaylistImageSource = (suggestplaylistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${suggestplaylistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=${coverWidth}&height=${coverHeight}&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const btnPlay = (favorplaylistId) => {
    dispatch(playFirstTrackFromPlaylist(favorplaylistId))
  }
  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 3,
      partialVisibilityGutter: 0,
    },
    mobileL: {
      breakpoint: {
        max: 767,
        min: 465,
      },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: {
        max: 1023,
        min: 768,
      },
      items: useItemsToDispayHelper(160, 0).integerToDisplay,
      partialVisibilityGutter: useItemsToDispayHelper(160, 0)
        .remainderToDisplay,
    },
    tabletXL: {
      breakpoint: {
        max: 1439,
        min: 1024,
      },
      items: useItemsToDispayHelper(192, 10).integerToDisplay,
      partialVisibilityGutter:
        useItemsToDispayHelper(192, 10).remainderToDisplay - 24,
    },
    desktopSM: {
      breakpoint: {
        max: 1679,
        min: 1440,
      },
      items: useItemsToDispayHelper(224, 50).integerToDisplay,
      partialVisibilityGutter: useItemsToDispayHelper(224, 50)
        .remainderToDisplay,
    },
    desktopXL: {
      breakpoint: {
        max: 3000,
        min: 1680,
      },
      items: useItemsToDispayHelper(224, 50).integerToDisplay - 1,
      partialVisibilityGutter: useItemsToDispayHelper(224, 50)
        .remainderToDisplay,
    },
  }

  return (
    <div
      className={`container-page container-mymusic ${
        queue !== null ? 'container-page-with-player' : ''
      }`}
    >
      {/* main content */}
      <article className="main_content">
        <h1 className="title-page">{t('mymusic')}</h1>
        {/* favorite tracks */}
        <div className="fav-tracks">

          <button
            type="button"
            className="buttontracksfavorites"
            onClick={() => {
              history.push('/myfavorites')
            }}
          >
            <div className="linear-gradient">
              <FaHeart color="white" className="heart" size="0.5em" />
              <IoIosMusicalNotes color="white" size="2em" />
            </div>
            <h2 className="title-fav-track">{t('favoritesplaylist')}</h2>
          </button>

          <div className="myplaylist-tablet">
            <div className="fav-desc-container-tablet">
              {/* <p id="myplaylist">{t('myplaylists')}</p> */}
              <div className="fav-desc-container">
                <div className="fav-desc-content-tablet">
                  <div className="fav-desc">
                    <p style={{ fontWeight: 'bold' }}>{ftracks.length}</p>
                    <p style={{ fontWeight: 'bold' }}>{t('tracks')}</p>
                    <p className="duration-tablet">
                      {parseTimeFav(ftracks.duration)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {ftracks.length > 0 && (
              <div className="btn-play">
                <img
                  src="/assets/picto_player_little.svg"
                  alt="button play"
                  className="image-button"
                />
              </div>
            )}
          </div>
        </div>
        {/* My playlist */}
        {favoplaylists.length > 0 ? (
          <div className="body-suggestions">
            <div className="myplaylist-desc">
              <h2 className="title-section">{t('myplaylists')}</h2>
              <FaHeart color="white" className="heart" />
            </div>
            <FPlaylistGrid favorplaylists={favoplaylists} responsive={responsive} />
          </div>
        ) : (
          <div className="fav-lists">
            <button
              aria-label="btn"
              type="button"
              className="buttonlistfavorites"
              onClick={() => {
                history.push('/explore')
              }}
            >
              <FaPlus color="white" size="2em" />

            </button>
          </div>
        )}
        {/* our suggests */}
        {Object.keys(playlists).length > 0 && (
          <div className="body-suggestions">
            <h2 className="title-section">{t('suggest')}</h2>
            <div className="slider_content">
              <Carousel
                arrows={false}
                keyBoardControl
                slidesToSlide={1}
                renderButtonGroupOutside={true}
                partialVisible={true}
                responsive={responsive}
                customButtonGroup={<ButtonGroup />}
              >
                {Object.keys(playlists).map((playlist) => (
                  <div
                    key={`item-${playlists[playlist].id}`}
                    className="container-playlist-suggestion"
                  >
                    <Link
                      className="link-item"
                      key={`ref-${playlists[playlist].id}`}
                      to={`${config.REACT_APP_PREFIX}/playlist/${playlists[playlist].id}`}
                      onClick={() => {
                        gaSender(
                          'action_mymusic_screen_click_online_list',
                          playlists[playlist].id,
                          playlists[playlist].metadata.title,
                        )
                      }}
                    >
                      <figure className="picture_content">
                        <img
                          className="picture"
                          src={suggestplaylistImageSource(
                            playlists[playlist].id,
                          )}
                          alt="slide"
                        />
                      </figure>
                      <div className="container-description">
                        <h3 className="playlistTitle">
                          {playlists[playlist] &&
                            playlists[playlist].metadata &&
                            playlists[playlist].metadata.title}
                        </h3>
                        <p className="playlistDescription">
                          {playlists[playlist] &&
                            playlists[playlist].metadata &&
                            playlists[playlist].metadata.description}
                        </p>
                        <div className="container-play">
                          <button
                            type="button"
                            className="btn-play"
                            onClick={() => btnPlay(playlists[playlist].id)}
                          >
                            <img
                              src="/assets/picto_player_little.svg"
                              alt="button play"
                              className="image-button"
                            />
                          </button>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        )}
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
    </div>
  )
}

export default MyMusic
