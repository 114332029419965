import {
  CHOOSE_UNITED_PROJECT_ID,
  PROCESSING_PROJECT,
  PROCESSED_PROJECT,
  STORE_PROJECT,
  STORE_UNITED_PROJECTS,
  LOGIN_METHOD,
  REMOVE_PROJECTS,
  STORE_PROJECT_LINKS,
} from '../actions'

const initialState = {
  languages: [],
  lists: [],
  currentTerritory: [],
  projects: [],
  PROJECT_ID: null,
  methods: [],
  links: {},
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case STORE_PROJECT: {
      return {
        ...state,
        projects: [
          ...state.projects,
          payload.project,
        ],
      }
    }
    case STORE_PROJECT_LINKS: {
      const { links } = payload
      return {
        ...state,
        links,
      }
    }
    case STORE_UNITED_PROJECTS: {
      return {
        ...state,
        projects: payload.projects,
      }
    }
    case CHOOSE_UNITED_PROJECT_ID: {
      localStorage.setItem('PROJECT_ID', payload.projectId)
      return {
        ...state,
        PROJECT_ID: payload.projectId,
      }
    }
    case PROCESSING_PROJECT:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_PROJECT:
      return {
        ...state,
        processing: false,
      }
    case LOGIN_METHOD: {
      const { methods } = payload
      return {
        ...state,
        methods,
      }
    }
    case REMOVE_PROJECTS:
      return {
        ...state,
        projects: [],
      }
    default:
      return state
  }
}
