import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { MainMenu, ArticlesCarousel, OnlyForYou, TopCountry, Moods, Genres, AppBanner } from '../../components'
import { fetchArticlesAndPlaylists, fetchPlaylistForOnlyForYou, updateTutorialValue } from '../../actions'
import { history, gaSender } from '../../lib'

const Home = () => {
  const { t } = useTranslation('home')
  const dispatch = useDispatch()
  const articles = useSelector((state) => state.articles.articles)
  const playlists = useSelector((state) => state.playlists.playlists)
  const onlyForYou = useSelector((state) => state.playlists.onlyForYou)
  const moods = useSelector((state) => state.explorer.moods)
  const genres = useSelector((state) => state.explorer.genres)
  const hasStartTutorial = useSelector((state) => state.tutorial.hasStartTutorial)
  const queue = useSelector((state) => state.queue.playlistId)
  useEffect(() => {
    gaSender('page_home_screen')
    dispatch(fetchArticlesAndPlaylists())
    dispatch(fetchPlaylistForOnlyForYou())
  }, [dispatch])

  return (
    <main className={`container-page container-home ${(queue !== null) ? 'container-page-with-player' : ''}`}>
      {/* main content */}
      <article className="main_content">
        <h1 className="title-page">{t('welcome')}</h1>
        <div>
          <ArticlesCarousel articles={articles} />
        </div>
        <AppBanner />
        {!hasStartTutorial && (
        <div className="tutorial">
          <button
            onClick={() => {
              gaSender('action_tutorial_skip')
              dispatch(updateTutorialValue(true))
            }}
            className="close-tuto"
            type="button"
          >
            x
          </button>
          <h2>{t('tutorialStart')}</h2>
          <p>{t('tutorialStartDesc')}</p>
          <button
            className="tuto-ok"
            type="button"
            onClick={() => {
              gaSender('action_tutorial_start')
              history.push('/tutorial')
              dispatch(updateTutorialValue(true))
            }}
          >
            {t('tutorialStartOk')}
          </button>
          <button
            className="tuto-skip"
            type="button"
            onClick={() => {
              gaSender('action_tutorial_skip')
              dispatch(updateTutorialValue(true))
            }}
          >
            {t('tutorialStartSkip')}
          </button>
          <p>{t('tutorialStartFooter')}</p>

        </div>
        )}
        {onlyForYou.length > 0 ? (
          <OnlyForYou playlists={onlyForYou} />
        ) : (
          <OnlyForYou playlists={playlists} />
        )}
        <Moods moods={moods} />
        <TopCountry />
        <Genres genres={genres} />
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
    </main>
  )
}

export default Home
