import {
  FAVORITES_LIST_ID,
  QUEUE_ADD_TRACKS,
  QUEUE_TOGGLE_SHUFFLE,
  QUEUE_TOGGLE_REPEAT,
  QUEUE_DISABLE_REPEAT,
  QUEUE_SKIP_NEXT_TRACK,
  QUEUE_SKIP_PREVIOUS_TRACK,
  QUEUE_ENDED,
  NO_QUEUE,
  FAVORITE_PLAYLIST_ID,
  TOPTRACK_PLAYLIST_ID,
} from './constants'

import { playTrack, setTime } from './player'
import { trackEvent } from './analytics'
import { sendStats } from './stats'
import { gaSender } from '../lib'

export const noQueue = () => ({ type: NO_QUEUE })

export const toggleRepeat = () => (dispatch, getState) => {
  dispatch({
    type: QUEUE_TOGGLE_REPEAT,
  })
  const {
    playlists,
    queue: { playlistId, repeat, repeatOnce, queueIndex, territoryId },
    favorite: { tracks: favoriteTracks },
    toptracks: { topTracks },
  } = getState()
  if (repeatOnce) {
    let tracks = []
    switch (playlistId) {
      case FAVORITE_PLAYLIST_ID:
        tracks = favoriteTracks
        break
      case TOPTRACK_PLAYLIST_ID:
        tracks = topTracks[territoryId]
        break
      default:
        tracks = playlists.tracks[playlistId]
    }
    const currentTrack = tracks[queueIndex]
    const { id, title: track, artists } = currentTrack
    const { name: artist } = artists[0]
    dispatch(trackEvent('action_player_screen_repeat_one', { id, track, artist }))
  } else if (repeat) {
    dispatch(trackEvent('action_player_screen_repeat_all', { id: playlistId }))
  }
}

export const addTracksToQueue = (playlistId, numberOfTracks, trackIndex) => ({
  type: QUEUE_ADD_TRACKS,
  payload: {
    playlistId,
    numberOfTracks,
    trackIndex,
  },
})

export const toggleShuffle = () => (dispatch, getState) => {
  const { queue: { playlistId } } = getState()
  if (playlistId === FAVORITE_PLAYLIST_ID) {
    dispatch(trackEvent('action_player_screen_schuffle', { id: FAVORITES_LIST_ID, title: 'Favoris' }))
  } else if (playlistId === TOPTRACK_PLAYLIST_ID) {
    dispatch(trackEvent('action_player_screen_schuffle', { id: TOPTRACK_PLAYLIST_ID, title: 'Top Tracks' }))
  } else {
    const { playlists: allPlaylist } = getState()
    const { playlists } = allPlaylist
    const { id, metadata: { title } } = playlists[playlistId]
    dispatch(trackEvent('action_player_screen_schuffle', { id, title }))
  }
  dispatch({
    type: QUEUE_TOGGLE_SHUFFLE,
  })
}

export const queueEnded = () => ({
  type: QUEUE_ENDED,
})

export const playNextTrack = () => (dispatch, getState) => {
  const {
    playlists,
    queue: { playlistId, queueIndex, repeat, repeatOnce, tracksIndexes, territoryId },
    favorite: { tracks: favoriteTracks },
    toptracks: { topTracks },
  } = getState()
  let tracks = []
  switch (playlistId) {
    case FAVORITE_PLAYLIST_ID:
      tracks = favoriteTracks
      break
    case TOPTRACK_PLAYLIST_ID:
      tracks = topTracks[territoryId]
      break
    default:
      tracks = playlists.tracks[playlistId]
  }

  gaSender('action_player_screen_skip', tracks[queueIndex].id, tracks[queueIndex].title)

  let newQueueIndex = queueIndex + 1
  if (repeatOnce) {
    newQueueIndex = queueIndex
  } else if (newQueueIndex >= tracks.length) {
    if (repeat) {
      newQueueIndex = 0
    } else {
      dispatch(setTime(
        false,
        tracks[queueIndex].duration,
      ))
      return dispatch(queueEnded())
    }
  }
  const trackIndex = tracksIndexes[newQueueIndex]

  dispatch(playTrack(tracks[trackIndex], playlistId, trackIndex, newQueueIndex, territoryId))
}

export const trackEnded = () => (dispatch, getState) => {
  const {
    playlists,
    queue: { playlistId, queueIndex, repeatOnce, tracksIndexes, territoryId },
    favorite: { tracks: favoriteTracks },
    toptracks: { topTracks },
  } = getState()
  let tracks = []
  switch (playlistId) {
    case FAVORITE_PLAYLIST_ID:
      tracks = favoriteTracks
      break
    case TOPTRACK_PLAYLIST_ID:
      tracks = topTracks[territoryId]
      break
    default:
      tracks = playlists.tracks[playlistId]
  }

  if (tracks.length === 0 && playlistId === FAVORITE_PLAYLIST_ID) {
    dispatch(noQueue())
    return
  }

  const trackIndex = tracksIndexes[queueIndex]
  if (repeatOnce) {
    dispatch(playTrack(tracks[trackIndex], playlistId, trackIndex, queueIndex, territoryId))
    dispatch({
      type: QUEUE_DISABLE_REPEAT,
    })
  } else {
    dispatch(playNextTrack())
  }
}

export const skipNextTrack = () => (dispatch, getState) => {
  let tracks = []
  const {
    playlists,
    queue: { playlistId, queueIndex, playingTrackId, territoryId },
    player: { currentTime },
    favorite: { tracks: favoriteTracks },
    toptracks: { topTracks },
  } = getState()
  switch (playlistId) {
    case FAVORITE_PLAYLIST_ID:
      tracks = favoriteTracks
      break
    case TOPTRACK_PLAYLIST_ID:
      tracks = topTracks[territoryId]
      break
    default:
      tracks = playlists.tracks[playlistId]
  }
  if (tracks && tracks[queueIndex]) {
    const currentTrack = tracks[queueIndex]
    if (currentTrack) {
      const { id, title, artists } = currentTrack
      const { name: artist } = artists[0]
      dispatch(trackEvent('action_player_screen_skip', { id, title, artist }))
    }

    dispatch(sendStats('next', playingTrackId, playlistId, currentTime))
  }
  dispatch(playNextTrack(true))
  dispatch({
    type: QUEUE_SKIP_NEXT_TRACK,
  })
}

export const skipPreviousTrack = () => (dispatch, getState) => {
  const {
    playlists,
    queue: {
      playlistId, queueIndex, tracksIndexes, repeat, repeatOnce, playingTrackId, territoryId,
    },
    player: { currentTime },
    favorite: { tracks: favoriteTracks },
    toptracks: { topTracks },
  } = getState()
  let tracks = []
  switch (playlistId) {
    case FAVORITE_PLAYLIST_ID:
      tracks = favoriteTracks
      break
    case TOPTRACK_PLAYLIST_ID:
      tracks = topTracks[territoryId]
      break
    default:
      tracks = playlists.tracks[playlistId]
  }
  const currentTrack = tracks[queueIndex]
  if (currentTrack) {
    const { id, title, artists } = currentTrack
    const { name: artist } = artists[0]
    dispatch(trackEvent('action_player_screen_skip', { id, title, artist }))
  }

  gaSender('action_player_screen_skip', tracks[queueIndex].id, tracks[queueIndex].title)

  let newQueueIndex = queueIndex - 1
  if (repeatOnce) {
    newQueueIndex = queueIndex
  } else if (newQueueIndex < 0) {
    newQueueIndex = 0

    if (repeat) {
      newQueueIndex = tracks.length - 1
    }
  }
  const trackIndex = tracksIndexes[newQueueIndex]

  dispatch(sendStats('previous', playingTrackId, playlistId, currentTime))

  dispatch(playTrack(tracks[trackIndex], playlistId, trackIndex, newQueueIndex, territoryId))
  dispatch({
    type: QUEUE_SKIP_PREVIOUS_TRACK,
  })
}
