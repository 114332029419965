import React, { useState, useEffect } from 'react'
import './styles/index.scss'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Switch from 'react-switch'
import config from '../../config'

import { updateCookieChoice, updateOptinCookieChoice } from '../../actions'

const RgpdConfigure = (props) => {
  const { t } = useTranslation('rgpd')
  const locale = useSelector((state) => state.config.locale)
  const choice = useSelector((state) => state.rgpd.choiceCookie)

  const checkValue1 = () => {
    let val = false
    choice.forEach((element) => {
      if (element.idOptin === 1) {
        val = element.value
      }
    })
    return val
  }

  const checkValue2 = () => {
    let val = false
    choice.forEach((element) => {
      if (element.idOptin === 2) {
        val = element.value
      }
    })
    return val
  }

  const checkValue3 = () => {
    let val = false
    choice.forEach((element) => {
      if (element.idOptin === 3) {
        val = element.value
      }
    })
    return val
  }

  const checkValue4 = () => {
    let val = false
    choice.forEach((element) => {
      if (element.idOptin === 4) {
        val = element.value
      }
    })
    return val
  }

  const [checked1, setChecked1] = useState(checkValue1())
  const [checked2, setChecked2] = useState(checkValue2())
  const [checked3, setChecked3] = useState(checkValue3())
  const [checked4, setChecked4] = useState(checkValue4())

  const dispatch = useDispatch()
  const {
    location: {
      state,
    },
    configCloseHandler,
  } = props

  const handleChange = (nextChecked, event, id) => {
    if (id === '1') {
      setChecked1(nextChecked)
      dispatch(updateCookieChoice(1, nextChecked))
    } else if (id === '2') {
      setChecked2(nextChecked)
      dispatch(updateCookieChoice(2, nextChecked))
    } else if (id === '3') {
      setChecked3(nextChecked)
      dispatch(updateCookieChoice(3, nextChecked))
    } else if (id === '4') {
      setChecked4(nextChecked)
      dispatch(updateCookieChoice(4, nextChecked))
    }
    if (state.scene === 'settings') {
      dispatch(updateOptinCookieChoice())
    }
  }

  const colorActive = '#3cad00'
  const boxShadow = '0px 1px 5px rgba(0, 0, 0, 0.6)'
  const activeBoxShadow = '0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
  const switchHeight = 16
  const switchWidth = 39
  const switchDiameter = 24

  useEffect(() => {
    if (choice.length === 0) {
      dispatch(updateCookieChoice(1, checked1))
      dispatch(updateCookieChoice(2, checked2))
      dispatch(updateCookieChoice(3, checked3))
      dispatch(updateCookieChoice(4, checked4))
    }
  }, [dispatch, checked1, checked2, checked3, checked4, choice])

  return (
    <div className="bg_rgpdConfigure">
      <div className="rgpdConfigure_content">
        <button
          type="button"
          className="btn_close"
          onClick={() => configCloseHandler()}
        >
          <p>x</p>
        </button>
        <div className="center_logo">
          <img alt="logo" src="/assets/images/logo_digster.png" className="logo" />
        </div>
        <div>
          <p className="rgpdConfigure_info">
            {t('rgpdconfigureinfo1')}
            <a
              href={`${config.API_BASE_URL}/assets/privacy-${locale}.pdf`}
              className="rgpd_privacy"
              alt={t('rgpdlink')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('rgpdprivacy')}
            </a>
          </p>
        </div>
        <p className="rgpdConfigure_info">{t('rgpdconfigureinfo2')}</p>
        <h2 className="rgpdConfigure_autorization">{t('rgpdConfigureautorization')}</h2>
        <div className="cookieChoice">
          <p className="rgpdConfigure_autorization_text">
            <span className="plusicon">+</span>
            {t('rgpdconfigureautorizationtext1')}
          </p>
          <Switch
            onChange={handleChange}
            checked={checked1}
            onColor={colorActive}
            onHandleColor={colorActive}
            handleDiameter={switchDiameter}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow}
            height={switchHeight}
            width={switchWidth}
            id="1"
          />
        </div>
        <div className="cookieChoice">
          <p className="rgpdConfigure_autorization_text">
            <span className="plusicon">+</span>
            {t('rgpdconfigureautorizationtext2')}
          </p>
          <Switch
            onChange={handleChange}
            checked={checked2}
            onColor={colorActive}
            onHandleColor={colorActive}
            handleDiameter={switchDiameter}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow}
            height={switchHeight}
            width={switchWidth}
            id="2"
          />
        </div>
        <div className="cookieChoice">
          <p className="rgpdConfigure_autorization_text">
            <span className="plusicon">+</span>
            {t('rgpdconfigureautorizationtext3')}
          </p>
          <Switch
            onChange={handleChange}
            checked={checked3}
            onColor={colorActive}
            onHandleColor={colorActive}
            handleDiameter={switchDiameter}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow}
            height={switchHeight}
            width={switchWidth}
            id="3"
          />
        </div>
        <div className="cookieChoice">
          <p className="rgpdConfigure_autorization_text">
            <span className="plusicon">+</span>
            {t('rgpdconfigureautorizationtext4')}
          </p>
          <Switch
            onChange={handleChange}
            checked={checked4}
            onColor={colorActive}
            onHandleColor={colorActive}
            handleDiameter={switchDiameter}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow={boxShadow}
            activeBoxShadow={activeBoxShadow}
            height={switchHeight}
            width={switchWidth}
            id="4"
          />
        </div>
      </div>
    </div>
  )
}

RgpdConfigure.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      scene: PropTypes.string,
    }),
  }).isRequired,
  configCloseHandler: PropTypes.func.isRequired,
}

export default RgpdConfigure
