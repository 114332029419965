import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import './styles/index.scss'

const ModalCancelSubscription = ({ isShowing, hide, cancel }) => {
  const { t } = useTranslation('modalSubscription')

  return (
    <div>
      {isShowing && (
      <div className="modalSubscription">
        <h1>{t('modalTitle')}</h1>
        <p>{t('modalDesc')}</p>
        <button
          type="button"
          onClick={cancel}
          className="cancel"
        >
          {t('cancel')}
        </button>
        <button
          type="button"
          onClick={hide}
          className="modal-skip"
        >
          {t('close')}
        </button>
      </div>
      )}
    </div>
  )
}

ModalCancelSubscription.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
}
export default ModalCancelSubscription
