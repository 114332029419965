import {
  PROCESSING_FAVORITES_TRACKS,
  PROCESSED_FAVORITES_TRACKS,
  FAVORITE_ADD_TRACK,
  FAVORITE_REMOVE_TRACK,
  STORE_FAVORITES_TRACKS,
  RELOAD_FAVORITES_TRACKS,
  FAVORITE_ADD_PLAYLIST,
  FAVORITE_REMOVE_PLAYLIST,
  PROCESSING_FAVORITES_PLAYLISTS,
  PROCESSED_FAVORITES_PLAYLISTS,
  STORE_FAVORITES_PLAYLISTS,
  RELOAD_FAVORITES_PLAYLISTS,
} from '../actions'

const initialState = {
  tracks: [],
  fplaylists: [],
  processing: false,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case RELOAD_FAVORITES_TRACKS:
      return {
        ...state,
        tracks: [],
      }
    case RELOAD_FAVORITES_PLAYLISTS:
      return {
        ...state,
        fplaylists: [],
      }
    case STORE_FAVORITES_TRACKS: {
      const { tracks } = payload
      tracks.forEach((track) => {
        const t = track
        t.favorite = true
      })
      return {
        ...state,
        tracks: [
          ...state.tracks,
          ...tracks,
        ],
      }
    }
    case STORE_FAVORITES_PLAYLISTS: {
      const { fplaylists } = payload
      fplaylists.forEach((fplaylist) => {
        const fp = fplaylist
        fp.favorite = true
      })
      return {
        ...state,
        fplaylists: [
          ...state.fplaylists,
          ...fplaylists,
        ],
      }
    }
    case FAVORITE_ADD_TRACK: {
      const { tracks } = state
      const { track } = payload
      let isTrackAlreadyInside = false
      tracks.forEach((t) => {
        const trackInside = t
        if (track.id === trackInside.id) {
          isTrackAlreadyInside = true
          trackInside.favorite = true
        }
      })
      if (!isTrackAlreadyInside) {
        track.favorite = true
        tracks.push(track)
      }
      return {
        ...state,
        tracks: [
          ...tracks,
        ],
      }
    }
    case FAVORITE_ADD_PLAYLIST: {
      const { fplaylists } = state
      const { fplaylist } = payload
      let isPlaylistAlreadyInside = false
      fplaylists.forEach((fp) => {
        const playlistInside = fp
        if (fplaylist.id === playlistInside.id) {
          isPlaylistAlreadyInside = true
          playlistInside.favorite = true
        }
      })
      if (!isPlaylistAlreadyInside) {
        fplaylist.favorite = true
        fplaylists.push(fplaylist)
      }
      return {
        ...state,
        fplaylists: [
          ...fplaylists,
        ],
      }
    }
    case FAVORITE_REMOVE_TRACK: {
      const { tracks } = state
      const { track } = payload

      const newTracklist = []

      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].id !== track.id) {
          newTracklist.push(tracks[i])
        }
      }
      return {
        ...state,
        tracks: [
          ...newTracklist,
        ],
      }
    }
    case FAVORITE_REMOVE_PLAYLIST: {
      const { fplaylists } = state
      const { fplaylist } = payload

      const newPlaylistlist = []

      for (let i = 0; i < fplaylists.length; i++) {
        if (fplaylists[i].id !== fplaylist.id) {
          newPlaylistlist.push(fplaylists[i])
        }
      }
      return {
        ...state,
        fplaylists: [
          ...newPlaylistlist,
        ],
      }
    }
    case PROCESSING_FAVORITES_TRACKS:
      return {
        ...state,
        processing: true,
      }
    case PROCESSING_FAVORITES_PLAYLISTS:
      return {
        ...state,
        processing: true,
      }
    case PROCESSED_FAVORITES_TRACKS:
      return {
        ...state,
        processing: false,
      }
    case PROCESSED_FAVORITES_PLAYLISTS:
      return {
        ...state,
        processing: false,
      }
    default:
      return state
  }
}
