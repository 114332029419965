import React, { useState, useCallback } from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import ReactCodeInput from 'react-verification-code-input'
import { loginWithCredentials } from '../../actions'
import Spinner from '../../components/Spinner'
import config from '../../config'
import { history, gaSender } from '../../lib'

const Identification = () => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [pending, setPending] = useState(false)
  const [isRequestSent, setIsRequestSent] = useState(false)
  const { t } = useTranslation('identification')
  const { t: tError } = useTranslation('error')
  const dispatch = useDispatch()
  const registration = useSelector((state) => state.user.registration)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const phone = query.get('phoneNumber')

  const onClickCallToAction = useCallback(async () => {
    gaSender('action_auth_screen_click_sign_in')
    setPending(true)
    try {
      if (registration?.phoneNumber || phone) {
        const resp = await dispatch(loginWithCredentials(registration?.phoneNumber || phone, password))
        if (!resp) {
          setError('Invalid credentials')
        }
        setPending(false)
      } else {
        alert(t('alertphonenumber'))
        setPending(false)
        history.push('/phonenumber')
      }
    } catch (e) {
      setError(e.message)
      setPending(false)
    }
  }, [dispatch, password, phone, registration, t])

  React.useEffect(() => {
    if (password.length === 4 && !isRequestSent) {
      setIsRequestSent(true)
      onClickCallToAction()
    } else if (password.length === 1) {
      setIsRequestSent(false)
    }
  }, [dispatch, registration, password, onClickCallToAction, isRequestSent, setIsRequestSent])

  React.useEffect(() => {
    gaSender('page_signin')
  }, [])

  return (
    <div className="identification__page main_page">
      {pending && (
        <Spinner message={t('connection')} />
      )}
      {!pending && (
        <div className="container__identification container_page">
          <Link className="link_return" to="/phonenumber">
            <img src="/assets/fleche_retour.png" alt="return" />
          </Link>
          <img className="imagelogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
          <h1 className="titre_page">{t('titleidentification')}</h1>
          <p className="chapo_text">{t('identification')}</p>
          <div className="container__code">
            <div className="container__input">
              <span>DIG - </span>
              <ReactCodeInput
                fields={4}
                onChange={setPassword}
              />
            </div>
            <p className="wrap_link">
              <Link to="/forgotPwd" className="forgotLink">
                <text>{t('forgotpwd')}</text>
              </Link>
            </p>
          </div>
            {error && (<p className="alert alert-danger">{tError(error)}</p>)}
          <div className="container__button">
            <button
              className="button__validation"
              type="submit"
              onClick={() => {
                setIsRequestSent(true)
                onClickCallToAction()
              }}
            >
              {t('validation')}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Identification
