import {
  STORE_CONFIG,
} from './constants'
import i18next from '../i18n'

const storeConfig = (lang) => ({
  type: STORE_CONFIG,
  payload: { locale: lang },
})

export const getLanguage = () => async (dispatch) => {
  const lang = i18next.language ||
  navigator.language.slice(0, 2)
  || navigator.userLanguage
  || 'en'
  dispatch(storeConfig(lang))
}
