import { MAINTENANCE } from './constants'
import api from '../api'
import { history } from '../lib'

const maintenance = (payload) => ({
  type: MAINTENANCE,
  payload,
})

export const loadMaintenance = () => async (dispatch) => {
  try {
    dispatch(maintenance({
      loading: true,
      loaded: false,
    }))
    const result = await api.loadMaintenance()
    if (result.maintenance) {
      history.push('/maintenance')
    }
    dispatch(maintenance({
      loading: false,
      loaded: true,
      ...result,
    }))
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(maintenance({
      loading: false,
    }))
  }
}
