import Hls from 'hls.js'
import {
  TIME_UPDATE,
  OPEN_PLAYER,
  CLOSE_PLAYER,
  CHANGE_VOLUME,
  PLAY_TRACK,
  PAUSE_TRACK,
  RESUME_TRACK,
  PROCESSING_PLAYLIST_TRACKS,
  PROCESSED_PLAYLIST_TRACKS,
  STORE_PLAYLIST_TRACKS,
  PROCESSING_TOP_TRACKS,
  PROCESSED_TOP_TRACKS,
  STORE_TOP_TRACKS,
  FAVORITE_PLAYLIST_ID,
  TOPTRACK_PLAYLIST_ID,
} from './constants'
import api from '../api'
import config from '../config'
import { sendStats } from './stats'

import { addTracksToQueue, trackEnded } from './queue'
import { gaSender } from '../lib'

let a = null

export const timeUpdate = (payload) => ({
  type: TIME_UPDATE,
  payload,
})

export const openPlayer = () => ({
  type: OPEN_PLAYER,
})

export const closePlayer = () => ({
  type: CLOSE_PLAYER,
})

export const onChangeVolume = (payload) => ({
  type: CHANGE_VOLUME,
  payload,
})

const processingPlaylistTracks = () => ({
  type: PROCESSING_PLAYLIST_TRACKS,
})

const processedPlaylistTracks = () => ({
  type: PROCESSED_PLAYLIST_TRACKS,
})

const storePlaylistTracks = (payload) => async (dispatch) => {
  const { tracks } = payload
  dispatch({
    type: STORE_PLAYLIST_TRACKS,
    payload: {
      ...payload,
      tracks,
    },
  })
}

const processingTopTracks = () => ({
  type: PROCESSING_TOP_TRACKS,
})

const processedTopTracks = () => ({
  type: PROCESSED_TOP_TRACKS,
})

const storeTopTracks = (payload) => async (dispatch) => {
  const { tracks } = payload
  dispatch({
    type: STORE_TOP_TRACKS,
    payload: {
      ...payload,
      tracks,
    },
  })
}

export const setVolume = (vol) => async (dispatch) => {
  a.volume = vol
  dispatch(onChangeVolume({ currentVolume: vol }))
}

export const setTime = (seeked, time) => async (dispatch, getState) => {
  if (a) {
    a.currentTime = time
  }
  dispatch(timeUpdate({ currentTime: time }))

  if (seeked) {
    const {
      queue: { playlistId, playingTrackId },
      player: { currentTime },
    } = getState()
    dispatch(sendStats('resume', playingTrackId, playlistId, currentTime))
  }
}

export const updateSeekTime = () => async (dispatch) => {
  if (a) {
    dispatch(timeUpdate({ currentTime: Math.floor(a.currentTime) }))
  }
}
export const playTrack = (
  track,
  pPlaylistId,
  trackIndex,
  queueIndex = null,
  territoryId = null,
) => async (
  dispatch,
  getState,
) => {
  const {
    queue: { playlistId: queuePlaylistId },
    favorite: { tracks: favoriteTracks },
    playlists: { tracks: playlistTrack },
    toptracks: { topTracks },
  } = getState()
  const {
    user,
  } = getState()

  let tracks = []
  switch (pPlaylistId) {
    case FAVORITE_PLAYLIST_ID:
      tracks = favoriteTracks
      break
    case TOPTRACK_PLAYLIST_ID:
      tracks = topTracks[territoryId]
      break
    default:
      localStorage.setItem('type', null)
      tracks = playlistTrack[pPlaylistId]
  }

  if (pPlaylistId !== queuePlaylistId) {
    await dispatch(addTracksToQueue(pPlaylistId, tracks.length, trackIndex))
  }

  gaSender('stream_track', track.id, track.title)

  const { queue: { tracksIndexes } } = getState()

  dispatch({
    type: PLAY_TRACK,
    payload: {
      pPlaylistId,
      playingTrackId: track.id,
      trackIndex,
      territoryId,
      queueIndex: queueIndex || tracksIndexes.findIndex((e) => e === trackIndex),
    },
  })

  const {
    queue: { playlistId, playingTrackId },
    player: { currentTime },
  } = getState()

  if (a !== null) {
    a.pause()
  }
  a = new Audio()
  const hlsPlayer = new Hls()
  const src = `${config.API_BASE_URL}/tracks/${track.id}/hls?authorization=${user.authUser.accessToken}`
  if (Hls.isSupported()) {
    hlsPlayer.loadSource(src)
    hlsPlayer.attachMedia(a)
    hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
      a.play()
    })
  } else if (a.canPlayType('application/vnd.apple.mpegurl')) {
    a.src = src
    a.play()
  }
  a.addEventListener('ended', () => {
    const {
      player: { currentTime: endCurrentTime },
    } = getState()
    dispatch(sendStats('completed', playingTrackId, playlistId, endCurrentTime))
    dispatch(trackEnded())
  })

  dispatch(sendStats('started', playingTrackId, playlistId, currentTime))
}

export const playFirstTrackFromPlaylist = (pPlaylistId, type = null, queueIndex = null) => async (
  dispatch,
  getState,
) => {
  const {
    queue: { playlistId: queuePlaylistId },
  } = getState()

  const {
    user,
  } = getState()

  let tracks = []
  switch (type) {
    case 'territory':
      localStorage.setItem('type', type)
      dispatch(processingTopTracks())
      tracks = (await api.fetchTopTracks(pPlaylistId)).items
      if (tracks) {
        await dispatch(storeTopTracks({
          territoryId: pPlaylistId,
          tracks,
        }))
      }
      dispatch(processedTopTracks())
      break
    default:
      localStorage.setItem('type', null)
      dispatch(processingPlaylistTracks())
      tracks = (await api.fetchPlaylistTracks(pPlaylistId)).items
      await dispatch(storePlaylistTracks({
        playlistId: pPlaylistId,
        tracks,
      }))
      dispatch(processedPlaylistTracks())
  }
  const trackIndex = 0
  if (pPlaylistId !== queuePlaylistId) {
    await dispatch(addTracksToQueue(pPlaylistId, tracks.length, trackIndex))
  }
  const { queue: { tracksIndexes } } = getState()

  if (tracks.length > 0) {
    dispatch({
      type: PLAY_TRACK,
      payload: {
        pPlaylistId,
        playingTrackId: tracks[0].id,
        trackIndex,
        queueIndex: queueIndex || tracksIndexes.findIndex((e) => e === trackIndex),
      },
    })

    const {
      queue: { playlistId, playingTrackId },
      player: { currentTime },
    } = getState()

    if (a !== null) {
      a.pause()
    }

    if (a !== null) {
      a.pause()
    }

    a = new Audio()
    const hlsPlayer = new Hls()
    const src = `${config.API_BASE_URL}/tracks/${tracks[0].id}/hls?authorization=${user.authUser.accessToken}`
    if (Hls.isSupported()) {
      hlsPlayer.loadSource(src)
      hlsPlayer.attachMedia(a)
      hlsPlayer.on(Hls.Events.MANIFEST_PARSED, () => {
        a.play()
      })
    } else if (a.canPlayType('application/vnd.apple.mpegurl')) {
      a.src = src
      a.play()
    }
    a.addEventListener('ended', () => {
      const {
        player: { currentTime: endCurrentTime },
      } = getState()
      dispatch(sendStats('completed', playingTrackId, playlistId, endCurrentTime))
      dispatch(trackEnded())
    })

    dispatch(sendStats('started', playingTrackId, playlistId, currentTime))
  } else if (a !== null) {
    a.pause()
    a.currentTime = 0
  }
}

export const resumeTrack = () => async (dispatch, getState) => {
  a.play()
  dispatch({
    type: RESUME_TRACK,
  })

  const {
    queue: { playlistId, playingTrackId },
    player: { currentTime },
  } = getState()
  dispatch(sendStats('resume', playingTrackId, playlistId, currentTime))
}

export const pauseTrack = () => async (dispatch, getState) => {
  if (a) {
    a.pause()
  }
  dispatch({
    type: PAUSE_TRACK,
  })

  const {
    queue: { playlistId, playingTrackId },
    player: { currentTime },
  } = getState()
  dispatch(sendStats('paused', playingTrackId, playlistId, currentTime))
}
