import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { history, gaSender } from '../../lib'
import { updateUserGender } from '../../actions'
import { OnBoardingSkipButton } from '../../components'

const OnBoardingGender = () => {
  const { t } = useTranslation('onboarding')
  const dispatch = useDispatch()
  const gender = useSelector((state) => state.onboarding.gender)

  const woman = { gender: 'woman' }
  const man = { gender: 'man' }
  const noAnswer = { gender: 'noAnswer' }

  const updateGender = (async (selectedGenre) => {
    try {
      await dispatch(updateUserGender(selectedGenre))
      history.push('./onboardingbirth')
    } catch (e) {
      console.log(e)
    }
  })

  React.useEffect(() => {
    gaSender('page_onboarding_gender')
  })

  return (
    <div className="container_onboarding_gender">
      <div className="bg-onboarding-gender">
        <OnBoardingSkipButton />
        <div className="onboarding-gender-content">
          <h1>{t('titlegender')}</h1>
          <div className="gender-desktop">
            <button
              type="button"
              className="btn-gender"
              onClick={() => updateGender(woman)}
            >
              <img
                style={gender === 'woman' ? {
                  borderColor: 'green' } : { borderColor: 'transparent' }}
                src="/assets/images/girl-desktop.png"
                alt={t('womangender')}
                className="gender"
              />
              <p>{t('womangender')}</p>
            </button>
            <button
              type="button"
              className="btn-gender"
              onClick={() => updateGender(man)}
            >
              <img
                style={gender === 'man' ? {
                  borderColor: 'green' } : { borderColor: 'transparent' }}
                src="/assets/images/boy-desktop.png"
                alt={t('mangender')}
                className="gender"
              />
              <p>{t('mangender')}</p>
            </button>
          </div>
          <button
            type="button"
            className="noanswer"
            onClick={() => {
              updateGender(noAnswer)
              history.push('./onboardingbirth')
            }}
          >
            <p style={gender === 'noAnswer' ? {
              color: 'green' } : { color: 'white' }}
            >
              {t('noanswergender')}
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}
export default OnBoardingGender
