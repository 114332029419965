import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import PropTypes from 'prop-types'
import { MainMenu, ArtistGrid, ArtistHeader } from '../../components'
import { fetchArtist, fetchPlaylistsArtist } from '../../actions'

const ArtistPage = (props) => {
  const { match } = props
  const artistPageId = match.params.id
  const dispatch = useDispatch()
  const playlistsArtist = useSelector((state) => state.articles.playlistsArtist)
  const artist = useSelector((state) => state.articles.artist)

  React.useEffect(() => {
    dispatch(fetchArtist(artistPageId))
    dispatch(fetchPlaylistsArtist(artistPageId))
  }, [dispatch, match, artistPageId])

  return (
    <div className="container-page playlist">
      {/* main content */}
      <article className="main_content">
        <ArtistHeader match={match} artist={artist} />
        <ArtistGrid playlists={playlistsArtist} />
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
    </div>
  )
}

ArtistPage.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
}

export default ArtistPage
