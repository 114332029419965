import api from '../api'
import {
  UPDATE_ONBOARDING_VALUE,
  ON_BOARDING_SKIP,
  ON_BOARDING_GENDER,
  ON_BOARDING_YEAR,
  ON_BOARDING_RELAUNCH,
  ON_BOARDING_GENRE,
  ON_BOARDING_MOOD,
  HAS_ONBOARDED,
  STORE_ONBOARDING_GENRES,
  STORE_ONBOARDING_MOODS,
  HIDE_ONBOARDING_VALUE,
} from './constants'
import { history } from '../lib'

export const updateOnBoardingValue = (start) => (dispatch) => {
  dispatch({
    type: UPDATE_ONBOARDING_VALUE,
    payload: {
      start,
    },
  })
}

export const updateHasOnBoarded = () => async (dispatch) => {
  try {
    const {
      has_onboarded: hasOnboarded,
    } = await api.updateHasOnBoarded()
    dispatch({
      type: HAS_ONBOARDED,
      payload: {
        hasOnboarded,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export const getUserGender = () => async (dispatch) => {
  try {
    const response = await api.getUserGender()
    const { gender } = response.metadata
    dispatch({
      type: ON_BOARDING_GENDER,
      payload: {
        gender,
      },
    })
  } catch (e) {
    throw e
  }
}

export const updateUserGender = (gender) => async () => {
  try {
    const {
      updated_rows_count: updatedRowsCount,
    } = await api.updateUserGender(gender)
    return updatedRowsCount
  } catch (e) {
    console.error(e)
  }
}

export const getUserBirth = () => async (dispatch) => {
  try {
    const response = await api.getUserBirth()
    const { birthdate } = response.metadata
    dispatch({
      type: ON_BOARDING_YEAR,
      payload: {
        birthdate,
      },
    })
  } catch (e) {
    throw e
  }
}

export const updateUserBirth = (birthdate) => async () => {
  try {
    await api.updateUserBirth(birthdate)
  } catch (e) {
    console.error(e)
  }
}

export const getFavoriteGenres = () => async (dispatch) => {
  try {
    const response = await api.getFavoriteGenres()
    const genres = response.items.map((item) => item.id)
    dispatch({
      type: ON_BOARDING_GENRE,
      payload: {
        genres,
      },
    })
  } catch (e) {
    throw e
  }
}

export const updateFavoriteGenre = (genre) => async () => {
  try {
    await api.updateFavoriteGenre(genre)
  } catch (e) {
    console.error(e)
  }
}

export const getFavoriteMoods = () => async (dispatch) => {
  try {
    const response = await api.getFavoriteMoods()
    const mood = response.items.map((item) => item.id)
    dispatch({
      type: ON_BOARDING_MOOD,
      payload: {
        mood,
      },
    })
  } catch (e) {
    throw e
  }
}

export const updateFavoriteMood = (mood) => async () => {
  try {
    await api.updateFavoriteMood(mood)
  } catch (e) {
    console.error(e)
  }
}

export const skipOnBoarding = () => (dispatch) => {
  dispatch({
    type: ON_BOARDING_SKIP,
  })
  dispatch(updateOnBoardingValue(true))
  history.push('/')
}

export const relaunchOnBoarding = () => async (dispatch) => {
  dispatch({
    type: ON_BOARDING_RELAUNCH,
  })
  await dispatch(getUserGender())
  await dispatch(getUserBirth())
  await dispatch(getFavoriteGenres())
  await dispatch(getFavoriteMoods())
}

export const fetchOnboardingGenres = () => async (dispatch, getState) => {
  try {
    const { CATEGORIES } = getState().config
    const { items: genres } = await api.fetchCategories(CATEGORIES.genre, 0)
    dispatch({
      type: STORE_ONBOARDING_GENRES,
      payload: {
        genres,
      },
    })
  } catch (e) {
    console.error(e)
  }
}
export const fetchOnboardingMoods = () => async (dispatch, getState) => {
  try {
    const { CATEGORIES } = getState().config
    const { items: moods } = await api.fetchCategories(CATEGORIES.mood, 0)
    dispatch({
      type: STORE_ONBOARDING_MOODS,
      payload: {
        moods,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export const hideOnBoarding = (hideMyMusicProfil) => (dispatch) => {
  dispatch({
    type: HIDE_ONBOARDING_VALUE,
    payload: {
      hideMyMusicProfil,
    },
  })
}
