import dayjs from 'dayjs'
import i18next from 'i18next'
import { jwtDecode } from 'jwt-decode'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { autoLoginWithSubscriptionId, chooseProject, fetchProject, getUnitedProjects } from '../../actions'
import { ModalRefreshSubscription } from '../../components'
import Spinner from '../../components/Spinner'
import config from '../../config'
import { history } from '../../lib'
import './styles/index.scss'

const AccessPortal = () => {
  const { type } = useParams()
  const ods = type && type.includes('ods')

  const dispatch = useDispatch()
  const stateOnboarding = useSelector((state) => state.onboarding)
  const useQuery = () => new URLSearchParams(useLocation().search)
  const query = useQuery()
  const { t } = useTranslation('accessPortal')

  const [pending, setPending] = useState(false)

  const [subscriptionId] = useState(query.get('subscription_id') ? query.get('subscription_id') : null)
  const [loginUser, setLoginUser] = useState()
  const [expSub, setExpSub] = useState()
  const [isShowingModalRefreshSubscription, setIsShowingModalRefreshSubscription] = useState(false)

  const autoLog = useCallback(
    async () => {
      await dispatch(autoLoginWithSubscriptionId(subscriptionId))
      if (localStorage.getItem('user')) {
        const subscription = JSON.parse(localStorage.getItem('user'))?.subscription
        const exp = subscription?.end_date
        if (!exp) {
          setIsShowingModalRefreshSubscription(true)
        }
        const login = JSON.parse(localStorage.getItem('user'))?.login
        if (!login) {
          setIsShowingModalRefreshSubscription(true)
        }
        if (localStorage.getItem('lang') === 'fr') {
          const langFormat = dayjs(exp).format('DD/MM/YYYY')
          setExpSub(langFormat)
        } else {
          const langFormat = dayjs(exp).format('MM/DD/YYYY')
          setExpSub(langFormat)
        }
        setLoginUser(login)
      } else {
        setIsShowingModalRefreshSubscription(true)
      }
    },
    [dispatch, subscriptionId],
  )

  const getLanguage = () => i18next.language || window.localStorage.i18nextLng

  useLayoutEffect(() => {
    if (query.get('lang')) {
      const lang = query.get('lang')
      i18next.changeLanguage(lang)
      localStorage.setItem('lang', lang)
    } else {
      const lang = getLanguage()?.split('-')[0]
      i18next.changeLanguage(lang)
      localStorage.setItem('lang', lang)
    }

    if (subscriptionId) {
      autoLog()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLog, subscriptionId])

  const connectPwa = () => {
    if (localStorage.getItem('user')) {
      setPending(true)
      const access = JSON.parse(localStorage.getItem('user'))?.accessToken
      const { project_id: id } = jwtDecode(access)
      dispatch(chooseProject(id, true))
      dispatch(fetchProject())
      dispatch(getUnitedProjects())
      if (stateOnboarding.hasOnboarded) {
        history.push('/')
      }
      if (stateOnboarding.start === null && !stateOnboarding.hasOnboarded) {
        history.push('/onboardingwelcome')
      }
      if (stateOnboarding.start && !stateOnboarding.hasOnboarded) {
        history.push('/onboardinggender')
      }
    } else {
      history.push('/selectcountry')
    }
  }

  if (pending) {
    return (<Spinner message={t('connection')} />)
  }
  return (
    <div className="containerAccess">
      <img className="containerAccess__imagelogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
      <img className="containerAccess__imagelogomobile" width={70} src={`${config.REACT_APP_PREFIX}./iconDigster192.png`} alt="logo" />
      <h1 className="containerAccess__info--title">{t('title')}</h1>
      {ods ? (
        <section className="containerAccess__info">

          <p>
            {`${t('dateEndSub')} `}
            {expSub && (
            <span className="containerAccess__info--exp">
              {expSub}
            </span>
            )}
          </p>

          <p>
            {`${t('info')} `}
          </p>
          <div className="containerAccess__info--user">

            <p>
              {`${t('passwordODS')}`}
            </p>
          </div>

          <p>{t('pwa')}</p>
          <button className="containerAccess__linkPwa" onClick={() => connectPwa()} type="button">{t('textBtn')}</button>
          <p>{t('download')}</p>
          <div className="containerAccess__storeBtn">
            <a title="apple store" href={`https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&isi=1520638158&ibi=com.uol.digster.un`}>
              <img src={`${config.REACT_APP_PREFIX}./app-store-badge_${localStorage.getItem('lang')}.png`} alt="logo" />
            </a>
            <a title="google play store" href={`https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&apn=uol.digster`}>
              <img src={`${config.REACT_APP_PREFIX}./google-play-badge_${localStorage.getItem('lang')}.png`} alt="logo" />
            </a>
          </div>
        </section>
      ) : (
        <section className="containerAccess__info">

          <p>
            {`${t('dateEndSub')} `}
            {expSub && (
            <span className="containerAccess__info--exp">
              {expSub}
            </span>
            )}
          </p>

          <p>
            {`${t('info')} `}
          </p>
          <div className="containerAccess__info--user">
            <p>
              {`${t('login')}`}
              {loginUser && (
              <span className="containerAccess__info--login">
                {loginUser}
              </span>
              )}
            </p>
            <p>
              {`${t('password')}`}
            </p>
          </div>

          <p>{t('pwa')}</p>
          <button className="containerAccess__linkPwa" onClick={() => connectPwa()} type="button">{t('textBtn')}</button>
          <p>{t('download')}</p>
          <div className="containerAccess__storeBtn">
            <a title="apple store" href={`https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&isi=1520638158&ibi=com.uol.digster.un`}>
              <img src={`${config.REACT_APP_PREFIX}./app-store-badge_${localStorage.getItem('lang')}.png`} alt="logo" />
            </a>
            <a title="google play store" href={`https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&apn=uol.digster`}>
              <img src={`${config.REACT_APP_PREFIX}./google-play-badge_${localStorage.getItem('lang')}.png`} alt="logo" />
            </a>
          </div>
        </section>
      )}
      {isShowingModalRefreshSubscription && (
      <ModalRefreshSubscription />
      )}
    </div>
  )
}

export default AccessPortal
