import React from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'

const Maintenance = () => {
  const { t } = useTranslation('maintenance')

  return (
    <div className="bg-container">
      <div className="bg-maintenance">
        <div className="maintenance-content">
          <img alt="logo" src="/assets/images/logo_digster.png" />
          <p>{t('title')}</p>
        </div>
      </div>
    </div>
  )
}
export default Maintenance
