import { UPDATE_TUTORIAL_VALUE } from './constants'

export const updateTutorialValue = (start) => (dispatch) => {
  dispatch({
    type: UPDATE_TUTORIAL_VALUE,
    payload: {
      start,
    },
  })
}
