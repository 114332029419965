const BASE_URL = {
  dev: 'https://digster-infra-dev.api-umf.com',
  uat: 'https://digster-infra-uat.api-umf.com',
  prod: 'https://digster-infra-prod.api-umf.com',
}

const BASE_SHARE_URL = {
  dev: 'http://preprod.digster-sharing.com',
  uat: 'http://preprod.digster-sharing.com',
  prod: 'http://music.digster-sharing.com',
}

const BASE_API_KEY_ID = {
  dev: '3f61382a-fcff-466f-ab74-f817bcffa7b1',
  uat: '0833e585-0e84-4eb1-b731-b4ec22679a62',
  prod: 'adf7ed41-f0e6-48cf-b31b-90498f768abb',
}

const { REACT_APP_INFRA = 'dev', REACT_APP_PREFIX = '' } = process.env

const CAT = {
  dev: { mood: 2, genre: 1, decade: 3 },
  uat: { mood: 2, genre: 1, decade: 3 },
  prod: { mood: 2, genre: 1, decade: 3 },
}

const API_BASE_URL = BASE_URL[REACT_APP_INFRA]
const SHARE_URL = BASE_SHARE_URL[REACT_APP_INFRA]
const API_KEY_ID = BASE_API_KEY_ID[REACT_APP_INFRA]
const CATEGORIES = CAT[REACT_APP_INFRA]
const VOUCHERPOPIN = 2 // Configuration of the number of days remaining before the popin appears, set up at 2days
const VOUCHERPOPINAGAIN = 30 // Configuration of the number of minutes between two popin, set up at 30mins

export default {
  API_KEY_ID,
  API_BASE_URL,
  SHARE_URL,
  REACT_APP_PREFIX,
  CATEGORIES,
  VOUCHERPOPIN,
  VOUCHERPOPINAGAIN,
}
