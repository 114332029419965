import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles/index.scss'
import i18n from 'i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper/core'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { gaSender } from '../../lib'

SwiperCore.use([Pagination, Navigation])

const Tutorial = () => {
  const { t } = useTranslation('tutorial')
  const [overlayHeight, setOverlayHeight] = useState(1)
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng
  const lang = getLanguage()?.split('-')[0]

  const imageSourceMobile = (tutoNb) => {
    const url = `/assets/tutorial/mobile/${tutoNb}-mobil-${lang}.png`
    return url
  }

  const imageSourceTablet = (tutoNb) => {
    const url = `/assets/tutorial/tablet/${tutoNb}-tab-${lang}.png`
    return url
  }

  const imageSourceDesktop = (tutoNb) => {
    const url = `/assets/tutorial/desktop/${tutoNb}-desktop-${lang}.png`
    return url
  }

  const buttonPrevSlide = () => (
    <div className="swiper-btn swiper-button-prev">
      <img src="/assets/picto_fleche_retour.svg" alt="next slide of tutorial" />
    </div>
  )

  const buttonNextSlide = () => (
    <div className="swiper-btn swiper-button-next">
      <img src="/assets/picto_fleche_go.svg" alt="next slide of tutorial" />
    </div>
  )

  const scrollHandler = () => {
    setOverlayHeight(window.document.activeElement.clientHeight - window.innerHeight)
  }

  useEffect(() => {
    gaSender('page_tutorial')
    scrollHandler()
    function watchScroll() {
      window.addEventListener('resize', scrollHandler)
    }
    watchScroll()
    return () => {
      window.removeEventListener('resize', scrollHandler)
    }
  }, [])

  return (
    <Swiper
      pagination={true}
      className="mySwiper"
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
    >
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle1')}</h2>
            <p>{t('tutorialdesc1')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto1')}
              alt="tuto1"
            />
            <img
              className="picto"
              src="/assets/tutorial/tuto-hand.png"
              alt="tuto1"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto1')}
              alt="tuto1"
            />
            <img
              className="picto-tablet"
              src="/assets/tutorial/picto-tablet.png"
              alt="tuto1"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto1')}
                alt="tuto1"
              />
            </div>
            <div className="right-desktop">
              <img
                className="picto-desktop"
                src="/assets/tutorial/picto-tablet.png"
                alt="tuto1"
              />
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter1')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle2')}</h2>
            <p>{t('tutorialdesc2')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto2')}
              alt="tuto2"
            />
            <img
              className="picto2"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto2"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto2')}
              alt="tuto2"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto2"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto2')}
                alt="tuto2"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto2"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter2')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle3')}</h2>
            <p>{t('tutorialdesc3')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto3')}
              alt="tuto3"
            />
            <img
              className="picto2"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto3"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto3')}
              alt="tuto3"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto3"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto3')}
                alt="tuto3"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto3"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter3')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle3')}</h2>
            <p>{t('tutorialdesc3')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto4')}
              alt="tuto4"
            />
            <img
              className="picto2"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto4"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto4')}
              alt="tuto4"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto4"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto4')}
                alt="tuto4"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto4"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter4')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle4')}</h2>
            <p>{t('tutorialdesc4')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto5')}
              alt="tuto5"
            />
            <img
              className="picto2"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto5"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto5')}
              alt="tuto5"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto5"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto5')}
                alt="tuto5"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto5"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter5')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle5')}</h2>
            <p>{t('tutorialdesc5')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto6')}
              alt="tuto6"
            />
            <img
              className="picto3"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto6"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto6')}
              alt="tuto6"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto6"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto6')}
                alt="tuto6"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto6"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter6')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
      <SwiperSlide>
        {buttonPrevSlide()}
        <div className="tutorial-container">
          <button
            className="close-tuto"
            type="button"
            onClick={() => window.history.back()}
          >
            <p>x</p>
          </button>
          <div>
            <h2>{t('tutorialtitle6')}</h2>
            <p>{t('tutorialdesc6')}</p>
          </div>
          <div className="small-image">
            <img
              className="tuto-small"
              src={imageSourceMobile('tuto7')}
              alt="tuto7"
            />
            <img
              className="picto2"
              src="/assets/tutorial/tuto-hand-2.png"
              alt="tuto7"
            />
          </div>
          <div className="tablet-image">
            <img
              className="tuto-medium"
              src={imageSourceTablet('tuto7')}
              alt="tuto7"
            />
            <img
              className="picto-tablet1"
              src="/assets/tutorial/fleche-tuto.png"
              alt="tuto7"
            />
          </div>
          <div className="desktop-footer">
            <div className="desktop-image">
              <img
                className="tuto-desktop"
                src={imageSourceDesktop('tuto7')}
                alt="tuto7"
              />
              <img
                className="picto-desktop1"
                src="/assets/tutorial/fleche-tuto.png"
                alt="tuto7"
              />
            </div>
            <div className="right-desktop">
              <p className="linear-gradient-footer" style={overlayHeight !== 0 ? ({ marginBottom: overlayHeight }) : ({})}>{t('tutorialfooter7')}</p>
            </div>
          </div>
        </div>
        {buttonNextSlide()}
      </SwiperSlide>
    </Swiper>
  )
}

export default Tutorial
