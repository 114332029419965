import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import resources from './i18n'

const { persistor, store } = configureStore()

let navigatorLang = navigator.languages[0]
if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
  navigatorLang = 'en'
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || navigatorLang,
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  })

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
