import React, { useState, useCallback } from 'react'
import './styles/index.scss'
import 'react-phone-number-input/style.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactCodeInput from 'react-verification-code-input'
import { useTranslation } from 'react-i18next'
import { history, gaSender } from '../../lib'
import config from '../../config'
import { checkVoucher, verifyInterstitialPic, gotoNextPageAfterLogin } from '../../actions'

const VoucherValidation = () => {
  const [error, setError] = useState(false)
  const [password, setPassword] = useState('')
  const [isRequestSent, setIsRequestSent] = useState(false)
  const [showInterstitial, setShowInterstitial] = useState(false)
  const { t } = useTranslation('vouchervalidation')
  const dispatch = useDispatch()
  const voucher = useSelector((state) => state.user.voucher)
  const interstitial = useSelector((state) => state.user.interstitial)
  const picWeb = useSelector((state) => state.user.interstitialPic)
  const PROJECT_ID = localStorage.getItem('PROJECT_ID')
  const closePopin = () => {
    history.push('/')
  }

  const urlImage = () => {
    const token = interstitial.accessToken
    const setId = interstitial.id
    return `${config.API_BASE_URL}/assets/${PROJECT_ID}/vouchers/${setId}/desktop.png?authorization=${token}`
  }

  const verifyVoucherSet = useCallback(async () => {
    try {
      if (interstitial) {
        await dispatch(verifyInterstitialPic(interstitial.id, interstitial.accessToken))
      } else {
        setError(true)
      }
    } catch (e) {
      if (e.message === 'Not Found') {
        await dispatch(gotoNextPageAfterLogin(interstitial.authUser.accessToken, interstitial.authUser.refreshToken))
      }
    }
  }, [interstitial, dispatch])

  React.useEffect(() => {
    gaSender('page_authentification_validate_voucher')
  }, [])

  const onClickCallToAction = useCallback(async () => {
    try {
      if (voucher && voucher.phoneNumber && voucher.code) {
        await dispatch(checkVoucher(voucher.phoneNumber, voucher.code, password))
      } else {
        setError(true)
      }
    } catch (e) {
      if (e.message === 'E-VOUCHER-ERROR') {
        setError(t('error'))
      }
      if (e.message === 'E-INVALID-CODE') {
        setError(t('error'))
      }
      if (e.message === 'E-VOUCHER-ACTIVE') {
        setError(t('error1'))
      }
    }
  }, [dispatch, password, voucher, t])

  React.useEffect(() => {
    if (password.length === 4 && !isRequestSent) {
      setIsRequestSent(true)
      onClickCallToAction()
    } else if (password.length === 1) {
      setIsRequestSent(false)
    }
    if (voucher) {
      verifyVoucherSet()
    }
    if (picWeb) {
      setShowInterstitial(true)
      setTimeout(() => {
        closePopin()
      }, 5000)
    }
  }, [dispatch, voucher, password,
    onClickCallToAction, isRequestSent, setIsRequestSent, verifyVoucherSet, picWeb])

  return (
    <div className="container-vouchervalidation">
      <img className="imagelogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
      <Link
        className="link_return"
        to="/voucher"
      >
        <img src="/assets/fleche_retour.png" alt="return" />
      </Link>
      <h1>{t('titlevouchercode')}</h1>
      <p>{t('vouchercodetext')}</p>
      <div className="codeinput">
        <input
          type="text"
          value="FREE"
          className="free"
        />
        <span>-</span>
        <ReactCodeInput
          fields={4}
          onChange={setPassword}
        />
      </div>
      {error && (<p className="error">{error}</p>)}
      {showInterstitial && (
        <div className="popin">
          <div className="popin-content">
            <img
              src={urlImage()}
              alt="web intersticial"
            />
            <button
              className="close-btn"
              type="button"
              onClick={closePopin}
              aria-label={t('close')}
            >
              x
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
export default VoucherValidation
