import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import './styles/index.scss'
import configureStore from '../../store'

const { store } = configureStore()

const ArtistHeader = (props) => {
  const {
    user,
  } = store.getState()
  const [scrollY, setscrollY] = useState(0)
  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { match, artist } = props
  const artistPageId = match.params.id

  const artistImageSource = (artistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${artistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }
  useEffect(() => {
    setInterval(() => {
      setscrollY(window.scrollY)
    }, 500)
  }, [setscrollY])

  if (scrollY === 0) {
    return (
      <div className="artistHeader">
        <div className="text-white background__opacity sticky-top">
          <div className="flex__row">
            <img
              className="background__image"
              src={artistImageSource(artistPageId)}
              alt="slide"
            />
            <img
              className="small__image"
              src={artistImageSource(artistPageId)}
              alt="slide"
            />
            <div className="artistFlexRow">
              <button className="back__arrow" type="button" onClick={() => window.history.back()}>
                <img
                  src="/assets/fleche_retour.png"
                  alt="back arrow"
                />
              </button>
              <div className="container__description">
                <h1 className="artist__title">{artist.metadata && artist.metadata.title}</h1>
                <p className="artist__description">{artist.metadata && artist.metadata.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="playlistHeader sticky-top-sticky">
      <div className="topTracks_container">
        <div className="topTracks_content">
          <figure className="topTracks_cover">
            <img
              className="backgroundImageSticky"
              src={artistImageSource(artistPageId)}
              alt="slide"
            />
          </figure>
          <div className="topTracks_desc">
            <h1 className="playlist-title playlist-title-scroll">{artist.metadata && artist.metadata.title}</h1>
            <div className="desc_content">
              <div className="desc_text">
                <p className="text">{artist.metadata && artist.metadata.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="backArrow" id="back" type="button" onClick={() => window.history.back()}>
        <img
          src="/assets/fleche_retour.png"
          alt="back arrow"
        />
      </button>
    </div>
  )
}

ArtistHeader.defaultProps = {
  artist: {},
}
ArtistHeader.propTypes = {
  artist: PropTypes.objectOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  })),
  match: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,

}

export default ArtistHeader
