import api from '../api'
import {
  UPDATE_RGPD_CHOICE,
  UPDATE_COOKIE_CHOICE,
} from './constants'

export const updateRgpdChoice = (idOptin, value) => (dispatch) => {
  dispatch({
    type: UPDATE_RGPD_CHOICE,
    payload: {
      idOptin,
      value,
    },
  })
}

export const updateOptinRgpdChoice = () => async () => {
  try {
    await api.updateOptinRgpdChoice()
  } catch (e) {
    console.log(e)
  }
}

export const updateCookieChoice = (idOptin, value) => (dispatch) => {
  dispatch({
    type: UPDATE_COOKIE_CHOICE,
    payload: {
      idOptin,
      value,
    },
  })
}

export const updateOptinCookieChoice = () => async () => {
  try {
    await api.updateOptinCookieChoice()
  } catch (e) {
    console.log(e)
  }
}

export const getOptinChoice = () => async (dispatch) => {
  try {
    const response = await api.getOptinChoice()
    let idOptin
    let value
    response.forEach((element) => {
      idOptin = element.id
      value = element.agreed
      dispatch({
        type: UPDATE_COOKIE_CHOICE,
        payload: {
          idOptin,
          value,
        },
      })
    })
  } catch (e) {
    console.log(e)
  }
}
