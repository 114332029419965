import api from '../api'

let lastInfoDate = null
let lastStatus = null
let lastCurrentTime = null

const getCurrentDateTime = () => {
  const now = Math.floor(Date.now() / 1000)
  return now
}

const getTimeBetweenRequets = (status, currentTime) => {
  let adjusted = false
  let time = 0
  time = getCurrentDateTime() - lastInfoDate
  // In ordre to have the same currentTime and time when it is needed,
  // some times the currentTime is late by 1s, that fix this
  if (currentTime - time < 2 && !adjusted) {
    time = currentTime
    adjusted = true
  }

  if (currentTime - lastCurrentTime - time < 2 && !adjusted) {
    time = currentTime - lastCurrentTime
    adjusted = true
  }

  if (status === 'started' || status === 'resume' || (status === 'next' && lastStatus === 'paused') || (status === 'preview' && lastStatus === 'paused')) {
    time = 0
  }
  return time
}

export const sendStats = (status, id, playlistId, currentTime) => async () => {
  if (status && id !== null && playlistId !== null) {
    await api.sendStats(status, id, getCurrentDateTime(), getTimeBetweenRequets(status, currentTime), 'web', playlistId, 'online', currentTime, true, 'low')
    lastInfoDate = getCurrentDateTime()
    lastStatus = status
    lastCurrentTime = currentTime
  } else {
    console.log("error, can't send data")
  }
}
