import { jwtDecode } from 'jwt-decode'
import dayjs from 'dayjs'
import config from '../config'

export const verifyToken = (accessToken) => {
  const token = jwtDecode(accessToken)

  return {
    ...token,
    subscription: token.external ? token.external : token.subscription,
  }
}

export const getUserFromResponse = ({
  access_token: accessToken,
  refresh_token: refreshToken,
}) => {
  const token = verifyToken(accessToken)
  const ret = {
    accessToken,
    refreshToken,
    id: token.id,
    login: token.login,
    idVoucherSet: token.id_voucher_set,
    subscription: token.subscription,
  }

  return ret
}

export const refreshSession = (accessToken, refreshToken) => {
  const { id, login, subscription } = verifyToken(accessToken)
  return {
    id,
    login,
    accessToken,
    refreshToken,
    subscription,
  }
}

export const validateEmail = (email) => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])/
  return pattern.test(email)
}

export const isUserTokenExpired = (token) => {
  if (token) {
    const { exp } = jwtDecode(token)

    const expirationThreshold = 60

    const now = dayjs()

    const expiration = dayjs(exp * 1000)

    const thresholdTime = expiration.subtract(expirationThreshold, 'second')

    return now.isAfter(thresholdTime)
  }
  return true
}

export const isUserTokenExpiredOffline = (token) => {
  if (token) {
    const { subscription: { end_date: endDate } } = verifyToken(token)
    return !endDate || (dayjs().isAfter(dayjs(endDate)))
  }
  return true
}

export const isUserSubscriptionExpired = ({ subscription }) => {
  const exp = subscription.end_date
  return (
    !subscription ||
    !exp ||
    !dayjs(exp).isValid() ||
    dayjs().isAfter(dayjs(exp))
  )
}

export const mustDisplayPopInSubscription = (timeLastPopIn) => {
  const subscription = JSON.parse(localStorage.getItem('user'))?.subscription
  if (!subscription) {
    return false
  }
  const toDay = Date.parse(new Date())
  const exp = Date.parse(subscription.end_date)
  const daysPopIn = config.VOUCHERPOPIN * 86400000 // 86400000 is 1 day in timestamp
  const PopInAgain = config.VOUCHERPOPINAGAIN * 60000 // 60000 is 1 minute in timestamp
  // First check if sub end < 2days --- Second check if we saw the popin < 30mins
  return ((exp - toDay < daysPopIn) && (toDay - timeLastPopIn > PopInAgain))
}

export const getProjectNameByIdProject = (idProject, project) => {
  if (project && project.length > 1) {
    const projectName = project.find((projectCode) => projectCode.id === idProject)
    return projectName.territory[0].code
  }
}

export const getProjecIdByProjectName = (projectName, project) => {
  if (project && project.length > 1) {
    const projectId = project.find((projectCode) => projectCode.territory[0].code === projectName)
    return projectId.id
  }
}

export const getMobileOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  }
  if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }
  return 'Other'
}

export const getUrlDeeplink = () => {
  let url = 'https://digster.page.link/Accueil'
  const subscriptionId = localStorage.getItem('subId')
  if (subscriptionId) {
    if (getMobileOS() === 'iOS') {
      url = `https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&isi=1520638158&ibi=com.uol.digster.un`
    } else if (getMobileOS() === 'Android') {
      url = `https://digster.page.link/?link=https://digster-music.com/selectcountry?subscription-id%3D${subscriptionId}&apn=uol.digster`
    }
  }

  return url
}
