import dayjs from 'dayjs'
import ReactGA from 'react-ga'

export const parseTime = (sec, hourText = 'hr', minText = 'min', plural = 's') => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  if (!sec) {
    return `0${minText}`
  }
  const dayUtc = dayjs.utc(sec * 1000)
  const hours = Number(dayUtc.format('HH'))
  const min = Number(dayUtc.format('mm'))

  let formatted = ''
  if (hours > 0) {
    formatted = `${hours}${hourText}`
    if (hours > 1) {
      formatted += plural
    }
    formatted += ' '
  }

  if (min > 0) {
    formatted += `${min}${minText}`
    if (min > 1) {
      formatted += plural
    }
  }

  return formatted
}

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ''
  }
  let newPhoneNumber = ''
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i % 2 === 0) {
      newPhoneNumber += ' '
    }
    newPhoneNumber += phoneNumber[i]
  }

  return newPhoneNumber
}

export function stringToHexColor(category) {
  const { name: strToConvert, id } = category

  const filter = `digster-${strToConvert}${strToConvert}${strToConvert}${strToConvert}`
  let txt = '#'
  const knownColors = [
    {
      hex: '#e93333',
      ids: [90, 106],
    },
    {
      hex: '#ff7d5b',
      ids: [92],
    },
    {
      hex: '#ff7b00',
      ids: [11, 91, 113],
    },
    {
      hex: '#ffbc1f',
      ids: [93, 107],
    },
    {
      hex: '#70c07a',
      ids: [94, 10, 108],
    },
    {
      hex: '#009066',
      ids: [95, 115],
    },
    {
      hex: '#00c0a9',
      ids: [21, 96, 111],
    },
    {
      hex: '#008fc4',
      ids: [5, 97, 112],
    },
    {
      hex: '#1434a0',
      ids: [12, 98, 118],
    },
    {
      hex: '#47177a',
      ids: [8, 99, 117],
    },
    {
      hex: '#9049c7',
      ids: [16, 109],
    },
    {
      hex: '#d454be',
      ids: [6, 102, 110],
    },
    {
      hex: '#fe71a1',
      ids: [15, 101],
    },
    {
      hex: '#df275a',
      ids: [14, 103, 114],
    },
    {
      hex: '#b00000',
      ids: [104, 116],
    },
  ]

  knownColors.forEach((obj) => {
    if (obj.ids.includes(id)) {
      txt = obj.hex
    }
  })

  if (txt.length === 1) {
    let result = ''
    for (let i = 0; i < filter.length; i++) {
      result += filter.charCodeAt(i)
    }

    const limit = parseInt(result.length / 6, 10) + 1
    for (let j = 0; j < 6; j++) {
      let count = 0
      for (let i = 0; i < limit; i++) {
        const index = j * 6 + i
        if (index < result.length) {
          count += parseInt(result.charAt(index), 10)
        }
      }

      count %= 16

      switch (count) {
        case 10:
          txt += 'A'
          break
        case 11:
          txt += 'B'
          break
        case 12:
          txt += 'C'
          break
        case 13:
          txt += 'D'
          break
        case 14:
          txt += 'E'
          break
        case 15:
          txt += 'F'
          break
        default:
          txt += count
      }
    }
  }
  return txt
}

export function gaSender(action, value = 0, label = '') {
  const { REACT_APP_INFRA = 'dev' } = process.env
  if (REACT_APP_INFRA === 'prod') {
    console.log('analytics', action, value, label)
    ReactGA.event({
      category: 'event',
      action,
      value,
      label,
    })
  }
}
