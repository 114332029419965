import React, { useState, useEffect } from 'react'
import './styles/index.scss'
import Carousel from 'react-multi-carousel'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import 'react-multi-carousel/lib/styles.css'
import { fetchMoods, updateActiveFilter } from '../../actions'
import { stringToHexColor, gaSender } from '../../lib'
import { ButtonGroup } from '..'

const Moods = () => {
  const dispatch = useDispatch()
  const moods = useSelector((state) => state.explorer.moods)
  const [activeFiltersState, setActiveFiltersState] = useState([])
  const { t } = useTranslation('moods')
  useEffect(() => {
    dispatch(fetchMoods())
  }, [dispatch])
  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 4,
      partialVisibilityGutter: 7,
    },
    mobileXL: {
      breakpoint: {
        max: 767,
        min: 465,
      },
      items: 4,
      // items: 4.7,
      partialVisibilityGutter: 14,
    },
    tablet: {
      breakpoint: {
        max: 1023,
        min: 768,
      },
      items: 5,
      // items: 4.75,
      partialVisibilityGutter: 14,
    },
    desktopSM: {
      breakpoint: {
        max: 1439,
        min: 1024,
      },
      items: 7,
      // items: 6.75,
      partialVisibilityGutter: 14,
    },
    desktopMD: {
      breakpoint: {
        max: 1679,
        min: 1440,
      },
      items: 7,
      partialVisibilityGutter: 14,
    },
    desktopXL: {
      breakpoint: {
        max: 1999,
        min: 1680,
      },
      items: 8,
      partialVisibilityGutter: 14,
    },
    desktopXXL: {
      breakpoint: {
        max: 2560,
        min: 2000,
      },
      items: 8,
      partialVisibilityGutter: 14,
    },
  }

  return (
    <div className="container__mood slider_bloc">
      {moods && (
        <div className="slider-content">
          <div className="moods-titles-extra slider-title">
            <h2 className="title title-extra-moods">
              <Link to="/explore" className="link__title">
                <span>{t('title')}</span>
                <img src="/assets/picto_fleche_go.svg" alt={t('seeall')} />
              </Link>
            </h2>
            <Link
              className="button__seemore"
              to={{ pathname: '/explore', state: { moodCat: true } }}
              onClick={() => {
                gaSender('action_mood_see_all')
              }}
            >
              <text>{t('more')}</text>
            </Link>
          </div>
          <div className="slider-body">
            <Carousel
              arrows={false}
              keyBoardControl
              partialVisible
              additionalTransfrom={0}
              responsive={responsive}
              slidesToSlide={1}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {moods.map((filter) => (
                <div className="slider_item">
                  <Link
                    className="mood-filter"
                    to="/explore"
                    key={`currentcat${filter.id}`}
                    style={{ backgroundColor: stringToHexColor(filter) }}
                    onClick={() => {
                      const index = activeFiltersState.indexOf(filter.id)
                      const filters = [
                        ...activeFiltersState,
                      ]
                      if (index !== -1) {
                        filters.splice(index, 1)
                      } else {
                        filters.push(filter.id)
                      }
                      setActiveFiltersState(filters)
                      dispatch(updateActiveFilter(filters))
                    }}
                  >
                    <p className="picto">
                      <img
                        src={`/assets/moods/picto_${filter.id}.png`}
                        alt="moods"
                        className="mood-picto"
                      />
                    </p>
                    <p className="text">{filter.name}</p>
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  )
}

export default Moods
