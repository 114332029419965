import {
  PLAY_TRACK,
  RESUME_TRACK,
  PAUSE_TRACK,
  PLAYER_STATUS_IDLE,
  PLAYER_STATUS_PLAYING,
  PLAYER_STATUS_PAUSED,
  TIME_UPDATE,
  SEEK_TO,
  QUEUE_ENDED,
  OPEN_PLAYER,
  CLOSE_PLAYER,
  LOGOUT,
  CHANGE_VOLUME,
} from '../actions'

const initialState = {
  status: PLAYER_STATUS_IDLE,
  currentTime: 0,
  totalTime: 0,
  opened: false,
  currentVolume: 0.5,
}

export default (state = initialState, action = {}) => {
  const { payload } = action
  switch (action.type) {
    case PLAY_TRACK:
      return {
        ...state,
        currentTime: 0,
        totalTime: 0,
        status: PLAYER_STATUS_PLAYING,
      }
    case RESUME_TRACK:
      return {
        ...state,
        status: PLAYER_STATUS_PLAYING, // FIXME: to remove
      }
    case PAUSE_TRACK:
      return {
        ...state,
        status: PLAYER_STATUS_PAUSED, // FIXME: to remove
      }
    case TIME_UPDATE:
      return {
        ...state,
        ...payload,
      }
    case QUEUE_ENDED:
      return {
        ...state,
        status: PLAYER_STATUS_IDLE,
        currentTime: 0,
        totalTime: 0,
      }
    case SEEK_TO:
      return {
        ...state,
        currentTime: payload.time,
      }
    case OPEN_PLAYER:
      return {
        ...state,
        opened: true,
      }
    case CLOSE_PLAYER:
      return {
        ...state,
        opened: false,
      }
    case CHANGE_VOLUME:
      return {
        ...state,
        ...payload,
      }
    case LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
