import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { playFirstTrackFromPlaylist } from '../../actions'
import config from '../../config'
import configureStore from '../../store'

const { store } = configureStore()

const ArtistGrid = (props) => {
  const {
    user,
  } = store.getState()
  const dispatch = useDispatch()
  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { playlists } = props
  const { t } = useTranslation('artistgrid')

  const playlistImageSource = (playlistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const btnPlay = (playlistId) => {
    dispatch(playFirstTrackFromPlaylist(playlistId))
  }

  return (
    <div className="container__artistgrid">
      <div className="container__playlists" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.keys(playlists).map((playlist) => (
          <div key={`item-${playlists[playlist].id}`} className="text-white container__grid" style={{ position: 'relative', margin: '30px 20px 0 0' }}>
            <a key={`ref-${playlists[playlist].id}`} href={`${config.REACT_APP_PREFIX}/playlist/${playlists[playlist].id}`}>
              <img
                className="d-block mr-3"
                src={playlistImageSource(playlists[playlist].id)}
                alt="slide"
              />
            </a>
            <div className="container__desc">
              <div>
                <p className="title">
                  {playlists[playlist] && playlists[playlist].first_item && playlists[playlist].first_item.title}
                </p>
                <p className="desc">{t('desc')}</p>
              </div>
              <div className="container-play">
                <button type="button" className="btn-play" onClick={() => btnPlay(playlists[playlist].id)}>
                  <img src="/assets/picto_player_little.svg" alt="button play" className="image-button" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

ArtistGrid.propTypes = {
  playlists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    first_item: PropTypes.string,
  })).isRequired,
}

export default ArtistGrid
