import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import { history, stringToHexColor, gaSender } from '../../lib'
import { updateFavoriteGenre, fetchOnboardingGenres, getLanguage } from '../../actions'
import { OnBoardingSkipButton } from '../../components'

const OnBoardingGenres = () => {
  const { t } = useTranslation('onboarding')
  const dispatch = useDispatch()
  const genres = useSelector((state) => state.onboarding.genres)
  const favoriteGenre = useSelector((state) => state.onboarding.favoriteGenre)
  const [activeFiltersState, setActiveFiltersState] = useState(
    favoriteGenre.length === 3 ? favoriteGenre : [],
  )

  const toggleFilter = (filter) => {
    const index = activeFiltersState.indexOf(filter.id)
    const filters = [
      ...activeFiltersState,
    ]
    if (index !== -1) {
      filters.splice(index, 1)
    } else {
      filters.push(filter.id)
    }
    setActiveFiltersState(filters)
    if (filters.length > 3) {
      filters.splice(index, 1)
    }
  }

  useEffect(() => {
    gaSender('page_onboarding_genre')
    dispatch(getLanguage())
    dispatch(fetchOnboardingGenres())
  }, [dispatch])

  return (
    <div className="container_onboarding_genres">
      <div className="bg-onboarding-genres">
        <OnBoardingSkipButton />
        <div className="onboarding-genres-content">
          <h1>{t('titlegenres')}</h1>
          <p>{t('choice')}</p>
          <div className="active-filters">
            <div className="filter-choice">
              {activeFiltersState.map((filter) => {
                let filterFound = {}
                genres.forEach((genre) => {
                  if (genre.id === filter) {
                    filterFound = genre
                  }
                })

                return (
                  <div key={`activefilters${filter}`}>
                    <button
                      type="button"
                      style={{ backgroundColor: stringToHexColor(filterFound) }}
                      onClick={() => {
                        toggleFilter(filterFound)
                      }}
                    >
                      <p>{filterFound.name}</p>
                      <p>x</p>
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="genre">
            {genres.map((filter) => (
              <button
                type="button"
                style={{
                  opacity: activeFiltersState.indexOf(filter.id) !== -1 ? 1 : 0.75,
                  backgroundColor: stringToHexColor(filter),
                }}
                key={`currentcat${filter.id}`}
                onClick={() => toggleFilter(filter)}
              >
                {filter.name}
              </button>
            ))}
          </div>
          <div>
            {activeFiltersState.length > 2 && (
              <button
                type="button"
                className="btn-genres"
                onClick={() => {
                  dispatch(updateFavoriteGenre(activeFiltersState))
                  history.push('./onboardingmoods')
                }}
              >
                {t('onboardingvalidate')}

              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default OnBoardingGenres
