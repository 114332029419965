import React from 'react'
import PropTypes from 'prop-types'
import './styles/index.scss'
import { useDispatch } from 'react-redux'
import { playFirstTrackFromPlaylist } from '../../actions'
import config from '../../config'
import configureStore from '../../store'

const { store } = configureStore()

const OnlyForYouGrid = (props) => {
  const {
    user,
  } = store.getState()
  const lang = localStorage.getItem('lang')
  const { playlists } = props

  const playlistImageSource = (playlistId) => {
    let apiPath = ''
    try {
      apiPath = `lists/${playlistId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }
  const dispatch = useDispatch()

  const btnPlay = (playlistId) => {
    dispatch(playFirstTrackFromPlaylist(playlistId))
  }

  return (
    <div className="d-flex flex-wrap mt-2 p-3 onlyForYou">
      {playlists.map((playlist) => (
        <div key={playlist.id} className="text-white">
          <a key={playlist.id} href={`${config.REACT_APP_PREFIX}/playlist/${playlist.id}`}>
            <img
              className="d-block mr-3"
              src={playlistImageSource(playlist.id)}
              alt="slide"
            />
          </a>
          {playlist && playlist.metadata && (
            <div className="container-description">
              <div className="content-description">
                <p className="title">
                  {playlist.metadata.title}
                </p>
                <p className="description">
                  {playlist.metadata.description}
                </p>
              </div>
              <div className="container-play">
                <button type="button" className="btn-play" onClick={() => btnPlay(playlist.id)}>
                  <img alt="button play" src="/assets/picto_player_little.svg" className="image-button" />
                </button>
              </div>
            </div>
          )}

        </div>
      ))}
    </div>
  )
}

OnlyForYouGrid.propTypes = {
  playlists: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default OnlyForYouGrid
