import React from 'react'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import config from '../../config'

const OverlayPortaitWarning = () => {
  const { t } = useTranslation('overlayportraitwarning')

  return (
    <main className="overlay_container">
      <div className="overlay_text_container">
        <img className="imagelogo overlaywarninglogo" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="" />
        <p>
          {t('warning')}
        </p>
      </div>
    </main>

  )
}

export default OverlayPortaitWarning
