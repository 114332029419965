import React, { useEffect } from 'react'
import './styles/index.scss'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { MainMenu, TracksList, PlaylistHeader } from '../../components'
import { fetchTopTracks, fetchProject } from '../../actions'
import configureStore from '../../store'
import config from '../../config'
import { gaSender } from '../../lib'

const { store } = configureStore()

const TopTracks = (props) => {
  const dispatch = useDispatch()
  const {
    user,
  } = store.getState()
  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  const { match } = props
  const territoryId = match.params.id
  const tracks = useSelector((state) => state.toptracks.topTracks)
  const projects = useSelector((state) => state.project.projects)
  const project = projects.find((p) => p.id.toString() === territoryId)
  const queue = useSelector((state) => state.queue.playlistId)

  useEffect(() => {
    dispatch(fetchProject(territoryId))
    dispatch(fetchTopTracks(territoryId))
  }, [dispatch, match, territoryId])

  useEffect(() => {
    gaSender('page_top_tracks_screen')
  }, [])

  const getProjectCover = () => {
    let apiPath = ''
    try {
      apiPath = `assets/${territoryId}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/country.jpg`
  }

  const getProjectFlag = () => {
    let apiPath = ''
    try {
      apiPath = `assets/${territoryId}/flags`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.ori`
  }

  return (
    <div className={`container-page playlist ${(queue !== null) ? 'container-page-with-player' : ''}`}>
      {/* main content */}
      <article className="main_content">
        <div className="bgMobile">
          <img src={getProjectCover()} alt="bgMobile" />
        </div>
        <div className="main-playlist">
          <div className="content_playlist">
            {project && tracks && (
              <PlaylistHeader
                getProjectCover={getProjectCover}
                getProjectFlag={getProjectFlag}
                isTopTracks={true}
                project={project}
                territory={territoryId}
                tracks={tracks[territoryId]}
              />
            )}
            <div className="body-list-group">
              {tracks && (
                <TracksList
                  territoryTracks={tracks[territoryId]}
                  territory={territoryId}
                  user={user}
                  lang={lang}
                  isTopTracks={true}
                />
              )}
            </div>
          </div>
        </div>
      </article>
      {/* menu */}
      <aside className="main_menu">
        <MainMenu />
      </aside>
    </div>
  )
}

TopTracks.propTypes = {
  match: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
}

export default TopTracks
