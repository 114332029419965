import { getUserFromResponse } from '../lib'
import {
  REFRESH_TOKEN_SUCCESS,
} from './constants'
import { logout } from './user'

export const refreshTokenSuccess = (accessToken, refreshToken) => {
  const user = getUserFromResponse({
    access_token: accessToken,
    refresh_token: refreshToken,
  })
  localStorage.setItem('user', JSON.stringify(user))
  localStorage.setItem('refreshToken', user.refreshToken)
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: {
      user,
    },
  }
}

export const refreshTokenFailed = () =>
  async (dispatch) => {
    await dispatch(logout(true))
  }
