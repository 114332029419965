import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import api from '../api'
import reducer from '../reducers'

const persistConfig = {
  key: 'digster',
  storage,
  whitelist: ['onboarding', 'project', 'config', 'rgpd', 'tutorial', 'voucherPopIn'],
}

const persistedReducer = persistReducer(persistConfig, reducer)

const store = createStore(persistedReducer, applyMiddleware(thunkMiddleware))
const persistor = persistStore(store)

api.setStore(store)

export default () => ({
  store,
  persistor,
})
