import React, { useState, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import './styles/index.scss'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-multi-carousel'
import dayjs from 'dayjs'
import config from '../../config'
import { history } from '../../lib'
import { Seeker } from '../../components'
import {
  skipNextTrack, skipPreviousTrack, addToFavorite, removeFromFavorite, toggleRepeat, setTime,
  toggleShuffle, pauseTrack, resumeTrack, playTrack, sendStats,
} from '../../actions'

const AudioPlayer = (props) => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const { isMyFavorites } = props
  const { t } = useTranslation('player')
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const player = useSelector((state) => state.player)
  const queue = useSelector((state) => state.queue)
  const tracks = useSelector((state) => state.playlists.tracks)
  const playlist = useSelector((state) => state.playlists.playlists[queue.playlistId])
  const favoriteTracks = useSelector((state) => state.favorite.tracks)
  const topTracks = useSelector((state) => state.toptracks.topTracks)
  const project = useSelector((state) => state.project.projects[queue.territoryId])
  const [seekValue, setSeekValue] = useState(null)
  const slideCarousel = useRef(null)

  useLayoutEffect(() => {
    if (slideCarousel.current) {
      slideCarousel.current.goToSlide(queue.trackIndex)
    }
  }, [queue.trackIndex])

  let navigatorLang = navigator.languages[0]
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang
  let globaltracks = null
  if (queue.playlistId === -1) {
    globaltracks = favoriteTracks
  } else if (queue.playlistId === -2) {
    globaltracks = topTracks[queue.territoryId]
  } else {
    globaltracks = tracks[`${queue.playlistId}`]
  }
  const responsive = {
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  }

  let totaltime = null
  if (globaltracks) {
    totaltime = globaltracks[queue.trackIndex].duration
  }

  const isFavorite = () => {
    let fav = false
    favoriteTracks.forEach((tr) => {
      if (tr.id === globaltracks[queue.trackIndex].id) {
        fav = tr.favorite
      }
    })
    return fav
  }

  const isCurrentListenedPlaylist = () => {
    let Cur = false
    if (queue.playlistId !== null) {
      if (queue.playlistId === playlist.id) {
        Cur = true
      }
    }
    return Cur
  }

  const onClickFavoriteButton = () => {
    if (tracks && queue.playlistId !== null) {
      if (isFavorite(globaltracks[queue.trackIndex])) {
        dispatch(removeFromFavorite(globaltracks[queue.trackIndex]))
      } else {
        dispatch(addToFavorite(globaltracks[queue.trackIndex]))
      }
    }
  }

  const isPlaying = () => {
    let playing = null
    if (player.status === 'PLAYER_STATUS_PLAYING') {
      playing = true
    } else if (player.status === 'PLAYER_STATUS_PAUSED') {
      playing = false
    }
    return playing
  }

  const onClickPlayPause = () => {
    if (!isMyFavorites) {
      if (tracks && queue.playlistId !== null && queue.playlistId !== -1 &&
         queue.playlistId !== -2) {
        if (isCurrentListenedPlaylist() && isPlaying()) {
          dispatch(pauseTrack())
        } else if (isCurrentListenedPlaylist() && !isPlaying()) {
          dispatch(resumeTrack())
        } else {
          dispatch(playTrack(tracks, queue.playlistId, queue.trackIndex))
        }
      }
      if (tracks && queue.playlistId !== null && queue.playlistId === -1) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else if (!isPlaying()) {
          dispatch(resumeTrack())
        } else {
          dispatch(playTrack(tracks, favoriteTracks, favoriteTracks[queue.trackIndex]))
        }
      }
      if (tracks && queue.playlistId !== null && queue.playlistId === -2) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else if (!isPlaying()) {
          dispatch(resumeTrack())
        } else {
          dispatch(playTrack(tracks, topTracks, topTracks[queue.territoryId]))
        }
      }
    }
    if (isMyFavorites || favoriteTracks[queue.trackIndex]) {
      if (tracks && favoriteTracks !== null) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else if (!isPlaying()) {
          dispatch(resumeTrack())
        } else {
          dispatch(playTrack(tracks, favoriteTracks, favoriteTracks[queue.trackIndex]))
        }
      }
    }
  }

  const onclickPrev = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(skipPreviousTrack())
    }
  }

  const onClickNext = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(skipNextTrack())
    }
  }

  const onClickRepeat = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(toggleRepeat())
    }
  }

  const onClickRandom = () => {
    if (tracks && queue.playlistId !== null) {
      dispatch(toggleShuffle())
    }
  }

  const computeSeekedTime = (time) => {
    const computedSeekedTime = Math.floor(
      tracks[queue.playlistId][queue.trackIndex].duration * time,
    )
    return computedSeekedTime
  }

  const onSeekBarChangeStarted = () => {
    dispatch(sendStats('seeked', queue.playingTrackId, queue.playlistId, player.currentTime))
  }

  const onSeekBarChangeEnd = (endValue) => {
    if (endValue) {
      setSeekValue(null)
      const seekedTime = computeSeekedTime(endValue)
      dispatch(setTime(
        true,
        seekedTime,
      ))
    }
  }

  const renderArtists = () => {
    let string = ''
    const numberOfArtists = globaltracks[queue.trackIndex].artists.length
    for (let i = 0; i < numberOfArtists; i++) {
      if (i === 0) {
        string = `${globaltracks[queue.trackIndex].artists[i].name} `
      } else {
        string = `${string}&${globaltracks[queue.trackIndex].artists[i].name}`
      }
    }
    return string
  }

  const trackImageSource = (index) => {
    let apiPath = ''
    try {
      apiPath = `albums/${globaltracks[index].id_album}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=300&height=300&
    lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const onClickBack = () => {
    if (queue.playlistId && queue.playlistId === -1 && favoriteTracks[queue.trackIndex]) {
      dispatch(resumeTrack())
      history.push('/myfavorites')
    }
    if (queue.playlistId && queue.playlistId === -2 && topTracks[queue.territoryId]) {
      dispatch(resumeTrack())
      history.push(`/topTracks/${queue.territoryId}`)
    }
    if (queue.playlistId && queue.playlistId !== -1 && queue.playlistId !== -2) {
      dispatch(resumeTrack())
      history.push(`/playlist/${queue.playlistId}`)
    }
  }

  return (
    <body>
      {isCurrentListenedPlaylist && (
        <div className="container__audioplayer">
          <div className="container__play">
            <div className="d-flex container__titles">
              <div className="title__playlist">
                <button
                  type="button"
                  onClick={() => window.history.back()}
                >
                  <img src="/assets/picto_playlist.svg" alt="playlist" />
                </button>
                {queue.playlistId && queue.playlistId === -2 && topTracks[queue.territoryId] && (
                <p>{t(project.territory[0].code)}</p>)}
                <p>{playlist && playlist.metadata.title}</p>
              </div>
              <button
                type="button"
                onClick={() => onClickBack()}
              >
                <img src="/assets/picto_playlist.svg" alt="playlist" />
              </button>

            </div>

            {tracks && queue.playlistId !== null && (
              <Carousel
                responsive={responsive}
                slidesToSlide={1}
                centerMode
                ref={slideCarousel}
              >
                {Object.keys(globaltracks).map((track) => (
                  <div key={track} className="container__picture">
                    <img
                      src={trackImageSource(track)}
                      alt="slide"
                    />
                  </div>
                ))}
              </Carousel>
            )}
            <div className="container__audioplayer__description">
              <div>
                <p className="audioplayer__title">{queue.playlistId && (globaltracks[queue.trackIndex].title)}</p>
                <p>{queue.playlistId && renderArtists()}</p>
              </div>
              {queue.playlistId && (
                <button
                  type="button"
                  onClick={() => onClickFavoriteButton()}
                >
                  <img
                    src={isFavorite(globaltracks[queue.trackIndex]) ? '/assets/picto_heart_actif.png' : '/assets/picto_heart_inactif.svg'}
                    alt={isFavorite(globaltracks[queue.trackIndex]) ?
                      t('removefavorite') : t('addfavorite')}
                  />
                </button>
              )}
            </div>
            {tracks && queue.playlistId && (
              <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                <Seeker
                  onChangeStart={(startValue) => onSeekBarChangeStarted(startValue)}
                  onChangeEnd={(endValue) => onSeekBarChangeEnd(endValue)}
                  onChange={(value) => {
                    setSeekValue(Math.floor(totaltime * Math.floor(value * 10) * 0.1))
                  }}
                  totaltime={totaltime}
                  seekValue={seekValue}
                  currentTime={player.currentTime}
                  styleBgBar={{ background: 'grey' }}
                  styleBar={{ background: '#3cad00' }}
                  styleHandle={{ background: '#3cad00' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p>{dayjs.utc(player.currentTime * 1000).format('mm:ss')}</p>
                  {queue.playlistId && (
                    <p>{dayjs.utc(totaltime * 1000).format('mm:ss')}</p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="container__audioplayer__button">
            {queue && !queue.repeat && !queue.repeatOnce && (
              <button type="button" className="inactif" onClick={onClickRepeat}>
                <img className="button_repeat" src="/assets/picto_repeat.svg" alt="repeat" />
              </button>
            )}
            {queue && !queue.repeat && queue.repeatOnce && (
              <button type="button" className={queue.repeatOnce ? 'inactif btn_repeatonce' : ''} onClick={onClickRepeat}>
                <img className="button_repeat" src={queue.repeatOnce ? '/assets/pictoo_repeat_actif.svg' : '/assets/picto_repeat.svg'} alt="repeat" />
                <p className={queue.repeatOnce ? 'active_repeatonce' : 'no_repeatonce'}>1</p>
              </button>
            )}
            {queue && queue.repeat && !queue.repeatOnce && (
              <button type="button" className="inactif" onClick={onClickRepeat}>
                <img className="button_repeat" src={queue.repeat ? '/assets/pictoo_repeat_actif.svg' : '/assets/picto_repeat.svg'} alt="repeat" />
              </button>
            )}
            <button type="button" onClick={onclickPrev}>
              <img className="buttonM" src="/assets/picto_precedent.svg" alt="previous" />
            </button>
            <button className="container__buttonL" type="button" onClick={onClickPlayPause}>
              {isPlaying() && (
                <img className="buttonL__breack" src="/assets/picto_pause_little.svg" alt="break" />
              )}
              {!isPlaying() && (
                <img className="buttonL__play" src="/assets/picto_player_little.svg" alt="play" />
              )}
            </button>
            <button type="button" onClick={onClickNext}>
              <img className="buttonM" src="/assets/picto_next_little.svg" alt="next" />
            </button>
            <button type="button" onClick={onClickRandom}>
              <img
                className="button_shuffle"
                src={queue.shuffle ? '/assets/picto_shuf_actif.svg' : '/assets/picto_shuffle.svg'}
                alt={queue.shuffle ? t('random') : t('list')}
              />
            </button>
          </div>
        </div>
      )}
    </body>
  )
}

AudioPlayer.defaultProps = {
  isMyFavorites: false,
}
AudioPlayer.propTypes = {
  isMyFavorites: PropTypes.bool,
}

export default AudioPlayer
