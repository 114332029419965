import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import './styles/index.scss'
import dayjs from 'dayjs'
// import { useTranslation } from 'react-i18next'
import config from '../../config'
import { addToFavorite, removeFromFavorite, playTrack, pauseTrack, resumeTrack, TOPTRACK_PLAYLIST_ID } from '../../actions'
import { gaSender } from '../../lib'

const Track = (props) => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const { lang, track, user, territory, index } = props
  const favoriteTracks = useSelector((state) => state.favorite.tracks)
  const queue = useSelector((state) => state.queue)
  const numberOfArtists = track.artists.length
  const player = useSelector((state) => state.player)
  // const { t } = useTranslation('track')
  const dispatch = useDispatch()
  const trackImageSource = () => {
    let apiPath = ''
    try {
      apiPath = `albums/${track.id_album}`
    } catch (e) {
      console.log(e)
    }
    return `${config.API_BASE_URL}/${apiPath}/cover.png?width=200&height=200&lang=${lang}&authorization=${user.authUser.accessToken}`
  }

  const isFavorite = () => {
    let fav = false
    favoriteTracks.forEach((tr) => {
      if (tr.id === track.id) {
        fav = tr.favorite
      }
    })
    return fav
  }

  const isPlaying = () => {
    let playing = false
    if (track.id === queue.playingTrackId && player.status === 'PLAYER_STATUS_PLAYING') {
      playing = true
    }
    return playing
  }

  const onClickFavoriteButton = () => {
    if (isFavorite(track, lang)) {
      dispatch(removeFromFavorite(track))
      gaSender('favorite_remove', track.id, track.title)
    } else {
      dispatch(addToFavorite(track))
      gaSender('favorite_add', track.id, track.title)
    }
  }

  const onClickPlayButton = (event) => {
    if (event.target.className !== 'buttonHeart' && event.target.parentElement.className !== 'buttonHeart') {
      if (track.id !== queue.playingTrackId) {
        dispatch(playTrack(track, TOPTRACK_PLAYLIST_ID, index, null, territory))
      }
      if (isPlaying() && track.id === queue.playingTrackId) {
        dispatch(pauseTrack())
      } else if (!isPlaying() && track.id === queue.playingTrackId) {
        dispatch(resumeTrack())
      }
    }
  }

  const renderArtists = () => {
    let string = ''
    for (let i = 0; i < numberOfArtists; i++) {
      if (i === 0) {
        string = `${track.artists[i].name} `
      } else {
        string = `${string}&${track.artists[i].name}`
      }
    }
    return string
  }

  return (
    <div
      role="button"
      className={isPlaying() ? 'mobilePlayOnClick bg-secondary' : 'mobilePlayOnClick'}
      aria-hidden="true"
      onClick={(e) => onClickPlayButton(e)}
    >
      <div className="innerList">
        <div className="leftTrack-content">
          <figure className="cover_img">
            <img
              className="d-block"
              src={trackImageSource()}
              alt="cover_img"
            />
          </figure>
          <div className="track-description-md">
            <p className="track-title">{track.title}</p>
            <p className="point" />
            <p className="track-artist">
              {' '}
              {renderArtists()}
            </p>
          </div>
        </div>
        <div className="lastInfoPlaylist">
          <p className="track-time">{dayjs.utc(track.duration * 1000).format('mm:ss')}</p>
          <button className="playButton" id={`user_listentrack_${track.id}`} type="button">
            {isPlaying() ? (
              <img
                src="/assets/player_pause_32x32_blanc.svg"
                alt="play"
              />
            ) : (
              <img
                src="/assets/player_32x32_blanc.svg"
                alt="play"
              />
            )}
          </button>
          {isFavorite(track) ? (
            <button className="buttonHeart" type="button" id={`user_unfav_track_${track.id}`} onClick={() => onClickFavoriteButton()}>
              <img
                src="/assets/picto_heart_actif.png"
                alt="heartIconGreen"
              />
            </button>
          ) : (
            <button className="buttonHeart" type="button" id={`user_fav_track_${track.id}`} onClick={() => onClickFavoriteButton()}>
              <img
                src="/assets/picto_heart_inactif.png"
                alt="heartIconWhite"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

Track.propTypes = {
  track: PropTypes.objectOf(PropTypes.shape({
    length: PropTypes.number,
  })),
  lang: PropTypes.string.isRequired,
  user: PropTypes.objectOf(PropTypes.shape({
    accessToken: PropTypes.string,
  })).isRequired,
  territory: PropTypes.number,
  index: PropTypes.number.isRequired,
}
Track.defaultProps = {
  track: [],
  territory: 0,
}

export default Track
