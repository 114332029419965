import React from 'react'
import { Slider, Direction } from 'react-player-controls'
import PropTypes from 'prop-types'

const SliderBar = ({ value, totaltime, styleBar, styleBgBar }) => {
  const time = (value / totaltime) * 100
  return (
    <div>
      <div
        style={({ position: 'absolute',
          background: 'grey',
          borderRadius: 4,
          top: 0,
          bottom: 0,
          left: 0,
          height: 7,
          width: '100%',
          transition: 'width 0.2s',
          ...styleBgBar,
        })}
      />
      <div
        style={({ position: 'absolute',
          background: 'grey',
          borderRadius: 4,
          top: 0,
          bottom: 0,
          left: 0,
          height: 7,
          width: `${time}%`,
          transition: 'width 0.2s',
          ...styleBar })}
      />
    </div>
  )
}

const SliderHandle = ({ value, totaltime, styleHandle }) => {
  const time = (value / totaltime) * 100
  return (
    <div
      style={({ position: 'absolute',
        zIndex: 2,
        width: 14,
        height: 14,
        background: 'green',
        borderRadius: '100%',
        transform: 'scale(1)',
        transition: 'left 0.2s',
        '&:hover': {
          transform: 'scale(1.3)',
        },
        top: 0,
        left: `${time}%`,
        marginTop: -4,
        marginLeft: -8,
        ...styleHandle })}
    />
  )
}

const Seeker = ({
  onChangeStart,
  onChangeEnd,
  onChange,
  totaltime,
  seekValue,
  currentTime,
  styleBgBar,
  styleBar,
  styleHandle,
}) => (
  <Slider
    direction={Direction.HORIZONTAL}
    onChangeStart={(value) => onChangeStart(value)}
    onChangeEnd={(value) => onChangeEnd(value)}
    onChange={(value) => onChange(value)}
  >
    <SliderBar
      value={seekValue || currentTime}
      totaltime={totaltime}
      styleBgBar={styleBgBar}
      styleBar={styleBar}
    />
    <SliderHandle
      value={seekValue || currentTime}
      totaltime={totaltime}
      styleHandle={styleHandle}
    />
    <div className="custom-range" />
  </Slider>
)

Seeker.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeEnd: PropTypes.func.isRequired,
  onChangeStart: PropTypes.func.isRequired,
  totaltime: PropTypes.number.isRequired,
  seekValue: PropTypes.number,
  currentTime: PropTypes.number.isRequired,
  styleBgBar: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  styleBar: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  styleHandle: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

Seeker.defaultProps = {
  seekValue: null,
}

SliderHandle.propTypes = {
  value: PropTypes.number.isRequired,
  totaltime: PropTypes.number.isRequired,
  styleHandle: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

SliderBar.propTypes = {
  value: PropTypes.number.isRequired,
  totaltime: PropTypes.number.isRequired,
  styleBgBar: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  styleBar: PropTypes.objectOf(PropTypes.shape({})).isRequired,
}

export default Seeker
