export const accessLevels = {
  USER: 'digster_user',
}

export const authorizedRole = {
  USER: [`${accessLevels.USER}`],
}

export const isAuthorized = (role, requiredRole) => {
  if (requiredRole.indexOf(role) > -1) {
    return true
  }
  return false
}
