import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import './styles/index.scss'
import favoriteBg from '../../images/favorite-bg.svg'
import {
  playTrack,
  addPlaylistToFavoritesPlaylists,
  removePlaylistFromFavoritesPlaylists,
  fetchFavoritesPlaylists,
  FAVORITE_PLAYLIST_ID,
  TOPTRACK_PLAYLIST_ID,
  pauseTrack,
  resumeTrack,
} from '../../actions'
import { gaSender } from '../../lib'

const PlaylistHeader = (props) => {
  const utc = require('dayjs/plugin/utc')
  dayjs.extend(utc)
  const [scrollY, setscrollY] = useState(0)
  const { t } = useTranslation('playlistheader')
  const {
    playlist,
    tracks,
    playlistImageSource,
    isTopTracks,
    project,
    getProjectCover,
    getProjectFlag,
    isMyFavorites,
  } = props
  const favoriteTracks = useSelector((state) => state.favorite.tracks)
  const player = useSelector((state) => state.player)
  const queue = useSelector((state) => state.queue)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchFavoritesPlaylists())
  }, [dispatch])

  const parseTimeTopTracks = (initSec, hourText = ' h', minText = ' m', plural = '') => {
    let sec = 0
    const tracksList = tracks
    if (!initSec && tracksList) {
      const tracksArray = Object.keys(tracksList)
      for (let i = 0; i < tracksList.length; i++) {
        if (tracksList[tracksArray[i]].duration) {
          sec += tracksList[tracksArray[i]].duration
        }
      }
    } else {
      sec = initSec
    }

    const dayUtc = dayjs.utc(sec * 1000)
    const hours = Number(dayUtc.format('HH'))
    const min = dayUtc.format('mm')

    let formatted = ''
    if (hours > 0) {
      formatted = `${hours}${hourText}`
      if (hours > 1) {
        formatted += plural
      }
      formatted += ' '
    }
    if (min > 0) {
      formatted += `${min}${minText}`
      if (min > 1) {
        formatted += plural
      }
    }
    return formatted
  }

  const parseTimeFav = (initSec, hourText = ' h', minText = ' m', plural = '') => {
    let sec = 0
    const tracksList = favoriteTracks
    if (!initSec && tracksList) {
      const tracksArray = Object.keys(tracksList)
      for (let i = 0; i < tracksList.length; i++) {
        if (tracksList[tracksArray[i]].duration) {
          sec += tracksList[tracksArray[i]].duration
        }
      }
    } else {
      sec = initSec
    }

    const dayUtc = dayjs.utc(sec * 1000)
    const hours = Number(dayUtc.format('HH'))
    const min = dayUtc.format('mm')

    let formatted = ''
    if (hours > 0) {
      formatted = `${hours}${hourText}`
      if (hours > 1) {
        formatted += plural
      }
      formatted += ' '
    }
    if (min > 0) {
      formatted += `${min}${minText}`
      if (min > 1) {
        formatted += plural
      }
    }
    return formatted
  }

  const parseTime = (initSec, hourText = ' h', minText = ' m', plural = '') => {
    let sec = 0
    const tracksList = tracks[playlist.id]
    if (!initSec && tracksList) {
      const tracksArray = Object.keys(tracksList)
      for (let i = 0; i < tracksList.length; i++) {
        if (tracksList[tracksArray[i]].duration) {
          sec += tracksList[tracksArray[i]].duration
        }
      }
    } else {
      sec = initSec
    }

    const dayUtc = dayjs.utc(sec * 1000)
    const hours = Number(dayUtc.format('HH'))
    const min = dayUtc.format('mm')

    let formatted = ''
    if (hours > 0) {
      formatted = `${hours}${hourText}`
      if (hours > 1) {
        formatted += plural
      }
      formatted += ' '
    }
    if (min > 0) {
      formatted += `${min}${minText}`
      if (min > 1) {
        formatted += plural
      }
    }
    return formatted
  }

  const isPlaying = () => {
    let playing = null
    if (player.status === 'PLAYER_STATUS_PLAYING') {
      playing = true
    } else if (player.status === 'PLAYER_STATUS_PAUSED') {
      playing = false
    }
    return playing
  }

  const onClickPlayButton = () => {
    const { territory } = props
    // Case top playlist
    if (isTopTracks) {
      if (TOPTRACK_PLAYLIST_ID !== queue.playlistId) {
        dispatch(playTrack(tracks[0], TOPTRACK_PLAYLIST_ID, 0, null, territory))
      } else if (TOPTRACK_PLAYLIST_ID === queue.playlistId) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else {
          dispatch(resumeTrack())
        }
      }
      // Case fav playlist
    } else if (isMyFavorites) {
      if (FAVORITE_PLAYLIST_ID !== queue.playlistId) {
        dispatch(playTrack(tracks[0], FAVORITE_PLAYLIST_ID, 0))
      } else if (FAVORITE_PLAYLIST_ID === queue.playlistId) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else {
          dispatch(resumeTrack())
        }
      }
      // Case normal playlist
    } else if (!isTopTracks && !isMyFavorites) {
      if (playlist.id !== queue.playlistId) {
        dispatch(playTrack(tracks[`${playlist.id}`][0], playlist.id, 0))
      } else if (playlist.id === queue.playlistId) {
        if (isPlaying()) {
          dispatch(pauseTrack())
        } else {
          dispatch(resumeTrack())
        }
      }
    }
  }

  const { favorplaylists } = props
  const isFavoritePlaylist = (playlistId) => {
    let favpl = false
    favorplaylists.forEach((fpl) => {
      if (fpl.id === playlistId) {
        favpl = fpl.favorite
      }
    })
    return favpl
  }

  useEffect(() => {
    setInterval(() => {
      setscrollY(window.scrollY)
    }, 500)
  }, [setscrollY])

  // playlist header top-tracks
  if (isTopTracks) {
    if (scrollY === 0) {
      return (
        <div className="playlistHeader topTracks">
          <div className="topTracks_container">
            <div className="topTracks_content">
              <figure className="topTracks_cover">
                <img
                  className="backgroundImage"
                  src={getProjectCover()}
                  alt="cover"
                />
                <img
                  className="flag"
                  src={getProjectFlag()}
                  alt="flag"
                />
              </figure>
              <div className="topTracks_desc">
                <h1 className="playlist-title">
                  <img
                    className="flag"
                    src={getProjectFlag()}
                    alt="flag"
                  />
                  {`${t('toptracks')} ${t(project.territory[0].code)}`}
                </h1>
                <div className="desc_content">
                  <div className="desc_text">
                    <p className="time">{parseTimeTopTracks(tracks.duration)}</p>
                  </div>
                  <button className="playlistPlayButton" type="button" onClick={() => onClickPlayButton()} id={`user_listentoptrack_${project.territory[0].code}`}>
                    <img
                      src={isPlaying() && TOPTRACK_PLAYLIST_ID === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                      alt="pictoPlayer"
                    />
                    <span>{t('play')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button className="backArrow" type="button" onClick={() => window.history.back()}>
            <img
              src="/assets/fleche_retour.png"
              alt="back arrow"
            />
          </button>
        </div>
      )
    }
    return (
      <div className="playlistHeader topTracks sticky-top-sticky">
        <div className="topTracks_container">
          <div className="topTracks_content">
            <figure className="topTracks_cover">
              <img
                className="backgroundImageSticky"
                src={getProjectCover()}
                alt="slide"
              />
            </figure>
            <div className="topTracks_desc">
              <h1 className="playlist-title playlist-title-scroll">
                <img
                  className="flag"
                  src={getProjectFlag()}
                  alt="flag"
                />
                {`${t('toptracks')} ${t(project.territory[0].code)}`}
              </h1>
              <div className="desc_content">
                <div className="desc_text">
                  <p className="time">{parseTimeTopTracks(playlist.duration)}</p>
                </div>
                <button className="playlistPlayButton" id="playSticky" type="button" onClick={() => onClickPlayButton()}>
                  <img
                    src={isPlaying() && TOPTRACK_PLAYLIST_ID === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                    alt="pictoPlayer"
                  />
                  <span>{t('play')}</span>
                </button>
              </div>
            </div>
          </div>
          <button type="button" className="playlistPlayButtonMobile" onClick={() => onClickPlayButton()} id={`user_listenplaylist_${playlist.id}`}>
            <img
              src="/assets/picto_player_little.png"
              alt="pictoPlayer"
            />
          </button>
        </div>
        <button className="backArrow" id="back" type="button" onClick={() => window.history.back()}>
          <img
            src="/assets/fleche_retour.png"
            alt="back arrow"
          />
        </button>
      </div>
    )
  }

  // playlist header normal
  if (scrollY === 0) {
    return (
      <div className="playlistHeader">
        {!isMyFavorites && (
        <div className="topTracks_container">
          <div className="topTracks_content">
            <figure className="topTracks_cover">
              <img
                className="backgroundImage"
                src={playlistImageSource()}
                alt="cover"
              />
            </figure>
            <div className="topTracks_desc">
              <h1 className="playlist-title">{playlist.metadata && playlist.metadata.title}</h1>
              <div className="desc_content">
                <div className="desc_text">
                  <p className="text">{playlist.metadata && playlist.metadata.description}</p>
                  <p className="time">{parseTime(playlist.duration)}</p>
                </div>
                <button type="button" className="playlistPlayButton" onClick={() => onClickPlayButton()} id={`user_listenplaylist_${playlist.id}`}>
                  <img
                    src={isPlaying() && playlist.id === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                    alt="pictoPlayer"
                  />
                  <span>{t('play')}</span>
                </button>
              </div>
            </div>
            <div className="container__icons">
              <p className="likeIcon">
                {isFavoritePlaylist(playlist.id) ? (
                  <button
                    className="buttonHeart"
                    type="button"
                    id={`user_unfav_playlist_${playlist.id}`}
                    onClick={() => {
                      gaSender('favorite_playlist_remove', playlist.id, playlist.metadata.title)
                      dispatch(removePlaylistFromFavoritesPlaylists(playlist))
                    }}
                  >
                    <img
                      src="/assets/picto_heart_actif.png"
                      alt="heartIconGreen"
                    />
                  </button>
                ) : (
                  <button
                    className="buttonHeart"
                    type="button"
                    id={`user_fav_playlist_${playlist.id}`}
                    onClick={() => {
                      gaSender('favorite_playlist_add', playlist.id, playlist.metadata.title)
                      dispatch(addPlaylistToFavoritesPlaylists(playlist))
                    }}
                  >
                    <img
                      src="/assets/picto_heart_inactif.png"
                      alt="heartIconWhite"
                    />
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
        )}
        { isMyFavorites && (
          <div className="topTracks_container fav_container">
            <div className="bgFav">
              <img src={favoriteBg} alt="favoriteBg" />
            </div>
            <div className="topTracks_content">
              <div className="topTracks_desc">
                <h1 className="playlist-title">{t('myfavorites')}</h1>
                <div className="desc_content">
                  <div className="desc_text">
                    <p className="time">{parseTimeFav(favoriteTracks.duration)}</p>
                  </div>
                  <button type="button" className="playlistPlayButton" onClick={() => onClickPlayButton()} id="user_listenplaylist_fav">
                    <img
                      src={isPlaying() && FAVORITE_PLAYLIST_ID === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                      alt="pictoPlayer"
                    />
                    <span>{t('play')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <button className="backArrow" type="button" onClick={() => window.history.back()}>
          <img
            src="/assets/fleche_retour.png"
            alt="back arrow"
          />
        </button>
      </div>
    )
  }
  return (
    <div className="playlistHeader sticky-top-sticky">
      { !isMyFavorites && (
        <div className="topTracks_container">
          <div className="topTracks_content">
            <figure className="topTracks_cover">
              <img
                className="backgroundImageSticky"
                src={playlistImageSource()}
                alt="slide"
              />
            </figure>
            <div className="topTracks_desc">
              <h1 className="playlist-title playlist-title-scroll">{playlist.metadata && playlist.metadata.title}</h1>
              <div className="desc_content">
                <div className="desc_text">
                  <p className="text">{playlist.metadata && playlist.metadata.description}</p>
                  <p className="time">{parseTime(playlist.duration)}</p>
                </div>
                <button type="button" className="playlistPlayButton" id="playSticky" onClick={() => onClickPlayButton()}>
                  <img
                    src={isPlaying() && playlist.id === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                    alt="pictoPlayer"
                  />
                  <span>{t('play')}</span>
                </button>
              </div>
            </div>
            <div className="container__icons">
              <button type="button" className="playlistPlayButtonMobile" onClick={() => onClickPlayButton()} id={`user_listenplaylist_${playlist.id}`}>
                <img
                  src={isPlaying() && playlist.id === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                  alt="pictoPlayer"
                />
              </button>
              <p className="likeIcon">
                {isFavoritePlaylist(playlist.id) ? (
                  <button className="buttonHeart" type="button" id={`user_unfav_playlist_${playlist.id}`} onClick={() => dispatch(removePlaylistFromFavoritesPlaylists(playlist))}>
                    <img
                      src="/assets/picto_heart_actif.png"
                      alt="heartIconGreen"
                    />
                  </button>
                ) : (
                  <button className="buttonHeart" type="button" id={`user_fav_playlist_${playlist.id}`} onClick={() => dispatch(addPlaylistToFavoritesPlaylists(playlist))}>
                    <img
                      src="/assets/picto_heart_inactif.png"
                      alt="heartIconWhite"
                    />
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      { isMyFavorites && (
        <div className="topTracks_container fav_container">
          <div className="bgFav">
            <img src={favoriteBg} alt="favoriteBg" />
          </div>
          <div className="topTracks_content">
            <figure className="topTracks_cover">
              <img
                className="backgroundImage"
                src={playlistImageSource()}
                alt="cover"
              />
            </figure>
            <div className="topTracks_desc">
              <h1 className="playlist-title">{t('myfavorites')}</h1>
              <div className="desc_content">
                <div className="desc_text">
                  <p className="time">{parseTimeFav(favoriteTracks.duration)}</p>
                </div>
                <button className="playlistPlayButton" id="playSticky" type="button" onClick={() => onClickPlayButton()}>
                  <img
                    src={isPlaying() && FAVORITE_PLAYLIST_ID === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                    alt="pictoPlayer"
                  />
                  <span>{t('play')}</span>
                </button>
                <button type="button" className="playlistPlayButtonMobile" onClick={() => onClickPlayButton()} id={`user_listenplaylist_${playlist.id}`}>
                  <img
                    src={isPlaying() && FAVORITE_PLAYLIST_ID === queue.playlistId ? '/assets/picto_pause_little.svg' : '/assets/picto_player_little.svg'}
                    alt="pictoPlayer"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <button className="backArrow" id="back" type="button" onClick={() => window.history.back()}>
        <img
          src="/assets/fleche_retour.png"
          alt="back arrow"
        />
      </button>
    </div>
  )
}

PlaylistHeader.defaultProps = {
  playlistImageSource: () => { },
  playlist: {},
  isTopTracks: false,
  project: {},
  tracks: {},
  isMyFavorites: false,
  favorplaylists: [],
  getProjectCover: () => { },
  getProjectFlag: () => {},
  territory: -1,
}
PlaylistHeader.propTypes = {
  tracks: PropTypes.objectOf(PropTypes.shape({})),
  playlistImageSource: PropTypes.func,
  playlist: PropTypes.objectOf(PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    metadata: PropTypes.shape({
    }),
  })),
  isTopTracks: PropTypes.bool,
  project: PropTypes.objectOf(PropTypes.shape({})),
  getProjectCover: PropTypes.func,
  getProjectFlag: PropTypes.func,
  isMyFavorites: PropTypes.bool,
  favorplaylists: PropTypes.arrayOf(PropTypes.shape({})),
  territory: PropTypes.number,
}

export default PlaylistHeader
