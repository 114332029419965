/* eslint-disable no-undef */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { fetchProjectLinks, getUnitedProjects } from '../../actions'
import config from '../../config'
import { getProjecIdByProjectName } from '../../lib'
import './styles/index.scss'

const Subscribe = () => {
  const { t } = useTranslation('subscribe')
  const dispatch = useDispatch()
  const useQuery = () => new URLSearchParams(useLocation().search)
  const links = useSelector((reducerState) => reducerState.project.links)
  const projects = useSelector((reducerState) => reducerState.project.projects)

  const query = useQuery()
  const rawData = query.get('territory')

  let navigatorLang = navigator.languages[0].substr(0, 2)
  if (['fr', 'en', 'ar'].indexOf(navigatorLang) !== -1) {
    navigatorLang = 'en'
  }
  const lang = localStorage.getItem('lang') || navigatorLang

  React.useEffect(() => {
    if (!projects || projects.length === 0 || !Array.isArray(projects)) {
      dispatch(getUnitedProjects())
    }
  }, [dispatch, projects])

  React.useEffect(() => {
    getProjecIdByProjectName(rawData, projects)
    dispatch(fetchProjectLinks(getProjecIdByProjectName(rawData, projects)))
    if (projects && projects.length > 1 && (!localStorage.getItem('PROJECT_ID') || localStorage.getItem('PROJECT_ID') !== getProjecIdByProjectName(rawData, projects))) {
      localStorage.setItem('PROJECT_ID', getProjecIdByProjectName(rawData, projects))
    }
  }, [dispatch, rawData, projects])

  return (
    <div className="container_subscribe">
      <Link className="close_btn" to="/phonenumber">X</Link>
      <div>
        <img className="logoI" width={160} src={`${config.REACT_APP_PREFIX}./logo_digster768.svg`} alt="logo" />
        <img className="logomobileI" width={70} src={`${config.REACT_APP_PREFIX}./iconDigster192.png`} alt="logo" />
      </div>
      <h4 className="title_subscribe">{t('selectSubModal')}</h4>

      <div className="container__link">
        {links && links.popup && links.popup[lang] && (
          <a className="link__subscribe" href={links.popup[lang]}>
            <text>{t('optionTel')}</text>
          </a>
        )}
        {links && links.abonnements && links.abonnements.cinetpayMobileMoney && (
          <a className="link__subscribe" href={links.abonnements.cinetpayMobileMoney}>
            <text>{t('optionCard')}</text>
          </a>
        )}
      </div>
    </div>
  )
}

export default Subscribe
